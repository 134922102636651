import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import { getIsMenuExpanded } from "../../store/actions/fetchActions";

export const NavigationMenu = ({
  menuItmes,
  clickExpand,
  className,
  dashboardRoute,
  collapseClass,
}) => {
  const [isExpanded, setExpanded] = useState(true);

  const dispatch = useDispatch();

  const onCollapseExpand = () => {
    const expandValue = !isExpanded;
    setExpanded(expandValue);
    clickExpand(expandValue);
    document.removeEventListener("wheel", preventDefault, {
      passive: false,
    });
    dispatch(getIsMenuExpanded(expandValue));
  };

  /** To prevent scroll if cursor is on nav div */
  const preventDefault = (e) => {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  };
  const enableScroll = () => {
    document.removeEventListener("wheel", preventDefault, {
      passive: false,
    });
  };
  const disableScroll = () => {
    document.addEventListener("wheel", preventDefault, {
      passive: false,
    });
  };

  return (
    <div
      className={`nav-menu ${
        isExpanded ? `expand ${className}` : `${collapseClass}`
      }`}
      onMouseEnter={disableScroll}
      onMouseLeave={enableScroll}
    >
      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        {isExpanded && (
          <NavLink
            style={{}}
            to={dashboardRoute}
            className={({ isActive }) => (isActive ? "is-active" : " ")}
          >
            <div className="dashboard-text">
              {isExpanded && (
                <span className="icon nav-icons hamburger-icon"></span>
              )}
              {isExpanded && (
                <span className="Dashboard-letter">Dashboard</span>
              )}
            </div>
          </NavLink>
        )}

        <span
          className={`go-back-icon icon ${
            isExpanded ? "expanded" : "collapsed"
          }`}
          onClick={() => onCollapseExpand()}
        ></span>
      </div>

      {menuItmes?.length &&
        menuItmes.map((item) => (
          <NavLink
            key={item.title}
            to={item.path}
            className={({ isActive }) => (isActive ? "is-active" : "")}
          >
            <span className={`icon nav-icons ${item.iconName}`}></span>
            {isExpanded && <span className="nav-items">{item.title}</span>}
          </NavLink>
        ))}
      {/* <Outlet /> */}
    </div>
  );
};
