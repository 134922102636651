export const status = {
  confirmed: "CONFIRMED",
  pending: "PENDING",
  removed: "REMOVED",
  declined: "DECLINED",
};

export const userRoleView = {
  LEVEL1_USER: "1",
  LEVEL2_USER: "2",
  LEVEL3_USER: "3",
};

export const statusView = {
  CONFIRMED: "Confirmed",
  PENDING: "Pending",
  REMOVED: "Removed",
  DECLINED: "Declined",
};

export const roleType = {
  level1: "LEVEL1_USER",
  level2: "LEVEL2_USER",
  level3: "LEVEL3_USER",
};

export const sortUserList = (users) => {
  const pendingUsers = users.filter(
    (user) => user.approvalStatus === status.pending
  );
  const confirmUsers = users.filter(
    (user) => user.approvalStatus === status.confirmed
  );
  const declinedUsers = users.filter(
    (user) => user.approvalStatus === status.declined
  );
  const removedUsers = users.filter(
    (user) => user.approvalStatus === status.removed
  );
  return [...pendingUsers, ...confirmUsers, ...declinedUsers, ...removedUsers];
};
