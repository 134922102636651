import axios from "axios";

import { FETCH_VEHICLES, IS_SIDEMENU_EXPANDED } from "../types";

import { VIN_NUMBER } from "../types";

export const fetchVehicles = () => (dispatch) => {
  axios
    .get("url")
    .then((res) => {
      dispatch({
        type: FETCH_VEHICLES,
        payload: res.data,
      });
    })
    .catch((err) => {});
};

export const addvin_Number = (payload) => ({
  type: VIN_NUMBER,
  payload: payload,
});

export const getIsMenuExpanded = (payload) => ({
  type: IS_SIDEMENU_EXPANDED,
  payload: payload,
});
