import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = (props) => {
  const {customInput, startDate, handleOnChange} = props;
  const [date, setDate] = useState(new Date());
  const today = new Date();

  useEffect(() => {
    setDate(startDate || new Date());
  }, [startDate]);

  const onChange = (date) => {
    setDate(date)
    handleOnChange(date);
  };

  return (
    <DatePicker
      selected={date}
      onChange={onChange}
      customInput={customInput}
      maxDate={today}
    />
  );
}

export default Datepicker;