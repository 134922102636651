import React from "react";
import ReportsIcon from "../WarrantyManagement/assets/reports.svg";
import WarrantyCard from "../WarrantyManagement/components/WarrantyCard";
import "./index.scss";
import VehiclePopulation from "../Dashboard/assets/vehicle_population.svg";
import IndividualVehicle from "../Dashboard/assets/individual-vehical.svg";
import Modal from "../../components/common/Modal/Modal";
import { addvin_Number } from "../../store/actions/fetchActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const DashBoard = () => {
  const dispatch = useDispatch();
  const [model, setmodel] = useState(false);
  const [vinNumber, setVinNumber] = useState("");
  const vinNumberFromRedux = useSelector(
    (state) => state.mainReducer.vin_Number
  );

  useEffect(() => {
    const val = localStorage.getItem("vinId");
    setVinNumber(val || vinNumberFromRedux);
  }, []);
  const showDetails = (val) => {
    setmodel(true);
  };

  let submitHandler = () => {
    setmodel(false);
    localStorage.setItem("vinId", vinNumber);

    dispatch(addvin_Number(vinNumber));
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="first-container">
          <div className="individual-vehicle-health-data">
            <button onClick={() => showDetails()}>
              <WarrantyCard
                img={IndividualVehicle}
                backgroundColor="#C9DBFF"
                text="Individual Vehicle"
                secondText="Health Data"
              />
            </button>
          </div>
          <WarrantyCard
            img={VehiclePopulation}
            backgroundColor="#E3F5E3"
            text="Vehicle Population"
            secondText="Health Data"
          />

          <WarrantyCard
            img={ReportsIcon}
            backgroundColor="#F4E6E7"
            text="My Reports"
          />
        </div>
      </div>

      <div>
        {model && (
          <Modal handleClose={() => setmodel(false)} wrapperClass="vin-modal">
            <div className="model-container">
              <p>Please enter your VIN number here no</p>
              <input
                type="text"
                value={vinNumber}
                placeholder="Enter the VIN number"
                required={true}
                minlength="17"
                maxlength="17"
                onChange={(e) => setVinNumber(e.target.value)}
              ></input>
              <div className="individual-vehicle-button">
                <button
                  className="close-button"
                  onClick={() => setmodel(false)}
                >
                  Close
                </button>
                <button
                  className="save-vin-number-button"
                  onClick={() => submitHandler()}
                >
                  Save VIN number
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
export default DashBoard;
