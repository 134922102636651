import "./multiSelect.scss";
import { forwardRef, useEffect, useState } from "react";
import upArrow from "../../../assets/arrow-drop-down.svg";
import downArrow from "../../../assets/arrow-drop-down.svg";
import withClickOutside from "../OutsideClick";

const MultiSelect = forwardRef((props, ref) => {
  // eslint-disable-next-line no-unused-vars
  const { heading = "heading", preIcon, open, setOpen } = props;

  // const [open, setOpen] = useState(false);

  const toggleSelect = () => {
    setOpen(!open);
  };

  return (
    <div className="multi-select-container" ref={ref}>
      <div className="heading-label">{heading}</div>
      <div className="heading-container" onClick={toggleSelect}>
        <div className="flex">
          <img className="pre-icon-img" src={preIcon} alt="pre-icon" />
          <span className="heading">{heading}</span>
        </div>
        {open ? (
          <img className="arrow-icon" src={upArrow} alt="down-arrow" />
        ) : (
          <img className="arrow-icon" src={downArrow} alt="down-arrow" />
        )}
      </div>
      <div className="multi-select-options-container">
        {open && <div className="multi-select-wrapper">{props.children}</div>}
      </div>
    </div>
  );
});

export default withClickOutside(MultiSelect);
