import React from "react";
import DtcHeading from "./DtcHeading";
import { AiOutlineClose } from "react-icons/ai";
import ServiceCard from "./ServiceCard";
import ReactPlayer from "react-player";
import { Button } from "../../../../components/common/Button/Button";

const LargeContent = ({ selectedCard, onClose, data, onServiceCardClick }) => {
  return (
    <>
      <DtcHeading />
      <div className="large_content_container">
        <div className="large_container">
          <div className="close_container">
            <AiOutlineClose onClick={() => onClose()} />
          </div>
          {selectedCard.mediaType === "blog" ? (
            <>
              <a
                href={selectedCard?.media}
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="image_container">
                  <img src={selectedCard?.imageUrl} alt="" />
                </div>
              </a>
            </>
          ) : (
            <>
              {selectedCard.mediaType === "video" ? (
                <>
                  <div className="video_container">
                    <ReactPlayer url={selectedCard?.media} />
                  </div>
                </>
              ) : (
                <>
                  {selectedCard.mediaType === "image" ? (
                    <div className="image_container">
                      <img src={selectedCard?.imageUrl} alt="" />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
          <div className="text_container">
            {selectedCard.mediaType === "blog" && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: "10px",
                  }}
                >
                  <a
                    href={selectedCard?.media}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button
                      styleClasses="btn btn-primary"
                      buttonStyles={{ width: "auto" }}
                    >
                      Blog Link
                    </Button>
                  </a>
                </div>
              </>
            )}
            <p style={{ fontWeight: 500 }}>{selectedCard.shortDescription}</p>
            <p>{selectedCard.longDescription}</p>
          </div>
        </div>
        <div className="sidebar_container">
          {data?.map((item, index) => (
            <div key={index}>
              <ServiceCard
                data={item}
                onClick={(data) => onServiceCardClick(data)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LargeContent;
