import { Player } from "@lottiefiles/react-lottie-player";
import LottieJson from "./assets/lottie-load.json";

const LottieLoad = ({ style }) => {
  return (
    <div
      className="page-wrapper"
      style={{
        height: "400px",
        marginTop: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <Player
        src={LottieJson}
        className="player"
        loop
        autoplay
        style={{ height: 120, width: 120 }}
      />
    </div>
  );
};

export default LottieLoad;
