import React from 'react';

const TwoColumn = ({ className, children }) => {
  return (
    <div className='page-wrapper'>
      <div
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
        className={className}
      >
        {children}
      </div>
    </div>
  );
};

export default TwoColumn;
