import { VIN_NUMBER, IS_SIDEMENU_EXPANDED } from "../types";

const initialState = {
  vin_Number: localStorage.getItem("vinId") || "DH34ASD7SDFF84742",
  isSidemenuExpanded: true,
};

export const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIN_NUMBER:
      return { ...state, vin_Number: action.payload };
    case IS_SIDEMENU_EXPANDED:
      return { ...state, isSidemenuExpanded: action.payload };
    default:
      return state;
  }
};
