import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import "./WarrantyManagement.scss";

const WarrantyManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/warranty-management") {
      navigate("/warranty-management/diagnostics", {
        replace: true,
      });
    }
  }, [location.pathname]);
  return (
    <div className="full-width warranty-management-container">
      <div className="tabs-container">
        <NavLink
          to="/warranty-management/diagnostics"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Diagnostics
        </NavLink>
        <NavLink
          to="/warranty-management/prognostics"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Prognostics
        </NavLink>
        <NavLink
          to="/warranty-management/warranty-rate"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Warranty rate
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default WarrantyManagement;
