import { useEffect } from "react";
import Highcharts from "highcharts";
import "./RangeChart.scss";
// import { engineSpeedMile, engineSpeedLastTrip } from "../../../_mock/vehicle-usage-profile/engineSpeed";

export const BarChart = ({ id, data, title, yLabel, onClickHandler }) => {
  useEffect(() => {
    renderChart(data, title, yLabel);
  }, [data]);

  const renderChart = (chartData, title, yLabel) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#c9ced740",
          type: "column",
          height: 350,
          spacing: [20, 20, 20, 20],
        },
        title: {
          text: null,
        },
        yAxis: {
          title: {
            text: yLabel,
            style: {
              color: "#000000",
            },
          },
          gridLineColor: "#ffffff",
          // gridLineWidth:0,
          // lineWidth:2,
          // lineColor: '#000000',
          labels: {
            enabled: false,
          },
        },
        xAxis: {
          categories: chartData?.[0]?.categories,
          title: {
            text: title,
            style: {
              color: "#000000",
            },
          },
          // gridLineWidth:0,
          // lineWidth:2,
          // lineColor: '#000000',
          labels: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        // plotOptions: {
        //     column: {
        //         borderWidth: 0,
        //         groupPadding: 0.1
        //     }
        // },
        series: chartData,
      },
      function (chart) {
        // on complete

        if (chart.series?.[0]?.data?.length < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 100)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      <div id={id}></div>
    </figure>
  );
};
