import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../assets/right-arrow-long.svg";
import LengendContainer from "./LengendContainer";
import { useSelector } from "react-redux";
import { colorMap, getTickIntervals, graphType } from "../utils/constants";
import HC_Regression from "highcharts-regression";
import NoDataFound from "./NoDataFound";

// usage of highcharts-regression
HC_Regression(Highcharts);

const MpgForCityAndHighwayChart = ({ data, filterType }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const [chartData, setChartData] = useState(null);
  const [mpgTargetHighWayArray, setMpgTargetHighWayArray] = useState(null);
  const [odometerTick, setOdometerTick] = useState([]);

  const [mpgTargetCityArray, setMpgTargetCityArray] = useState(null);
  const [chartInfo, setChartInfo] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setChartData(data?.mpgForCityAndHighway);
    if (data && data?.mpgForCityAndHighway.length > 0) {
      let mpgTargetHighWayArray = [];
      let mpgTargetCityArray = [];
      switch (filterType) {
        case graphType.DATE: {
          mpgTargetHighWayArray = data.mpgForCityAndHighway.map((item) => [
            moment(item.statsDate).format("DD"),
            item.mpgTargetHighway,
          ]);
          mpgTargetCityArray = data.mpgForCityAndHighway.map((item) => [
            moment(item.statsDate).format("DD"),
            item.mpgTargetCity,
          ]);
          break;
        }
        case graphType.TRIP: {
          mpgTargetHighWayArray = data.mpgForCityAndHighway.map((item) => [
            item.trip,
            item.mpgTargetHighway,
          ]);
          mpgTargetCityArray = data.mpgForCityAndHighway.map((item) => [
            item.trip,
            item.mpgTargetCity,
          ]);
          break;
        }
        case graphType.MILES: {
          mpgTargetHighWayArray = data.mpgForCityAndHighway.map((item) => [
            item.odometer,
            item.mpgTargetHighway,
          ]);
          mpgTargetCityArray = data.mpgForCityAndHighway.map((item) => [
            item.odometer,
            item.mpgTargetCity,
          ]);
          if (mpgTargetCityArray.length > 1) {
            const tickArray = data.mpgForCityAndHighway
              .map((item) => item.odometer)
              .sort((a, b) => a - b);
            const tArray = getTickIntervals(tickArray);
            setOdometerTick(tArray);
          }
          break;
        }

        default: {
          mpgTargetHighWayArray = data.mpgForCityAndHighway.map((item) => [
            moment(item.statsDate).format("DD"),
            item.mpgTargetHighway,
          ]);
          mpgTargetCityArray = data.mpgForCityAndHighway.map((item) => [
            moment(item.statsDate).format("DD"),
            item.mpgTargetCity,
          ]);
        }
      }

      setChartInfo(data?.information);
      setMpgTargetHighWayArray(mpgTargetHighWayArray);
      setMpgTargetCityArray(mpgTargetCityArray);
      const month = moment(data?.mpgForCityAndHighway[0].statsDate).format(
        "MMMM"
      );
      const year = moment(data?.mpgForCityAndHighway[0].statsDate).format(
        "YYYY"
      );
      setYear(year);
      setMonth(month);
    } else {
      setMpgTargetCityArray(null);
      setMpgTargetHighWayArray(null);
    }
  }, [data]);

  const options = {
    chart: {
      backgroundColor: "#F1F3F5",
      spacing: [50, 50, 30, 20],
      height: 350,
      type: "scatter",
      events: {
        load: function () {
          this.series[2]?.update({
            enableMouseTracking: false,
          });
          this.series[3]?.update({
            enableMouseTracking: false,
          });
        },
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
       <div style="font-size: 11px; left:-60px;top:4px;position:absolute;">
         MPG
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      min: 0,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
      gridLineColor: "#ffffff",
    },
    xAxis: {
      type: "category",
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-100px;top:4px;position:absolute;">
           ${
             filterType === graphType.DATE
               ? `${month},${year}`
               : filterType === graphType.MILES
               ? `By Miles`
               : filterType === graphType.TRIP
               ? `By Trips`
               : `${month},${year}`
           }
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      tickPositions: filterType === graphType.MILES ? odometerTick : null,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
        marker: {
          radius: 3,
        },
      },
      line: {
        dashStyle: "Dash",
      },
    },
    tooltip: {
      style: {
        color: "#353536",
        font: "12px Open Sans",
      },
    },
    series: [
      {
        data: mpgTargetHighWayArray,
        showInLegend: false,
        name: "Mpg Target HighWay",
        regression: mpgTargetHighWayArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[0]?.color],
          // legend: "%eq",
          // name: "%eq | r2: %r",
          dashStyle: "dot",
        },
        color: chartInfo && colorMap[chartInfo[0]?.color],
        marker: {
          symbol: "circle",
        },
      },
      {
        data: mpgTargetCityArray,
        showInLegend: false,
        name: "Mpg Target City",
        regression: mpgTargetCityArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[1]?.color],
          dashStyle: "dot",
        },
        color: chartInfo && colorMap[chartInfo[1]?.color],
        marker: {
          symbol: "circle",
        },
      },
    ],
  };

  return (
    <div key={mpgTargetHighWayArray}>
      <div key={isSidemenuExpanded}>
        {Array.isArray(mpgTargetCityArray) &&
        Array.isArray(mpgTargetHighWayArray) &&
        mpgTargetHighWayArray?.length > 0 ? (
          <>
            <LengendContainer chartInfo={chartInfo} />
            <HighchartsReact highcharts={Highcharts} options={options} />
            <div
              style={{
                width: "100%",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              <p style={{ fontSize: "12px", fontWeight: "600" }}>
                MPG (City/HW)
              </p>
            </div>
          </>
        ) : (
          <>
            <NoDataFound chartName="MPG (City/HW)" />
          </>
        )}
      </div>
    </div>
  );
};

export default MpgForCityAndHighwayChart;
