import "./vehicleUsageProfile.scss";
import MultiSelect from "../../components/common/MultiSelect/MultiSelect";
import Select from "../../components/common/Select/Select";
import { Checkbox } from "../../components/common/Checkbox/Checkbox";
import Filter from "../../components/Filter/Filter";
import React, { forwardRef, useEffect, useState } from "react";
import { BarChart } from "../../components/common/RangeChart/RangeChart";
import ValueChip from "../../components/common/ValueChip/ValueChip";
import Daterangepicker from "../../components/Datepicker/daterangepicker";
import { Button } from "../../components/common/Button/Button";
import { axiosInstance } from "../../services/usage-profile-service";
import {
  ServiceConfig,
  usageProfileEndPoints,
} from "../../services/service-constants";
import moment from "moment";
import metricPreIcon from "../../assets/metric.png";
import intervalPreIcon from "../../assets/interval.png";
import timeIcon from "../../assets/datepicker.png";
import navLeft from "../../assets/left-arrow-square.svg";
import navRight from "../../assets/right-arrow-square.svg";
import { mainAxiosInstance } from "../../services/service";
import { BarChartLarge } from "../../components/common/RangeChart/BarChartLarge";
import { useDispatch, useSelector } from "react-redux";

const VehicleUsageProfile = () => {
  const intervalTypes = {
    trips: "by_trip",
    miles: "by_miles",
    date: "by_date",
  };
  const metrics = ["Miles", "Hours", "Engine Run Time", "Key starts"];
  const interval = ["Lifetime", "Last Trip", "Last Miles", "Date"];
  const [selectAll, setSelectAll] = useState(true);
  const [defaultMetric, setDefaultMetric] = useState("Miles");
  const [defaultInterval, setDefaultInterval] = useState("Lifetime");
  const [chartData, setChartData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [selectedChartData, setSelectedChartData] = useState(null);
  const filterOptions = [
    { displayname: "Engine Speed", id: "engineSpeed", isChecked: true },
    { displayname: "Engine Oil Level", id: "engineOilLevel", isChecked: true },
    {
      displayname: "Remaining Engine Life",
      id: "remainingEngineLife",
      isChecked: true,
    },
    { displayname: "Tire Pressure", id: "tirePressure", isChecked: true },
    { displayname: "Engine Torque", id: "engineTorque", isChecked: true },
    {
      displayname: "Transmission Oil Temperature",
      id: "transmissionOilTemperature",
      isChecked: true,
    },
    {
      displayname: "Engine Oil Temperature",
      id: "engineOilTemperature",
      isChecked: true,
    },
    {
      displayname: "Transmission Gear",
      id: "transmissionGear",
      isChecked: true,
    },
    {
      displayname: "Engine Coolant Temperature",
      id: "engineCoolantTemperature",
      isChecked: true,
    },
    { displayname: "Break Pressure", id: "brakePressure", isChecked: true },
  ];

  const [filterData, setFilterData] = useState([...filterOptions]);
  const [inputData, setInputData] = useState(1000);
  const [showInput, setShowInput] = useState(false);
  const [showDatepicker, setShowDatepicker] = useState(false);

  const [selectedStartDate, setStartDate] = useState(new Date());
  const [selectedEndDate, setEndDate] = useState(new Date());
  const [yAxisLabel, setYAxisLabel] = useState("Total number of miles");
  const [noDataFound, setNoDataFound] = useState(true);

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    const isAnyModuleSelected = newFilterData.some((el) => el.isChecked);
    setNoDataFound(!isAnyModuleSelected);
    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };

  const handleMetricClick = (val) => {
    setDefaultMetric(val);
    const label = `Total number of ${val.toLowerCase()}`;
    setYAxisLabel(label);
  };

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Lifetime":
        setShowInput(false);
        setShowDatepicker(false);
        break;
      case "Last Trip":
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1);
        break;
      case "Last Miles":
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1000);
        break;
      case "Date":
        setShowInput(false);
        setShowDatepicker(true);
        break;
      default:
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1000);
        break;
    }
  };

  const DatepickerCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      className="btn btn-secondary date-selector"
      onClick={onClick}
      ref={ref}
    >
      <img className="time-icon" src={timeIcon} alt="datePicker" />
      {value}
    </div>
  ));

  const handleOnDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleRemove = (val) => {
    setDefaultMetric(defaultMetric.filter((el) => el !== val));
  };
  const valueMap = {
    brakePressure: "breakPressurePsi",
    engineCoolantTemperature: "temperatureInFahrenheit",
    transmissionGear: "transmissionGear",
    engineOilTemperature: "oilTemperatureFahrenheit",
    transmissionOilTemperature: "transmissionOilTemperatureFahrenheit",
    engineTorque: "engineTorque",
    tirePressure: "tirePressurePsi",
    remainingEngineLife: "remainingEngineLife",
    engineOilLevel: "engineOilLevel",
    engineSpeed: "engineSpeed",
  };

  const colorMap = {
    yellow: "#FDFD62",
    green: "#A9D18E",
    red: "#F23732",
  };

  const getMilesData = (data) => {
    const milesData = [];
    Object.keys(data).map((val) => {
      const detailsData = [];
      const categoryData = [];
      const isRatedRpm = [];
      data[val].forEach((value) => {
        detailsData.push({
          y: value.miles,
          color: colorMap[value.threshold.toLowerCase()],
        });
        categoryData.push(value[valueMap[val]]);
        isRatedRpm.push(!!value.ratedRpm);
      });
      milesData.push({
        type: val,
        data: detailsData,
        categories: categoryData,
        ratedRpm: isRatedRpm,
      });
    });
    return milesData;
  };

  const getHoursData = (data, categoryValue) => {
    const milesData = [];
    Object.keys(data).map((val) => {
      const detailsData = [];
      const categoryData = [];
      const isRatedRpm = [];
      data[val].forEach((value) => {
        detailsData.push({
          y: value.hours,
          color: colorMap[value.threshold.toLowerCase()],
        });
        categoryData.push(value[valueMap[val]]);
        isRatedRpm.push(!!value.ratedRpm);
      });
      milesData.push({
        type: val,
        data: detailsData,
        categories: categoryData,
        ratedRpm: isRatedRpm,
      });
    });
    return milesData;
  };

  const getEngineRunTimeData = (data, categoryValue) => {
    const milesData = [];
    Object.keys(data).map((val) => {
      const detailsData = [];
      const categoryData = [];
      const isRatedRpm = [];
      data[val].forEach((value) => {
        detailsData.push({
          y: value.engineRunTime,
          color: colorMap[value.threshold.toLowerCase()],
        });
        categoryData.push(value[valueMap[val]]);
        isRatedRpm.push(!!value.ratedRpm);
      });
      milesData.push({
        type: val,
        data: detailsData,
        categories: categoryData,
        ratedRpm: isRatedRpm,
      });
    });
    return milesData;
  };

  const getKeyStartData = (data, categoryValue) => {
    const milesData = [];
    Object.keys(data).map((val) => {
      const detailsData = [];
      const categoryData = [];
      const isRatedRpm = [];
      data[val].forEach((value) => {
        detailsData.push({
          y: value.keyStarts,
          color: colorMap[value.threshold.toLowerCase()],
        });
        categoryData.push(value[valueMap[val]]);
        isRatedRpm.push(!!value.ratedRpm);
      });
      milesData.push({
        type: val,
        data: detailsData,
        categories: categoryData,
        ratedRpm: isRatedRpm,
      });
    });
    return milesData;
  };

  const getChartData = async (response) => {
    const data = [];
    let categoryData = [];
    const metricSelected = [defaultMetric];
    metricSelected.forEach((val) => {
      const sectionData = {};
      sectionData["data"] = [];
      switch (val) {
        case "Miles": {
          sectionData["name"] = "Miles";
          const milesOutputData = getMilesData(response);
          sectionData["data"] = milesOutputData;
          break;
        }
        case "Hours": {
          sectionData["name"] = "Hours";
          const hoursOutputData = getHoursData(response);
          sectionData["data"] = hoursOutputData;
          break;
        }
        case "Engine Run Time": {
          sectionData["name"] = "Engine Run Time";
          const outputData = getEngineRunTimeData(response);
          sectionData["data"] = outputData;
          break;
        }
        case "Key starts": {
          sectionData["name"] = "Key starts";
          const outputData = getKeyStartData(response);
          sectionData["data"] = outputData;
          break;
        }
      }
      data.push(sectionData);
    });
    const mainData = [];
    data.forEach((value) => {
      const stepData = [];
      value.data.forEach((val) => {
        val.name = value.name;
      });
    });

    Object.keys(valueMap).map((key) => {
      data.forEach((val) => {
        const requiredData = val.data.filter((el) => el.type === key);
        delete requiredData[0].type;
        const index = mainData.findIndex((element) => {
          if (element?.name === key) {
            return true;
          }
          return false;
        });
        if (index === -1) {
          mainData.push({ name: key, data: requiredData });
        } else {
          mainData.map((el) => {
            if (el.name === key) {
              el.data = [...el.data, ...requiredData];
            }
          });
        }
      });
    });
    setChartData(mainData);
  };

  const prepareChartData = (dataSet) => {
    getChartData(dataSet);
  };
  const getPageData = (val) => {
    const url = `${ServiceConfig.usage_profile}`;
    let params = {};
    switch (defaultInterval) {
      case "Last Miles": {
        params = {
          type: intervalTypes.miles,
          vinNumber: val.vinNumber,
          organizationId: 1,
          lastMiles: inputData,
        };
        break;
      }
      case "Last Trip": {
        params = {
          type: intervalTypes.trips,
          vinNumber: val.vinNumber,
          organizationId: 1,
          lastTrips: inputData,
        };
        break;
      }
      case "Date": {
        params = {
          type: intervalTypes.date,
          vinNumber: val.vinNumber,
          organizationId: 1,
          fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
          toDate: moment(selectedEndDate).format("yyyy-MM-DD"),
        };
        break;
      }
      case "Lifetime": {
        params = {
          type: intervalTypes.date,
          vinNumber: val.vinNumber,
          organizationId: 1,
        };
        break;
      }
      default: {
        params = {
          type: intervalTypes.miles,
          vinNumber: val.vinNumber,
          organizationId: 1,
          lastMiles: inputData,
        };
      }
    }

    mainAxiosInstance
      .post(url, { ...params })
      .then((response) => {
        setNoDataFound(false);
        prepareChartData(response.data);
      })
      .catch((er) => {
        setNoDataFound(true);
        throw er;
      });
  };
  const getDataForBarChart = (element) => {
    const barChartData = chartData.filter((el) => el.name === element.id);
    return barChartData?.[0]?.data;
  };

  const handleChartClick = (el) => {
    setShowModal(true);
    setSelectedChartData(el);
    setShowFilter(false);
  };

  const showPrev = (el) => {
    const data = filterData.filter((el) => el.isChecked);
    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== 0) {
        selectElement = data[index - 1];
      } else if (index === 0) {
        selectElement = data[data.length - 1];
      }
      setNoDataFound(false);
      setSelectedChartData(selectElement);
    } else {
      setNoDataFound(true);
    }
  };

  const clickOnClose = (e) => {
    setShowModal(false);
    setShowFilter(true);
  };
  const showNext = (el) => {
    const data = filterData.filter((el) => el.isChecked);
    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== data.length - 1) {
        selectElement = data[index + 1];
      } else if (index === data.length - 1) {
        selectElement = data[0];
      }
      setNoDataFound(false);
      setSelectedChartData(selectElement);
    } else {
      setNoDataFound(true);
    }
  };

  useEffect(() => {
    getPageData({
      vinNumber: vinNumber,
    });
  }, [
    defaultMetric,
    defaultInterval,
    inputData,
    selectedStartDate,
    selectedEndDate,
  ]);

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });

    setFilterData(...[getUpdatedfilterData]);
    setNoDataFound(false);
    setSelectAll(val);
  };
  return (
    <div className="page-wrapper">
      <div className="flex page-content-header">
        <div className="page-options-container">
          <Select
            heading="Metric For Life"
            options={metrics}
            defaultSelected={defaultMetric}
            handleOptionClick={handleMetricClick}
            preIcon={metricPreIcon}
          />
          <Select
            heading="Data Interval"
            options={interval}
            defaultSelected={defaultInterval}
            handleOptionClick={handleIntervalOptionClick}
            preIcon={intervalPreIcon}
          />
          {showInput && (
            <input
              type="text"
              className="value-input"
              placeholder={`Please enter ${defaultInterval}`}
              value={inputData}
              onChange={(e) => {
                setInputData(e.target.value);
              }}
            />
          )}
          {/* startDate={selectedStartDate} endDate={selectedEndDate} handleOnChange={handleOnDateChange} */}
          {showDatepicker && (
            <Daterangepicker
              customInput={<DatepickerCustomInput />}
              handleOnChange={handleOnDateChange}
            />
          )}
          {/* {showButton && <Button title="Submit" handleClick={handleSubmit} styleClasses="btn btn-primary metric-submit"/>} */}
        </div>
        {showFilter === true && (
          <div className="page-filter">
            <Filter data={AllSelect} selectAll={selectAll}>
              {filterData.map((el) => (
                <Checkbox
                  title={el.displayname}
                  handleInputClick={() => handleFilterClick(el)}
                  alignProp={{
                    borderTop: "0.5px solid #C4C4C4",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "10px",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                  }}
                  checkboxStyle={{ marginRight: "10px" }}
                  isChecked={el.isChecked}
                />
              ))}
            </Filter>
          </div>
        )}
      </div>
      {!noDataFound && (
        <div className="legend-wrapper">
          <div className="legend">
            <div className="green-legend">
              <span className="color-box green"></span>
              <span className="color-legend">Within Expected range</span>
            </div>
            <div className="yellow-legend">
              <span className="color-box yellow"></span>
              <span className="color-legend">
                Within Expected range but high/ low
              </span>
            </div>
            <div className="red-legend">
              <span className="color-box red"></span>
              <span className="color-legend">
                Alert / Warning outside expected range
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="page-chart-container vehicle-usage-charts">
        {!noDataFound &&
          filterData.map(
            (el) =>
              el.isChecked &&
              !showModal && (
                <BarChart
                  id={el.id}
                  data={getDataForBarChart(el)}
                  // categories={getCategoryDataForBarChart(el)}
                  title={el.displayname}
                  yLabel={yAxisLabel}
                  onClickHandler={() => handleChartClick(el)}
                />
              )
          )}
        {showModal && !noDataFound && (
          <>
            <div
              className="nav-icons"
              onClick={() => showPrev(selectedChartData)}
            >
              <img src={navLeft} alt="previous" />
            </div>
            <BarChartLarge
              id={selectedChartData.displayname}
              data={getDataForBarChart(selectedChartData)}
              title={selectedChartData.displayname}
              yLabel={yAxisLabel}
              clickOnClose={clickOnClose}
            />
            <div
              className="nav-icons"
              onClick={() => showNext(selectedChartData)}
            >
              <img src={navRight} alt="previous" />
            </div>
          </>
        )}

        {noDataFound && (
          <div className="no-data">
            Sorry, We could not find any data for the given search criteria.
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleUsageProfile;
