import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from "../types";

import * as AuthService from "../../services/auth-service";

export const login = (userLoginData) => (dispatch) => {
  return AuthService.login(userLoginData).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { userToken: data },
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL,
      });

      return Promise.reject(error);
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const register = (userData) => (dispatch) => {
  return AuthService.register(userData).then(
    (data) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: REGISTER_FAIL,
      });
      if (error && error.response && error.response.status) {
        var newData = {
          msg: error.toString(),
          data: error.response.data,
          status: error.response.status,
        };
        return Promise.reject(newData);
      }
    }
  );
};
