import React, { useState } from "react";
import { Navigate, Outlet } from "react-router";
import Breadcrumbs from "../components/common/Breadcrumb/Breadcrumbs";
import { NavigationMenu } from "../components/common/NavigationMenu";
import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";
import routes, { NavigationMenuList } from "./constants";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.authReducer);
  const [isExpanded, setExpanded] = useState(true);
  const expandClicked = (val) => {
    setExpanded(val);
  };
  return isLoggedIn ? (
    <>
      <Header />
      <div className="container flex">
        <NavigationMenu
          className="nav-content"
          menuItmes={NavigationMenuList}
          clickExpand={(val) => expandClicked(val)}
          dashboardRoute="/dashboard"
        />
        <div className={`content ${isExpanded ? "" : "expand"}`}>
          <Breadcrumbs routes={routes} />
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
