import { useEffect } from "react";
import Highcharts from "highcharts";
import "./RangeChart.scss";
import close from "../../../assets/close.svg";
import { useSelector } from "react-redux";
// import { engineSpeedMile, engineSpeedLastTrip } from "../../../_mock/vehicle-usage-profile/engineSpeed";

export const BarChartLarge = ({ id, data, title, yLabel, clickOnClose }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );
  let ratedRpmIndex = null;
  useEffect(() => {
    renderChart(data, title, yLabel);
  }, [data, isSidemenuExpanded]);

  const renderChart = (chartData, title, yLabel) => {
    ratedRpmIndex = (data?.[0]?.ratedRpm || []).findIndex((el) => el);
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#c9ced740",
          type: "column",
          spacing: [20, 20, 20, 20],
        },
        title: {
          text: null,
        },
        yAxis: {
          title: {
            text: yLabel,
            style: {
              color: "#000000",
            },
          },
          gridLineColor: "#ffffff",
        },
        xAxis: {
          categories: chartData?.[0]?.categories,
          title: {
            text: title,
            style: {
              color: "#000000",
            },
          },
          plotLines:
            ratedRpmIndex > -1
              ? [
                  {
                    color: "black", // Color value
                    dashStyle: "ShortDash", // Style of the plot line. Default to solid
                    value: ratedRpmIndex, // Value of where the line will appear
                    width: 2, // Width of the line
                    zIndex: 5,
                    label: {
                      text: "Rated Rpm", // Content of the label.
                      align: "left", // Positioning of the label.
                    },
                  },
                ]
              : [],
          // gridLineWidth:0,
          // lineWidth:2,
          // lineColor: '#000000',
          // labels: {
          //     enabled: false
          // }
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        // plotOptions: {
        //     column: {
        //         borderWidth: 0,
        //         groupPadding: 0.1
        //     }
        // },
        series: chartData,
      },
      function (chart) {
        // on complete

        if (chart.series?.[0]?.data?.length < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 100)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <div class="figure-large-container">
      <div className="modal-close" onClick={clickOnClose}>
        <img src={close} alt="close" />
      </div>
      <figure className="highcharts-figure-large">
        <div className="figurre-content" id={id}></div>
      </figure>
    </div>
  );
};
