import React from "react";
import "./VibrationalAnalytics.scss";
import { buttonStrings } from "./utils/utils";
import ButtonCard from "./components/buttonCard";
import { useState } from "react";
import { useSelector } from "react-redux";
import VibrationalAnalysisTable from "./VibrationalAnalysisTable";
import { AiOutlineArrowLeft } from "react-icons/ai";

const VibrationalAnalytics = () => {
  const performanceData = useSelector(
    (state) => state.vAnalytics.performanceData
  );
  const [isTableVisibe, setIsTableVisivle] = useState(false);

  return (
    <div className="page-wrapper vibrational-analytics">
      {!isTableVisibe ? (
        <>
          <h3 className="heading">
            Select Component​ to generate Performance profile
          </h3>
          <div className="button-container">
            <div className="left-container">
              {/* TO DO : Need to remove disabled prop once we get the data */}
              {buttonStrings.map((button, index) => (
                <ButtonCard
                  buttonText={button.text}
                  id={button.id}
                  disabled={index !== 0}
                  buttonContent={button}
                  showTable={() => setIsTableVisivle(true)}
                />
              ))}
            </div>
            <div className="right-container"></div>
          </div>
        </>
      ) : (
        <>
          <button
            style={{ display: "flex", alignItems: "center", gridGap: "4px" }}
            onClick={() => setIsTableVisivle(false)}
          >
            <AiOutlineArrowLeft />
            Go Back
          </button>
          <VibrationalAnalysisTable performanceData={performanceData} />
        </>
      )}
    </div>
  );
};

export default VibrationalAnalytics;
