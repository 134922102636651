import React, { useEffect, useState } from "react";
import { FishboneChart } from "react-fishbone-chart";
import { useDispatch, useSelector } from "react-redux";
import ValueChip from "../../../../components/common/ValueChip/ValueChip";
import { mainAxiosInstance } from "../../../../services/service";
import { ServiceConfig } from "../../../../services/service-constants";
import { addRootCausePayload } from "../../../../store/rootCauseAnalysis/actions";
import "./index.scss";
import { getPotentialRootCauseData, prepareIshikawaData } from "./utils";

const IshikawaDiagram = () => {
  const dispatch = useDispatch();
  let rcaPayload = useSelector(
    (state) => state.rootCauseReducer.rootCauseAnalysisPayload
  );
  if (!rcaPayload || rcaPayload === {}) {
    rcaPayload = JSON.parse(
      window.localStorage.getItem("root-cause-payload") || {}
    );
    dispatch(addRootCausePayload({ ...rcaPayload }));
  }
  const [data, setData] = useState(null);
  const [noDataFound, setNoDataFound] = useState(true);
  const [possibleCauses, setPossibleCauses] = useState([]);

  const prepareData = (response) => {
    const data = prepareIshikawaData(response.data);
    setData({ ...data });
    setPossibleCauses(getPotentialRootCauseData(response.data));
  };

  const getData = (vin, oId, dcode, stats) => {
    let url = `${ServiceConfig.mod_ishikawa_diagram}`;
    let params = {
      vin_number: vin,
      organization_id: oId,
      stats_date: stats,
      dtc_code: dcode,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        prepareData(response);
        setNoDataFound(false);
      })
      .catch((er) => {
        setNoDataFound(true);
        throw er;
      });
  };
  useEffect(() => {
    if (
      rcaPayload.vin &&
      rcaPayload.oId &&
      rcaPayload.dcode &&
      rcaPayload.stats
    ) {
      getData(
        rcaPayload.vin,
        rcaPayload.oId,
        rcaPayload.dcode,
        rcaPayload.stats
      );
    }
  }, []);
  return (
    <>
      {!noDataFound && possibleCauses.length > 0 && (
        <>
          <div className="possible-cause-wrapper">
            <div className="problem">Potential Causes</div>
            <ul className="problem-list-ul">
              {possibleCauses.map((elem) => {
                let cause = `${elem.primaryCause} -> ${elem.secondaryCause}`;
                if (elem.tertiaryCause) {
                  cause += ` -> ${elem.tertiaryCause}`;
                }
                return (
                  <li className="problem-list">
                    <ValueChip
                      text={cause}
                      showClose={false}
                      handleRemove={() => {}}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
      {!noDataFound && (
        <div className="ishikawa-wrapper">
          <div className="ishikawa-diagram">
            {data && <FishboneChart data={{ ...data }} />}
          </div>
        </div>
      )}
      {noDataFound && (
        <div className="no-data">Sorry, We could not find any data.</div>
      )}
    </>
  );
};

export default IshikawaDiagram;
