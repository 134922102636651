import React, { useEffect, useState } from "react";
import "react-grid-layout/css/styles.css";
import { useSelector } from "react-redux";
import "react-resizable/css/styles.css";
import { Checkbox } from "../../../components/common/Checkbox/Checkbox";
import Filter from "../../../components/Filter/Filter";
import { filterOptions } from "../utils/utils";
import "./VehicleRealtimeData.scss";
import Widget from "./Widget";
import WidgetExpanded from "./WidgetExpanded";

const fuelLevelDataLayout = {
  x: 0,
  y: 0,
  w: 1,
  h: 1,
  i: "0",
};

const engineSpeedLayout = {
  x: 1,
  y: 0,
  w: 1,
  h: 1,
  i: "1",
};
const gasPedalDataLayoutSingleWidth = {
  x: 2,
  y: 0,
  w: 1,
  h: 1,
  i: "2",
};
/** To prevent cutting off of x-xasis label in chart when react-grid-layout is not responsive */
const gasPedalDataLayoutDoubleWidth = {
  x: 2,
  y: 0,
  w: 2,
  h: 1,
  i: "2",
};
const airPressureDataLayout = {
  x: 0,
  y: 1,
  w: 1,
  h: 1,
  i: "3",
};

const oilPressureDataLayout = {
  x: 1,
  y: 1,
  w: 1,
  h: 1,
  i: "4",
};

const brakePedalDataLayoutSingleWidth = {
  x: 2,
  y: 1,
  w: 1,
  h: 1,
  i: "5",
};
const brakePedalDataLayoutDoubleWidth = {
  x: 2,
  y: 1,
  w: 2,
  h: 1,
  i: "5",
};
const engineLoadDataLayout = {
  x: 0,
  y: 2,
  w: 1,
  h: 1,
  i: "6",
};

const engineOilDataLayout = {
  x: 1,
  y: 2,
  w: 1,
  h: 1,
  i: "7",
};

const engineCoolantDataLayout = {
  x: 0,
  y: 3,
  w: 1,
  h: 1,
  i: "8",
};

const tirePressureDataLayout = {
  x: 1,
  y: 3,
  w: 1,
  h: 1,
  i: "9",
};

const VehicleRealTimeRefactor = () => {
  const [filterData, setFilterData] = useState(filterOptions);
  const isMenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );
  const [layout, setLayout] = useState(null);
  const [keyArray, setKeyArray] = useState(null);
  const [selectAll, setSelectAll] = useState(true);

  const setSelectAllvalue=(data)=>{
    const checkAllSelected = data.filter((el) =>el.isChecked===false);
    if(checkAllSelected.length>0){
      setSelectAll(false);
    }else{
      setSelectAll(true);
    }
  }

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };

  /**Reset filter options on component render */
  useEffect(() => {
    const newFilterData = filterData.map((el) => {
      return { ...el, isChecked: true };
    });
    setFilterData(newFilterData);
  }, []);

  useEffect(() => {
    const arrayItem = filterData
      .filter((filterItem) => filterItem.isChecked === true)
      .map((filterItem) => filterItem.key);
    setKeyArray(arrayItem);
  }, [filterData]);

  useEffect(() => {
    const tempLayout = [];
    if (Array.isArray(keyArray)) {
      if (keyArray.includes("fuelLevelData")) {
        tempLayout.push(fuelLevelDataLayout);
      }
      if (keyArray.includes("engineSpeedData")) {
        tempLayout.push(engineSpeedLayout);
      }

      if (keyArray.includes("gasPedalData")) {
        /** To prevent double width  from causing chart render issue */
        if (keyArray?.length > 9) {
          tempLayout.push(gasPedalDataLayoutDoubleWidth);
        } else {
          tempLayout.push(gasPedalDataLayoutSingleWidth);
        }
      }
      if (keyArray.includes("airPressureData")) {
        tempLayout.push(airPressureDataLayout);
      }
      if (keyArray.includes("oilPressureData")) {
        tempLayout.push(oilPressureDataLayout);
      }
      if (keyArray.includes("brakePedalData")) {
        if (keyArray?.length > 9) {
          tempLayout.push(brakePedalDataLayoutDoubleWidth);
        } else {
          tempLayout.push(brakePedalDataLayoutSingleWidth);
        }
      }
      if (keyArray.includes("engineLoadData")) {
        tempLayout.push(engineLoadDataLayout);
      }
      if (keyArray.includes("engineOilData")) {
        tempLayout.push(engineOilDataLayout);
      }
      if (keyArray.includes("engineCoolantData")) {
        tempLayout.push(engineCoolantDataLayout);
      }
      if (keyArray.includes("tirePressureData")) {
        tempLayout.push(tirePressureDataLayout);
      }
    }
    setLayout(tempLayout);
  }, [keyArray]);

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
        return {...el ,isChecked:val}
       }); 
        setFilterData(getUpdatedfilterData );
        setSelectAll(val);
     
     }


  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "10px",
            marginTop: "10px",
          }}
        >
          <Filter data={AllSelect}  selectAll={selectAll}>
            {filterData.map((el, index) => (
              <div key={index}>
                <Checkbox
                  title={el.displayname}
                  handleInputClick={() => handleFilterClick(el)}
                  alignProp={{
                    borderTop: "0.5px solid #C4C4C4",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "10px",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                  }}
                  checkboxStyle={{ marginRight: "10px" }}
                  isChecked={el.isChecked}
                />
              </div>
            ))}
          </Filter>
        </div>
        {isMenuExpanded ? (
          <WidgetExpanded layoutKey={layout} keyArray={keyArray} />
        ) : (
          <Widget layoutKey={layout} keyArray={keyArray} />
        )}
      </>
    </>
  );
};

export default VehicleRealTimeRefactor;
