import React from "react";
import "./index.scss";
import { Button } from "../../components/common/Button/Button";
import Input from "../../components/common/Input";
import Modal from "../../components/common/Modal/Modal";

const Resetpassword = ({
  handleSPInputChange,
  SPverifycode,
  SPpassword,
  SPcpassword,
  error,
  handleClose,
  handleClick,
  errorSPMsg,
  handleKeyDown,
}) => {
  return (
    <>
      <Modal
        type={"medium"}
        title={""}
        titlePosition={"center"}
        isError={false}
        handleClose={handleClose}
      >
        <div className="rsetpwd__Form">
          <div className="rsetpwd__Form--title">
            <p>Reset Password</p>
            <span>Enter the 6 digit passcode that sent to your mail.</span>
          </div>
          <span className="Form--error">{errorSPMsg}</span>
          <div className="rsetpwd__Form--inputs">
            <Input
              htmlForId="SPverifycode"
              type="password"
              placeholder="Enter 6 digit passcode"
              value={SPverifycode}
              handleOnChange={handleSPInputChange}
              error={error.SPverifycode}
              onBlur={handleSPInputChange}
              onKeyDown={handleKeyDown}
            />
            <Input
              htmlForId="SPpassword"
              type="password"
              placeholder="Enter new password"
              value={SPpassword}
              handleOnChange={handleSPInputChange}
              error={error.SPpassword}
              onBlur={handleSPInputChange}
              onKeyDown={handleKeyDown}
            />
            <Input
              htmlForId="SPcpassword"
              type="password"
              placeholder="Confirm Password"
              value={SPcpassword}
              handleOnChange={handleSPInputChange}
              error={error.SPcpassword}
              onBlur={handleSPInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="rsetpwd__Form--btn">
            <Button
              title="Cancel"
              handleClick={handleClose}
              styleClasses="btn btn-secondary"
            />
            <Button
              title="Update"
              handleClick={handleClick}
              styleClasses="btn btn-primary"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Resetpassword;
