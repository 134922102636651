import React from "react";
import { ReactComponent as ChatIcon } from "../assets/message-icon.svg";
import ChatImage from "../assets/message-box.png";
import { useState } from "react";

const DtcHeading = () => {
  const [isChatVisible, setIsChatVisible] = useState(false);
  return (
    <>
      <div className="dtc_heading_row">
        <div className="dtc_heading_container">
          <h2>P01012-Check Airflow Clog</h2>
        </div>
        <div
          className="live_chat_button"
          onClick={() => setIsChatVisible(!isChatVisible)}
        >
          Live chat<div className="red-dot"></div>
        </div>
      </div>
      <div
        className="chat-bot"
        onClick={() => setIsChatVisible(!isChatVisible)}
      >
        <ChatIcon />
      </div>
      {isChatVisible && (
        <div
          className="chat-image"
          onClick={() => setIsChatVisible(!isChatVisible)}
        >
          <img src={ChatImage} alt="" />
        </div>
      )}
    </>
  );
};

export default DtcHeading;
