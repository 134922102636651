import { useEffect, useState } from "react";
import { mainAxiosInstance } from "../../../../services/service";
import { ServiceConfig } from "../../../../services/service-constants";
import { Button } from "../../../common/Button/Button";
import Modal from "../../../common/Modal/Modal";
import DTCDetails from "../DTCDetails";
import Filter from "../../../Filter/Filter";
import { Checkbox } from "../../../common/Checkbox/Checkbox";
import "./OnBoard.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const OnBoard = () => {
  const navigate = useNavigate();
  const [onBoardData, setOnBoardData] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedDTC, setSelectedDTC] = useState("");
  const [selectedStatsDate, setSelectedStatsDate] = useState("");
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  const filterOptions = [
    { displayname: "Engine", isChecked: true },
    { displayname: "Transmission", isChecked: true },
    {
      displayname: "Body Control Module",
      isChecked: true,
    },
    { displayname: "Steering Control Module", isChecked: true },
    { displayname: "ABS", isChecked: true },
    {
      displayname: "Air Bag",
      isChecked: true,
    },
    {
      displayname: "Instrument Cluster Fields",
      isChecked: true,
    },
  ];

  const [filterData, setFilterData] = useState([...filterOptions]);

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };

  const getTableData = (val) => {
    const currentTrans = val.filter(
      (value) =>
        value.dtcStatus === "current" && value.category === "Transmission"
    );
    const currentABS = val.filter(
      (value) => value.dtcStatus === "current" && value.category === "ABS"
    );
    const pendingTrans = val.filter(
      (value) =>
        value.dtcStatus === "pending" && value.category === "Transmission"
    );
    const pendingABS = val.filter(
      (value) => value.dtcStatus === "pending" && value.category === "ABS"
    );
    const historicalTrans = val.filter(
      (value) =>
        value.dtcStatus === "historical" && value.category === "Transmission"
    );
    const historicalABS = val.filter(
      (value) => value.dtcStatus === "historical" && value.category === "ABS"
    );
    const aggregatedCurrent = [];
    if (currentTrans.length) {
      const obj = { type: "Transmission", values: [...currentTrans] };
      aggregatedCurrent.push(obj);
    }
    if (currentABS.length) {
      const obj = { type: "ABS", values: [...currentABS] };
      aggregatedCurrent.push(obj);
    }

    const aggregatedPending = [];
    if (pendingTrans.length) {
      const obj = { type: "Transmission", values: [...pendingTrans] };
      aggregatedPending.push(obj);
    }
    if (pendingABS.length) {
      const obj = { type: "ABS", values: [...pendingABS] };
      aggregatedPending.push(obj);
    }

    const aggregatedHistorical = [];
    if (historicalTrans.length) {
      const obj = { type: "Transmission", values: [...historicalTrans] };
      aggregatedHistorical.push(obj);
    }
    if (historicalABS.length) {
      const obj = { type: "ABS", values: [...historicalABS] };
      aggregatedHistorical.push(obj);
    }

    const aggregated = [];
    if (aggregatedCurrent.length) {
      const obj = { name: "current", values: [...aggregatedCurrent] };
      aggregated.push(obj);
    }
    if (aggregatedPending.length) {
      const obj = { name: "pending", values: [...aggregatedPending] };
      aggregated.push(obj);
    }
    if (aggregatedHistorical.length) {
      const obj = { name: "historical", values: [...aggregatedHistorical] };
      aggregated.push(obj);
    }
    setOnBoardData([...aggregated]);
  };

  const getOnBoardData = (val) => {
    const url = `${ServiceConfig.vehicle_health_check}dtc_all_modules`;
    const params = {
      vin_number: val.vin_Number,
      organization_id: 1,
    };

    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        getTableData(response.data);
      })
      .catch((er) => {
        throw er;
      });
  };

  useEffect(() => {
    getOnBoardData({
      vin_Number: vin_Number,
    });
  }, []);

  const renderHeading = (value) => {
    if (value === "current") {
      return <div className="onboard-dtc-hdr">Current DTC</div>;
    } else if (value === "pending") {
      return <div className="onboard-dtc-hdr">Pending DTC</div>;
    } else if (value === "historical") {
      return <div className="onboard-dtc-hdr">Historical DTC</div>;
    }
  };

  const handleClick = (dtc) => {
    setSelectedDTC(dtc.dtcCode);
    setSelectedStatsDate(dtc.statsDate);
    setShowModal(true);
  };

  const renderDetails = (value) => {
    return (
      <div className="onboard-dtc-left-wrap flex-row">
        <div className="flex-column">
          <div className="onboard-dtc-card">
            <div className="onboard-dtc-param">{value.dtcCode}</div>
          </div>
        </div>
        <div className="onboard-dtc-right-wrap">
          <div className="onboard-dtc-description">{value.description}</div>
          <div className="onboard-dtc-update-date">{value.statsDate}</div>
          <div className="onboard-dtc-details">
            <Button
              title="Show Details"
              handleClick={() => {
                handleClick(value);
              }}
              styleClasses=""
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  const checkIfCategoryToShow = (value) => {
    const filteredData = filterData.filter(
      (el) => el.displayname.toLowerCase() === value.type.toLowerCase()
    );
    return filteredData?.[0].isChecked;
  };

  const renderBody = (value) => {
    return (
      <>
        <div className="onboard-dtc-section flex-column">
          <div className="onboard-dtc-right-section flex-column">
            <div
              className={`onboard-dtc-wrap flex-column ${value.type.toLowerCase()}`}
            >
              <div className="onboard-dtc-type">{value.type}</div>
              {value.values.map((el) => {
                return renderDetails(el);
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });
    setFilterData(getUpdatedfilterData);
    setSelectAll(val);
  };

  const goToComponentSubSystemHistory = (data) => {
    navigate("./component-system-history", {
      relative: "route",
      state: { subSystem: data.subSystem, date: data.date },
    });
  };

  return (
    <>
      <div className="page-wrapper onboard_page_wrapper">
        <div className="onboard-container">
          <div className="flex page-content-header onboard-filter">
            <div className="page-filter">
              <Filter data={AllSelect} selectAll={selectAll}>
                {filterData.map((el) => (
                  <Checkbox
                    title={el.displayname}
                    handleInputClick={() => handleFilterClick(el)}
                    alignProp={{
                      borderTop: "0.5px solid #C4C4C4",
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "10px",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                    }}
                    checkboxStyle={{ marginRight: "10px" }}
                    isChecked={el.isChecked}
                  />
                ))}
              </Filter>
            </div>
          </div>
          <div className="onboard-dtc-container flex flex-column">
            {onBoardData.map((type) => {
              return (
                <>
                  <div
                    className={`dtc-category-container padding-15 ${type.name}`}
                  >
                    {renderHeading(type.name)}
                    {type.values.map((category) => {
                      return (
                        checkIfCategoryToShow(category) && renderBody(category)
                      );
                    })}
                  </div>
                </>
              );
            })}
            {/* <div className="onboard-dtc-right-section">
              <div className=""></div>
              </div> */}
          </div>
        </div>
        {showModal && (
          <Modal
            type={"large"}
            title={"DTC Details"}
            wrapperClass="main-diagnostics-modal"
            titlePosition={"center"}
            isError={false}
            handleClose={() => setShowModal(false)}
          >
            <DTCDetails
              dtcValue={selectedDTC}
              statsDate={selectedStatsDate}
              handleClose={() => setShowModal(false)}
              handleClick={goToComponentSubSystemHistory}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default OnBoard;
