export const graphType = {
  DATE: "by_date",
  MILES: "by_miles",
  TRIP: "by_trip",
};

export const chartType = {
  FUEL_ECONOMY: "fuel Economy",
  OPERATION_AND_USAGE: "Operation and Usage",
  COMPONENT_EFFICIENCIES: "Component Efficiencies",
};

export const filterOptions = [
  {
    displayname: "Fuel Economy",
    id: chartType.FUEL_ECONOMY,
    isChecked: true,
    type: "dualLine",
    label: "Fuel Economy",
    title: "Fuel Economy",
  },
  {
    displayname: "Operation and Usage",
    id: chartType.OPERATION_AND_USAGE,
    isChecked: true,
    type: "singleLine",
    label: "Operation and Usage",
    title: "Operation and Usage",
  },
  {
    displayname: "Component Efficiencies",
    id: chartType.COMPONENT_EFFICIENCIES,
    isChecked: true,
    type: "singleColumn",
    label: "Component Efficiencies",
    title: "Component Efficiencies",
  },
];

export const colorMap = {
  green: "#56A64B",
  blue: "#0954E5",
  pink: "#FD8390",
  orange: "#FF725E",
  purple: "#AC05FB",
};

export const token =
  "eyJraWQiOiI3QUJ3dVVSbzBmTFBhUTlcL2lad21Fd2ZsXC9reDY0bGNHV0Y1bVBiaXRzb1E9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI5N2ZhNGJhYy02ZWY1LTQyOGUtODFmOS1mY2U0MTEzYjgwNTIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX3VYZjZtOUc4bCIsImNsaWVudF9pZCI6IjUybGkwazJob3FmdG5vbWl0YzJiZDljZDJmIiwib3JpZ2luX2p0aSI6ImZmNzFmMGU3LWIyY2QtNDk0ZC1iMjAxLTVhZTc2Y2UxMzNjYyIsImV2ZW50X2lkIjoiZDc3MWRjNzMtNzk4Mi00MTcyLWI5Y2EtZjc4YzRkNzg3N2RiIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY2NDM0OTQzMCwiZXhwIjoxNjY0MzUzMDMwLCJpYXQiOjE2NjQzNDk0MzAsImp0aSI6IjBiYzgwNDMyLTgxMjQtNGRlYS05MmM0LWJhZmE5MzIzNDIwMCIsInVzZXJuYW1lIjoiYWRtaW5AdGVzbGEuY29tIn0.OoQQ8eZLamhHW-kT3zTx2RtqNeIUdZ2vcGxXBbzw_XTd6rD_UIZQFurYZ13uXySH2dYWbxwDNPRVntxzA9OKoRFnuINYGHywJTZh4Np5EKB58ctC_bM6wRnWaCvr_gRyELzmM05FGUIAiqiNFbrcckBvHobnRe6mztFi6oPlrfb47Twyx7Y8JjtR64hrQQkNsWWe2mRkn9skm79hIoCBpUBXADmev7TYqtycr2JAQGsrxkq8qYcmSKntsJ-mymOGgRLSga_3EpwykZGLUaJc7_BPt8Ct5whDreEMFPVAfeBQ19bteDIapwzYotIAInFBpG-2Hw3Q5TC-2SnaVZ7cNw";

export const getTickIntervals = (tickArray) => {
  const diff = Math.abs(tickArray[tickArray.length - 1] - tickArray[0]);
  const increment = Math.trunc(diff / 7);
  let tArray = [];
  for (
    let i = tickArray[0];
    i <= tickArray[tickArray.length - 1];
    i = i + increment
  ) {
    tArray.push(i);
  }
  return tArray;
};
