import React, { useCallback, useEffect } from "react";
import "./index.scss";

const Input = ({
  type,
  value,
  placeholder,
  handleOnChange,
  handleOnKeyPress,
  onBlur,
  onFocus,
  htmlForId,
  error,
  onKeyDown,
  size,
}) => {
  useEffect(() => {
    if (document.getElementById(`${htmlForId}`).type === "password") {
      document
        .getElementById(`hideicon__${htmlForId}`)
        .setAttribute("style", "display:block");
      document
        .getElementById(`showicon__${htmlForId}`)
        .setAttribute("style", "display:none");
    }
  }, []);

  const clicked = useCallback((event) => {
    if (document.getElementById(`${htmlForId}`).type === "password") {
      document.getElementById(`${htmlForId}`).setAttribute("type", "text");
      document
        .getElementById(`hideicon__${htmlForId}`)
        .setAttribute("style", "display:none");
      document
        .getElementById(`showicon__${htmlForId}`)
        .setAttribute("style", "display:block");
    } else {
      document.getElementById(`${htmlForId}`).setAttribute("type", "password");
      document
        .getElementById(`hideicon__${htmlForId}`)
        .setAttribute("style", "display:block");
      document
        .getElementById(`showicon__${htmlForId}`)
        .setAttribute("style", "display:none");
    }
    document.getElementById(`${htmlForId}`).focus();
  }, []);

  return (
    <>
      <div className="input__container">
        <div className="input__field">
          <input
            value={value}
            placeholder={placeholder}
            onChange={handleOnChange}
            onKeyPress={handleOnKeyPress}
            onBlur={onBlur}
            onFocus={onFocus}
            type={type}
            id={htmlForId}
            name={htmlForId}
            onKeyDown={onKeyDown}
            size={size}
          />
          {type === "password" ? (
            <>
              <svg
                id={`hideicon__${htmlForId}`}
                onClick={clicked}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.87264 17.129C5.02764 15.819 3.56764 14.115 2.74264 13.039C2.51195 12.742 2.38672 12.3766 2.38672 12.0005C2.38672 11.6244 2.51195 11.259 2.74264 10.962C4.23564 9.013 7.81765 5 11.9996 5C13.8756 5 15.6296 5.807 17.1296 6.874"
                  stroke="#747577"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 18.704C10.6492 18.8972 11.3227 18.9969 12 19C16.182 19 19.764 14.987 21.257 13.038C21.4876 12.7407 21.6127 12.3751 21.6125 11.9988C21.6124 11.6226 21.4869 11.2571 21.256 10.96C20.7313 10.2755 20.1684 9.62112 19.57 9M14.13 9.887C13.8523 9.60467 13.5214 9.38011 13.1565 9.22629C12.7916 9.07246 12.3998 8.99241 12.0038 8.99075C11.6078 8.98909 11.2154 9.06586 10.8491 9.21662C10.4829 9.36738 10.1502 9.58916 9.87016 9.86915C9.5901 10.1492 9.36824 10.4818 9.21739 10.848C9.06654 11.2142 8.98969 11.6066 8.99125 12.0026C8.99282 12.3986 9.07278 12.7904 9.22652 13.1554C9.38026 13.5203 9.60473 13.8512 9.887 14.129L14.13 9.887ZM4 20L20 4L4 20Z"
                  stroke="#747577"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                id={`showicon__${htmlForId}`}
                onClick={clicked}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.2565 10.962C21.7305 11.582 21.7305 12.419 21.2565 13.038C19.7635 14.987 16.1815 19 11.9995 19C7.81752 19 4.23552 14.987 2.74252 13.038C2.51191 12.7411 2.38672 12.3759 2.38672 12C2.38672 11.6241 2.51191 11.2589 2.74252 10.962C4.23552 9.013 7.81752 5 11.9995 5C16.1815 5 19.7635 9.013 21.2565 10.962V10.962Z"
                  stroke="#75777C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                  stroke="#75777C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          ) : (
            ""
          )}
        </div>
        {error && <span>{error}</span>}
      </div>
    </>
  );
};

export default Input;
