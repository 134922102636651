import React from "react";
import "./index.scss";
import { Button } from "../../components/common/Button/Button";
import Input from "../../components/common/Input";
import Modal from "../../components/common/Modal/Modal";

const Setpassword = ({
  handleFPInputChange,
  SPpassword,
  SPcpassword,
  error,
  handleClose,
  handleClick,
  errorSPMsg,
}) => {
  return (
    <>
      <Modal
        type={"medium"}
        title={""}
        titlePosition={"center"}
        isError={false}
        handleClose={handleClose}
        displayCloseIcon={false}
      >
        <div className="setpwd__Form">
          <div className="setpwd__Form--title">
            <p>Set your Password</p>
          </div>
          <span className="Form--error">{errorSPMsg}</span>
          <div className="setpwd__Form--inputs">
            <Input
              htmlForId="SPpassword"
              type="password"
              placeholder="Enter new password"
              value={SPpassword}
              handleOnChange={handleFPInputChange}
              error={error.SPpassword}
              onBlur={handleFPInputChange}
            />
            <Input
              htmlForId="SPcpassword"
              type="password"
              placeholder="Confirm Password"
              value={SPcpassword}
              handleOnChange={handleFPInputChange}
              error={error.SPcpassword}
              onBlur={handleFPInputChange}
            />
          </div>
          <div className="setpwd__Form--btn">
            <Button
              title="Submit"
              handleClick={handleClick}
              styleClasses="btn btn-primary"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Setpassword;
