import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../assets/right-arrow-long.svg";
import LengendContainer from "./LengendContainer";
import { useSelector } from "react-redux";

import { colorMap, getTickIntervals, graphType } from "../utils/constants";
import HC_Regression from "highcharts-regression";
import NoDataFound from "./NoDataFound";

// usage of highcharts-regression
HC_Regression(Highcharts);

const AcEfficiency = ({ data, filterType }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const [odometerTick, setOdometerTick] = useState([]);

  const [chartData, setChartData] = useState(null);
  const [acEfficiencyArray, setAcEfficiencyArray] = useState(null);
  const [chartInfo, setChartInfo] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setChartData(data?.acEfficiencyPercentage);
    if (data && data?.acEfficiencyPercentage.length > 0) {
      let acEfficiencyArray = [];
      switch (filterType) {
        case graphType.DATE: {
          acEfficiencyArray = data.acEfficiencyPercentage.map((item) => [
            moment(item.statsDate).format("DD"),
            item.acEfficiencyPercentage,
          ]);
          break;
        }
        case graphType.MILES: {
          acEfficiencyArray = data.acEfficiencyPercentage.map((item) => [
            item.odometer,
            item.acEfficiencyPercentage,
          ]);
          if (acEfficiencyArray.length > 1) {
            const tickArray = data.acEfficiencyPercentage
              .map((item) => item.odometer)
              .sort((a, b) => a - b);
            const tArray = getTickIntervals(tickArray);
            setOdometerTick(tArray);
          }
          break;
        }
        case graphType.TRIP: {
          acEfficiencyArray = data.acEfficiencyPercentage.map((item) => [
            item.trip,
            item.acEfficiencyPercentage,
          ]);
          break;
        }

        default: {
          acEfficiencyArray = data.acEfficiencyPercentage.map((item) => [
            moment(item.statsDate).format("DD"),
            item.acEfficiencyPercentage,
          ]);
        }
      }
      setChartInfo(data?.information);
      setAcEfficiencyArray(acEfficiencyArray);
      const month = moment(data?.acEfficiencyPercentage[0].statsDate).format(
        "MMMM"
      );
      const year = moment(data?.acEfficiencyPercentage[0].statsDate).format(
        "YYYY"
      );
      setMonth(month);
      setYear(year);
    } else {
      setAcEfficiencyArray(null);
    }
  }, [data]);

  const options = {
    chart: {
      backgroundColor: "#F1F3F5",
      spacing: [50, 30, 30, 20],
      height: 350,
      type: "scatter",
      events: {
        load: function () {
          this.series[1]?.update({
            enableMouseTracking: false,
          });
        },
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-100px;top:4px;position:absolute;">
        Ac Efficiency
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      min: 0,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
      gridLineColor: "#ffffff",
    },
    xAxis: {
      type: "category",
      tickPositions: filterType === graphType.MILES ? odometerTick : null,
      title: {
        useHTML: true,
        text: `
          <div style="font-size: 11px; left:-100px;top:4px;position:absolute;">
           ${
             filterType === graphType.DATE
               ? `${month},${year}`
               : filterType === graphType.MILES
               ? `By Miles`
               : filterType === graphType.TRIP
               ? `By Trips`
               : `${month},${year}`
           }
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
        marker: {
          radius: 3,
        },
      },
      line: {
        dashStyle: "Dash",
      },
    },
    tooltip: {
      style: {
        color: "#353536",
        font: "12px Open Sans",
      },
    },
    series: [
      {
        data: acEfficiencyArray,
        showInLegend: false,
        name: "Ac Efficiency",
        color: chartInfo && colorMap[chartInfo[0]?.color],
        regression: acEfficiencyArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[0]?.color],
          // legend: "%eq",
          // name: "%eq | r2: %r",
          dashStyle: "dot",
        },
      },
    ],
  };
  return (
    <div key={acEfficiencyArray}>
      <div key={isSidemenuExpanded}>
        {Array.isArray(acEfficiencyArray) && acEfficiencyArray.length > 0 ? (
          <>
            <LengendContainer chartInfo={chartInfo} />
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              updateArgs={[true, true, true]}
            />
            <div
              style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}
            >
              <p style={{ fontSize: "12px", fontWeight: "600" }}>
                Ac Efficiency
              </p>
            </div>
          </>
        ) : (
          <>
            <NoDataFound chartName=" Ac Efficiency" />
          </>
        )}
      </div>
    </div>
  );
};

export default AcEfficiency;
