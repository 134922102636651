import './index.scss';

const Details = () => {
  return (
    <div className="full-width details">
      Coming Soon...
    </div>
  )
}

export default Details;