import React from "react";
import "./index.scss";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const Analyticgraph = ({ title, yTitle, xTitle, chartdata }) => {
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "line",
      backgroundColor: "#C9CED740",
      width: 500,
      height: 350,
      events: {
        load: function () {
          let ren = this.renderer;
          // let doubleArrow = [
          //   "M0.976562",
          //   "3L5.97656",
          //   "5.88675V0.113249L0.976562",
          //   "3ZM92.026",
          //   "3L87.026",
          //   "0.113249V5.88675L92.026",
          //   "3ZM5.47656",
          //   "3.5L87.526",
          //   "3.5V2.5L5.47656",
          //   "2.5V3.5Z",
          // ];
          // ren
          //   .path(doubleArrow)
          //   .attr({
          //     "stroke-width": 2,
          //     stroke: "#75777C",
          //     pathLength: 1,
          //   })
          //   .translate(distX.width, 220)
          //   .add();
          let distX = this.xAxis[0];
          let distY = this.yAxis[0];
          let distplotX = this.plotSizeX;
          let distplotY = this.plotSizeY;
          let image = `data:image/svg+xml,%3Csvg width='168' height='6' viewBox='0 0 93 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.976562 3L5.97656 5.88675V0.113249L0.976562 3ZM92.026 3L87.026 0.113249V5.88675L92.026 3ZM5.47656 3.5L87.526 3.5V2.5L5.47656 2.5V3.5Z' fill='%2375777C'/%3E%3C/svg%3E`;
          let yimage = `data:image/svg+xml,%3Csvg width='8' height='93' viewBox='0 0 8 93' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.35355 0.646446C4.15829 0.451187 3.84171 0.451187 3.64645 0.646446L0.464466 3.82843C0.269204 4.02369 0.269204 4.34027 0.464466 4.53554C0.659728 4.7308 0.976311 4.7308 1.17157 4.53554L4 1.70711L6.82843 4.53554C7.02369 4.7308 7.34027 4.7308 7.53553 4.53554C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646446ZM4.5 93L4.5 1H3.5L3.5 93H4.5Z' fill='%23353536'/%3E%3C/svg%3E`;
          let ximage = `data:image/svg+xml,%3Csvg width='30' height='8' viewBox='0 0 30 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29.3536 4.35355C29.5488 4.15829 29.5488 3.84171 29.3536 3.64645L26.1716 0.464466C25.9763 0.269204 25.6597 0.269204 25.4645 0.464466C25.2692 0.659728 25.2692 0.976311 25.4645 1.17157L28.2929 4L25.4645 6.82843C25.2692 7.02369 25.2692 7.34027 25.4645 7.53553C25.6597 7.7308 25.9763 7.7308 26.1716 7.53553L29.3536 4.35355ZM0 4.5L29 4.5V3.5L0 3.5L0 4.5Z' fill='%23353536'/%3E%3C/svg%3E`;

          ren
            .image(yimage, distY.pos - distY.labelOffset, distY.top, "1", "1")
            .add();
          ren
            .image(
              ximage,
              distX.width + distX.top - distX.right,
              distX.height + distX.bottom + distX.axisTitleMargin,
              "1",
              "1"
            )
            .add();

          ren.image(image, distplotX - 100, distplotY + 15, "1", "1").add();
          ren
            .label(`RUL: ${chartdata?.units}`, distplotX - 50, distplotY)
            .css({
              color: "#000000",
              fontSize: "10px",
            })
            .add();
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    colors: ["#56A64B"],
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: title,
    },
    yAxis: {
      title: {
        text: yTitle,
        textOverflow: "ellipsis",
        align: "high",
        style: {
          fontSize: "12px",
          fontWeight: "100",
        },
      },
      gridLineColor: "#FFFFFF",
      plotLines: [
        {
          color: "#FF0000",
          value: chartdata?.seriesdata[chartdata?.seriesdata.length - 1],
          width: 1,
          dashStyle: "dash",
          label: {
            text: `Failure Threshold : ${chartdata?.seriesdata[chartdata?.seriesdata.length - 1]
              } milli seconds`,
            textAlign: "left",
            style: {
              fontWeight: "200",
            },
          },
        },
      ],
    },
    xAxis: {
      // tickPositions: chartdata?.categories,
      title: {
        text: xTitle,
        textOverflow: "ellipsis",
        align: "high",
        style: {
          fontSize: "12px",
          fontWeight: "100",
        },
      },
      // categories: chartdata?.categories,
      plotLines: [
        {
          color: "#FF0000",
          // value: chartdata?.seriesdata.length - 2,
          value: chartdata?.data[chartdata?.data.length - 2][0],
          dashStyle: "dash",
          label: {
            text: "Current ERT",
            verticalAlign: "middle",
            textAlign: "right",
            y: 20,
            rotation: 270,
            x: -5,
            style: {
              fontWeight: "200",
            },
          },
        },
        {
          color: "#FF0000",
          // value: chartdata?.seriesdata.length - 1,
          value: chartdata?.data[chartdata?.data.length - 1][0],
          dashStyle: "solid",
        },
      ],
    },
    series: [
      {
        // data: chartdata?.seriesdata,
        data: chartdata?.data,
        zoneAxis: "x",
        zones: [
          {
            // value: chartdata?.seriesdata.length - 2,
            value: chartdata?.data[chartdata?.data.length - 2][0],
          },
          {
            dashStyle: "dash",
          },
        ],
      },
    ],
  };
 
  return (
    <>
      <div className="div__highchart">
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"chart"}
          options={options}
        />
      </div>
    </>
  );
};

export default Analyticgraph;
