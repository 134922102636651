import { ROOT_CAUSE_ANALYSIS_ACTIONS } from "./action_types";

const INITIAL_STATE = {
  rootCauseAnalysisPayload: null,
};

const rootCauseReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ROOT_CAUSE_ANALYSIS_ACTIONS.ROOT_CAUSE_PAYLOAD:
      return {
        ...state,
        rootCauseAnalysisPayload: payload,
      };
    default:
      return state;
  }
};

export default rootCauseReducer;
