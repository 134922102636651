import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer text-align-center">
      Copyrights&nbsp;
      <span className="icon-copyright-1">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
      &nbsp;Autonostix 2022
    </div>
  );
};

export default Footer;
