import React, { useEffect, useState } from "react";
import { maintenanceLogEndPoints } from "../../../../services/service-constants";
import { getMaintenanceScoreTableData } from "../../api";
import Pagination from "../../../../components/common/Pagination";
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';

const Quality = () => {
    const [initialTableData, setInitialTableData] = useState(null);

    const [currentPage, setCurrentPage] = useState();

    const [isPageFirst, setIsPageFirst] = useState(false);

    const [isPageLast, setIsPageLast] = useState(false);

    const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

    
    const [pagenation, setPagenation] = useState({
        totalpages: 0,
        size: 0,
        currentPageNo: 0,
      });

      const onPageChanged = (val) => {
        const nextPage = val;
        getInitialData({
            page: nextPage,
        });
      };

      const getInitialData = async (payload) => {
        const { page ,vinNumber} = payload;
        const data = await getMaintenanceScoreTableData({
          page: page,
          url: `${maintenanceLogEndPoints.quality}`,
          vinNumber:vinNumber
        });
        if (data) {
          setInitialTableData(data?.content);
          setCurrentPage(data?.pageable?.pageNumber);
          setIsPageFirst(data?.first);
          setIsPageLast(data?.last);
          setPagenation({
            totalpages: data?.totalPages,
            size: data?.size,
            currentPageNo: data?.pageable?.pageNumber,
          });
        }
      };

    useEffect(() => {
        getInitialData({
            page: 0,
            vinNumber:vin_Number,

          });
    }, []);

    return(
        <>
            <table className="quality-table">
            <thead>
                <tr>
                    <th>Component Replaced</th>
                    <th>Vehicle Compatible Components</th>
                    <th>Actual Replaced Components</th>
                    <th>Violation</th>
                    <th>Weighing factor</th>
                </tr>
            </thead>
            <tbody>
                {initialTableData?.length &&
                initialTableData.map((item) => (
                    <tr key={item?.id}>
                        <td>{item?.componentReplaced || '-'}</td>
                        <td>{item?.vehicleCompatibleComponents || '-'}</td>
                        <td>{item?.actualReplacedComponents || '-'}</td>
                        <td>{item?.violation || '-'}</td>
                        <td>{item?.weighingFactor || '0.0'}</td>
                    </tr>
                ))}
            </tbody>
            </table>
            {pagenation.totalpages > 1 && (
                <div className="pagination-container">
                  <Pagination
                    pageCount={pagenation.totalpages}
                    pageRange={pagenation.size}
                    onPageChanged={onPageChanged}
                  />
                </div>
              )}
        </>
    )
}

export default Quality;