import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import "./index.scss";

const ComponentSystemHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const savedState = JSON.parse(localStorage.getItem("componentSystemHistoryState") || '');
  let stateObj;
  if ( savedState ) {
    stateObj = {subSystem: savedState.subSystem, date: savedState.date};
  }
  useEffect(() => {
    if (stateObj && stateObj !== {}){
        if (location.pathname.includes("/component-system-history") && !location.pathname.includes("/component-system-history/")) {
            navigate("./diagnostics", { replace: true, state: stateObj });
        }
    }
  }, [location.pathname]);

  return (
    <div className="full-width component-system-history">
      <div className="tabs-container">
        <NavLink
          to="./diagnostics"
          state={stateObj}
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Diagnostics
        </NavLink>
        <NavLink
          to="./prognostics"
          state={stateObj}
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Prognostics
        </NavLink>
        <NavLink
          to="./maintenance-history"
          state={stateObj}
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Maintenance History
        </NavLink>
        <NavLink
          to="./maintenance-profile"
          state={stateObj}
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Maintenance Profile
        </NavLink>
        <NavLink
          to="./usage-profile"
          state={stateObj}
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Usage Profile
        </NavLink>
        <NavLink
          to="./service-conditions"
          state={stateObj}
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Service Conditions
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default ComponentSystemHistory;
