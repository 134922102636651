import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";

const VehicleOverview = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/vehicle-overview") {
      navigate("/vehicle-overview/information", { replace: true });
    }
  }, [location.pathname]);

  return (
    <div className="full-width ">
      <div className="tabs-container">
        <NavLink
          to="/vehicle-overview/information"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Vehicle Information
        </NavLink>
        <NavLink
          to="/vehicle-overview/notification"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Vehicle Notification
        </NavLink>
        <NavLink
          to="/vehicle-overview/realtime-data"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Vehicle Real-Time Data
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default VehicleOverview;
