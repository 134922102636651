import React from "react";
import { colorMap } from "../utils/constants";

const LengendContainer = ({ chartInfo }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "0px",
      }}
    >
      <div
        className="legend_inner"
        style={{
          display: "inline-flex",
          justifyContent: "flex-end",

        }}
      >
        {chartInfo &&
          chartInfo.map((info) => (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  marginRight: "10px",
                }}
              >
                <div
                  style={{
                    height: "12px",
                    width: "12px",
                    backgroundColor: colorMap[info?.color],
                  }}
                ></div>
                <p style={{ fontSize: "10px", color: "#353536" }}>
                  {info?.label}
                </p>
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default LengendContainer;
