import React, { useState, useEffect } from "react";
import { Checkbox } from "../../../components/common/Checkbox/Checkbox";
import { mainAxiosInstance } from "../../../services/service";
import { ServiceConfig } from "../../../services/service-constants";
import ModCross from "../../../assets/mod_cross.svg";
import ModTick from "../../../assets/mod_tick.svg";
import RightIcon from "../../../assets/right-icon.svg";
import { useDispatch, useSelector } from "react-redux";

import "./routineMaintenance.scss";

const BarFillMeaserMent = ({ fillValue, status }) => {
  const fillHeight = parseInt((fillValue * 18) / 100) + "px";
  const bgColor = status ? "#56a64b" : "#f23732";
  return (
    <div>
      <div className="measure-val">{fillValue}%</div>
      <div className="vertical-filler-wrap">
        <div style={{ height: fillHeight, backgroundColor: bgColor }}></div>
      </div>
    </div>
  );
};

const SliderFiller = ({ fillValue }) => (
  <div className="slider-wrapper">
    <div className="slider-wrap">
      <div className="slider-value-rep"></div>
      <div
        className="slider-pointer"
        style={{ left: parseInt((fillValue * 84) / 100) }}
      ></div>
    </div>
    <div className="slider-val">{fillValue}</div>
  </div>
);

const RoutineRow = ({ data }) => {
  const [isActive, setIsActive] = useState(false);
  const [isSecActive, setIsSecActive] = useState(false);
  const { status, upperLimit, attributeValue, attributeName } = data;
  const percentFill = upperLimit
    ? parseInt((attributeValue * 100) / upperLimit)
    : attributeValue;
  return (
    <li
      className={status ? "routine-row" : "routine-row routine-row-critical"}
      key={data?.id}
    >
      <div>{attributeName}</div>

      <div
        role="button"
        onClick={() =>
          setIsActive((val) => {
            if (val) setIsSecActive(false);
            return !val;
          })
        }
      >
        {status ? (
          <>
            <img src={ModTick} alt="Desired" />

            {!isSecActive ? (
              <div
                role="button"
                className="routine-maintenance-right-arrow"
                onClick={(evt) => {
                  evt.stopPropagation();
                  setIsSecActive((val) => !val);
                }}
              >
                <img src={RightIcon} alt="show details"></img>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <img src={ModCross} alt="Critical" />
          </>
        )}
      </div>
      <div>
        {status ? (
          isSecActive ? (
            !data?.upperLimit ? (
              <BarFillMeaserMent
                fillValue={data?.attributeValue}
                status={status}
              />
            ) : (
              <SliderFiller fillValue={percentFill} />
            )
          ) : (
            ""
          )
        ) : !data?.upperLimit ? (
          <BarFillMeaserMent fillValue={data?.attributeValue} status={status} />
        ) : (
          <SliderFiller fillValue={percentFill} />
        )}
      </div>
      <div>
        <div className="row-message">
          {status ? (isSecActive ? data?.message : "") : data?.message}
        </div>
        <div className="end-arrow">
          {status && isSecActive ? (
            <div
              role="button"
              className="routine-maintenance-right-arrow"
              onClick={(evt) => {
                evt.stopPropagation();
                setIsSecActive((val) => !val);
              }}
            >
              <img src={RightIcon} alt="hide details"></img>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </li>
  );
};

const RoutineMaintenance = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [criticalModData, setCriticalModData] = useState([]);

  const [maintenanceData, setMaintenanceData] = useState([]);
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  const getOnDemandData = async (val) => {
    const url = `${ServiceConfig.mod_routine_maintenance}`;
    const params = {
      vin_number: val.vin_Number,
      organization_id: 1,
    };
    try {
      const modData = await mainAxiosInstance.get(url, { params: params });
      if (modData?.status === 200 || modData?.status === 301) {
        let criticalModData = [],
          desiredModData = [];
        const { data } = modData;
        if (data.length) {
          criticalModData = data.filter((i) => !i.status);
          desiredModData = data.filter((i) => i.status);
        }
        setCriticalModData(criticalModData);
        setMaintenanceData(desiredModData);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getOnDemandData({
      vin_Number: vin_Number,
    });
  }, []);

  const showAllParams = (val) => {
    setIsChecked((val) => !val);
  };

  return (
    <div className="maintenance-log-container page-wrapper">
      <div className=" page-filter maintainance-on-demand-filter">
        <Checkbox
          title="Show all parameters"
          handleInputClick={showAllParams}
          alignProp={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 0px 10px 20px",
            alignItems: "center",
            flexDirection: "row-reverse",
          }}
          checkboxStyle={{ marginRight: "10px" }}
          isChecked={isChecked}
        />
      </div>
      <div className="routine-maintainance-content">
        <ul>
          <li className="routine-row routine-row-hdr">
            <div>Service Item</div>
            <div>Status</div>
            <div>Measurement</div>
            <div>Service Action</div>
          </li>
          {criticalModData?.length ? (
            <>
              {criticalModData.map((item) => (
                <RoutineRow data={item} key={item.id} />
              ))}
            </>
          ) : (
            ""
          )}
          {maintenanceData?.length && isChecked ? (
            <>
              {maintenanceData.map((item) => (
                <RoutineRow data={item} key={item.id} />
              ))}
            </>
          ) : (
            ""
          )}
        </ul>
      </div>
    </div>
  );
};

export default RoutineMaintenance;
