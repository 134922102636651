import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getFuelEconomyData } from "./api";
import AverageEngineLoadChart from "./components/AverageEngineLoadChart";
import FuelEconomyHeader from "./components/FuelEconomyHeader";
import MpgForCityAndHighwayChart from "./components/MpgForCityAndHighwayChart";
import { chartType, filterOptions, graphType } from "./utils/constants";
import "./index.scss";
import HoursSpentRpm from "./components/HoursSpentRpm";
import MpgAndRpm from "./components/MpgAndRpm";
import TotalEngineRuntime from "./components/TotalEngineRuntime";
import AcEfficiency from "./components/AcEfficiency";
import AlternateEfficiency from "./components/AlternateEfficiency";
import HighPressureFuelPump from "./components/HighPressureFuelPump";
import TirePressure from "./components/TirePressure";
import FuelConsumption from "./components/FuelConsumption";
import TimeVehicleInMotion from "./components/TimeVehicleInMotion";
import { ReactComponent as CloseIcon } from "../../../assets/close.svg";
import { ReactComponent as LeftIcon } from "../../../assets/left-arrow-square.svg";
import { ReactComponent as RightIcon } from "../../../assets/right-arrow-square.svg";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import useEffectExcludingMount from "../../../hooks/useEffectExcludingMount";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import LottieLoad from "../../../components/common/ErrorComponents/LottieLoad";
import ChartLoader from "../../../components/common/ErrorComponents/ChartLoader";

const FuelEconomy = () => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);

  const interval = ["Lifetime", "Last Trip", "Last Miles", "Date"];
  const [defaultInterval, setDefaultInterval] = useState("Lifetime");
  const [showInput, setShowInput] = useState(false);
  const [inputData, setInputData] = useState(1000);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [type, setType] = useState(null);
  const [selectedStartDate, setStartDate] = useState(new Date());
  const [selectedEndDate, setEndDate] = useState(new Date());
  const [filterData, setFilterData] = useState([...filterOptions]);
  const [noDataFound, setNoDataFound] = useState(true);
  const [allChartData, setAllChartData] = useState(null);
  const [charts, setCharts] = useState(null);
  const [isZoom, setIsZoom] = useState(false);
  const [zoomChart, setZoomedChart] = useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);

  const getFuelData = async () => {
    const { code, data } = await getFuelEconomyData({
      type: graphType.DATE,
      vinNumber: vinNumber,
      organizationId: 1,
      fromDate: "",
      toDate: "",
      lastMiles: 0,
      lastTrips: 0,
    });
    if (code === 200 && data) {
      setAllChartData(data);
    } else if (code === 401) {
      setNotAuthorized(true);
    } else {
      setApiError(true);
    }
  };

  const getFuelDataOnChange = async () => {
    const { code, data } = await getFuelEconomyData({
      type: type,
      vinNumber: vinNumber,
      organizationId: 1,
      fromDate:
        selectedStartDate === null
          ? ""
          : moment(selectedStartDate).format("yyyy-MM-DD"),
      toDate:
        selectedEndDate === null
          ? ""
          : moment(selectedEndDate).format("yyyy-MM-DD"),
      lastMiles: type === graphType.MILES ? parseInt(inputData) : 0,
      lastTrips: type === graphType.TRIP ? parseInt(inputData) : 0,
    });
    if (code === 200 && data) {
      setAllChartData(data);
      setNotAuthorized(false);
      setApiError(false);
    } else if (code === 401) {
      setNotAuthorized(true);
    } else if (code) {
      setApiError(true);
    } else {
      setAllChartData(null);
      setIsZoom(false);
    }
  };

  useEffect(() => {
    getFuelData();
  }, []);

  /**
   *
   * We want to make the onChange api call only on input change and not in initial render
   */

  useEffectExcludingMount(() => {
    getFuelDataOnChange();
  }, [selectedStartDate, selectedEndDate, type, inputData]);

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Lifetime":
        setShowInput(false);
        setShowDatepicker(false);
        setType("by_date");
        setInputData(0);
        setStartDate(null);
        setEndDate(null);
        break;
      case "Last Trip":
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1);
        setType("by_trip");
        break;
      case "Last Miles":
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1000);
        setType("by_miles");
        break;
      case "Date":
        setShowInput(false);
        setShowDatepicker(true);
        setType("by_date");
        break;
      default:
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1000);
        setType("by_date");
        break;
    }
  };

  const handleOnDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const chartsArray = [
    {
      id: 1,
      component: (
        <MpgForCityAndHighwayChart
          data={allChartData?.mpgForCityAndHighway}
          filterType={type}
        />
      ),
      type: chartType.FUEL_ECONOMY,
    },
    {
      id: 2,
      component: (
        <AverageEngineLoadChart
          data={allChartData?.engineLoadPercentage}
          filterType={type}
        />
      ),
      type: chartType.OPERATION_AND_USAGE,
    },
    {
      id: 3,
      component: (
        <HoursSpentRpm
          data={allChartData?.hoursSpentOnRpmBand}
          filterType={type}
        />
      ),
      type: chartType.FUEL_ECONOMY,
    },
    {
      id: 4,
      component: <MpgAndRpm data={allChartData?.mpgVsRpm} filterType={type} />,
      type: chartType.FUEL_ECONOMY,
    },
    {
      id: 5,
      component: (
        <TotalEngineRuntime
          data={allChartData?.timeAccessoriesInUse}
          filterType={type}
        />
      ),
      type: chartType.OPERATION_AND_USAGE,
    },
    {
      id: 6,
      component: (
        <AcEfficiency
          data={allChartData?.acEfficiencyPercentage}
          filterType={type}
        />
      ),
      type: chartType.COMPONENT_EFFICIENCIES,
    },
    {
      id: 7,
      component: (
        <AlternateEfficiency
          data={allChartData?.alternatorEfficiencyPercentage}
          filterType={type}
        />
      ),
      type: chartType.COMPONENT_EFFICIENCIES,
    },
    {
      id: 8,
      component: (
        <HighPressureFuelPump
          data={allChartData?.highPressureFuelPumpEfficiencyPercentage}
          filterType={type}
        />
      ),
      type: chartType.COMPONENT_EFFICIENCIES,
    },
    {
      id: 9,
      component: (
        <TirePressure data={allChartData?.tirePressurePsi} filterType={type} />
      ),
      type: chartType.COMPONENT_EFFICIENCIES,
    },
    {
      id: 10,
      component: (
        <FuelConsumption
          data={allChartData?.fuelConsumptionPerHPForCityAndHighway}
          filterType={type}
        />
      ),
      type: chartType.FUEL_ECONOMY,
    },
    {
      id: 11,
      component: (
        <TimeVehicleInMotion
          data={allChartData?.timeVehicleInMotion}
          filterType={type}
        />
      ),
      type: chartType.OPERATION_AND_USAGE,
    },
  ];

  useEffect(() => {
    const trueFilter = filterData
      .filter((filter) => Boolean(filter.isChecked))
      .map((filter) => filter.id)
      .flat();

    const filterCharts = chartsArray.filter((chart) =>
      trueFilter.includes(chart.type)
    );
    setCharts(filterCharts);
  }, [filterData]);

  useEffect(() => {
    if (isZoom) {
      const updatedZoomChart = charts.find(
        (chart) => chart.id === zoomChart.id
      );
      setZoomedChart(updatedZoomChart);
    }
  }, [charts]);

  useEffect(() => {
    setCharts(chartsArray);
  }, [allChartData]);

  const onZoom = (chart) => {
    setIsZoom(true);
    setZoomedChart(chart);
  };

  const onClose = () => {
    setIsZoom(false);
  };

  const onNext = (chart) => {
    const id = chart.id;
    if (id === charts.length) {
      const newChart = charts.find((chart) => chart.id === 1);
      setZoomedChart(newChart);
    } else {
      const newChart = charts.find((chart) => chart.id === id + 1);
      setZoomedChart(newChart);
    }
  };

  const onPrev = (chart) => {
    const id = chart.id;
    if (id === 1) {
      const newChart = charts.find((chart) => chart.id === charts.length);
      setZoomedChart(newChart);
    } else {
      const newChart = charts.find((chart) => chart.id === id - 1);
      setZoomedChart(newChart);
    }
  };

  if (notAuthorized) {
    return <NotAuthorized />;
  }

  if (!allChartData) {
    return <ChartLoader />;
  }

  return (
    <>
      <div className="fuel_economy page-wrapper">
        <FuelEconomyHeader
          interval={interval}
          defaultInterval={defaultInterval}
          showInput={showInput}
          inputData={inputData}
          setInputData={(e) => setInputData(e.target.value)}
          showDatepicker={showDatepicker}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          handleOnDateChange={(dates) => handleOnDateChange(dates)}
          handleIntervalOptionClick={(val) => handleIntervalOptionClick(val)}
          setNoDataFound={(val) => setNoDataFound(val)}
          filterData={filterData}
          setFilterData={(val) => setFilterData(val)}
          isZoom={isZoom}
        />
        {apiError ? (
          <>
            <Feedback />
          </>
        ) : (
          <>
            {isZoom ? (
              <>
                <div className="zoomed_container">
                  {zoomChart && (
                    <>
                      <div
                        className="prev_icon"
                        onClick={() => onPrev(zoomChart)}
                        key={isSidemenuExpanded}
                      >
                        <LeftIcon style={{ width: "30px", height: "30px" }} />
                      </div>
                      <div className="zoom_inner">
                        <div
                          className="zoom_close"
                          onClick={onClose}
                          style={{
                            width: "23px",
                            height: "23px",
                            position: "absolute",
                          }}
                        >
                          <CloseIcon
                            style={{ width: "23px", height: "23px" }}
                          />
                        </div>
                        {zoomChart.component}
                      </div>
                      <div
                        className="next_icon"
                        onClick={() => onNext(zoomChart)}
                      >
                        <RightIcon
                          style={{
                            width: "30px",
                            height: "30px",
                            justifySelf: "right",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="chart_container">
                  {charts.map((chart) => (
                    <div onClick={() => onZoom(chart)}>{chart.component}</div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FuelEconomy;
