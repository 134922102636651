import React from "react";

const HeaderIconAndText = (props) => {
  const { iconUrl, iconAltText, iconText, handleClick } = props;

  return (
    <div
      className="icon-and-image-container flex flex-column justify-content-space-evenly"
      onClick={handleClick}
    >
      <div className="icon align-self-center">
        <img src={iconUrl} alt={iconAltText} />
      </div>
      <div className="text">{iconText}</div>
    </div>
  );
};

export default HeaderIconAndText;
