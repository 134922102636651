import { mainAxiosInstance } from "../../../../services/service";
import { serviceGuideEndPoints } from "../../../../services/service-constants";

export const getServiceGuideData = async (payload) => {
  const url = `${serviceGuideEndPoints.getServiceGuide}`;
  //   TODO : dynamic vin_number and org_id required
  const params = {
    vin_number: "DH34ASD7SDFF84742",
    organization_id: 1,
    dtc_code: "P0102",
    service_guide_type: payload?.serviceType,
  };
  return mainAxiosInstance
    .get(url, { params: params })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((err) => {
      // return err?.message || 'Something went wrong';
      Promise.reject();
    });
};
