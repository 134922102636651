/**
 *Function return list of array array elements of equal step between start and end
 *
 * @param {*} start => start value of array(inclusive)
 * @param {*} end =>end value of array (inclusive)
 * @param {*} step => diffrence between each array element
 * @returns => list of array array elements of equal step between start and end
 */

export const tickRange = (start, end, step = 10) => {
  const len = Math.floor((end - start) / step) + 1;
  return Array(len)
    .fill()
    .map((_, idx) => start + idx * step);
};
