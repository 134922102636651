import React, { useEffect, useState } from "react";
import { mainAxiosInstance } from "../../../services/service";
import { GetDriverBehaviourSummaryApi } from "../../../services/service-constants";
import TrafficImg from "../../../assets/traffic-img.svg";
import TravelImg from "../../../assets/travel-img.svg";
import DriveImg from "../../../assets/drive-img.svg";
import { useNavigate } from "react-router";

import "./index.scss";
import { Button } from "../../../components/common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
const GetDriverBehaviourSummary = () => {
  const navigate = useNavigate();
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const [driverBehaviourSummaryData, setDriverBehaviourSummaryData] = useState(
    []
  );
  const [noDataFound, setNoDataFound] = useState(true);

  const colorMap = {
    yellow: "#FDE6A1",
    green: "#CFFFD2",
    red: "#FDDBD4",
  };

  const getPageData = (val) => {
    let url = "";
    let params = {};
    url = `${GetDriverBehaviourSummaryApi}`;
    params = {
      vin_number: val.vin_Number,
      organization_id: 1,
    };

    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        setDriverBehaviourSummaryData(response?.data);
        setNoDataFound(false);
      })
      .catch((er) => {
        setNoDataFound(true);
        throw er;
      });
  };

  useEffect(() => {
    getPageData({
      vin_Number: vin_Number,
    });
  }, []);

  return (
    <div className="driver-behaviour-summary-wrapper">
      <div className="summary-driver-behaviour">
        Driver Behaviour score is {driverBehaviourSummaryData?.driverScore}%
        (Good)
      </div>
      <div className="flex page-content-header">
        <div className="page-options-container"></div>
        <div className="service-help-wrapper">
          <div className="service-help">
            Pictorial Representation
            <Button
              title="View"
              handleClick={() =>
                navigate(
                  "/operational-analytics/driver-behaviour/summary/pictorial-representation",
                  {
                    replace: true,
                  }
                )
              }
              styleClasses="btn btn-primary view-button"
            />
          </div>
        </div>
      </div>
      <div className="page-chart-container vehicle-usage-charts">
        {!noDataFound &&
          driverBehaviourSummaryData?.behaviourSummary?.map((el) => {
            const imgName =
              el.message.includes("drive") || el.message.includes("driving")
                ? DriveImg
                : el.message.toLowerCase().includes("sign")
                ? TrafficImg
                : TravelImg;
            return (
              <div key={el.id} className="driver-summary-wrapper">
                <div
                  className="driver-summary-card"
                  style={{ backgroundColor: colorMap[el.color] }}
                >
                  <img src={imgName} alt="img" />
                  <div>&nbsp;{el.paramValue}%</div>
                  <div className="driver-summary-tooltip">
                    <div>Elementary score: {el.elementaryScore}</div>
                    <div>Weight of element: {el.weightOfElement}</div>
                    <div>{el.message}</div>
                  </div>
                </div>
                <div className="driver-summary-text">{el.paramName}</div>
              </div>
            );
          })}

        {noDataFound && (
          <div className="no-data">
            Sorry, We could not find any data for the given search criteria.
          </div>
        )}
      </div>
    </div>
  );
};

export default GetDriverBehaviourSummary;
