import "./filter.scss";
import { useEffect, useRef, useState } from "react";
import filterIcon from "../../assets/filter.png";
import useCloseOnClickOutside from "../../hooks/useCloseOnClickOutside";
import { Checkbox } from "../common/Checkbox/Checkbox";

const Filter = ({ selectAll = false, data, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [allselect, setAllSelect] = useState(selectAll);

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  let filterRef = useCloseOnClickOutside(() => {
    setIsOpen(false);
  });

  const handleChange = () => {
    setAllSelect(!allselect);
    data(!allselect);
  };
  useEffect(() => {
    setAllSelect(selectAll);
  }, [selectAll]);

  return (
    <div ref={filterRef} className="page-filter-wrapper">
      <div className="text-align-right filter-container" onClick={toggleFilter}>
        <span>Filter</span>
        <img className="filter-icon" src={filterIcon} alt="filter" />
      </div>
      <div className="filter-options-container">
        {isOpen && (
          <div className="filter-wrapper">
            <Checkbox
              title="SelectAll"
              handleInputClick={handleChange}
              alignProp={{
                borderTop: "0.5px solid #C4C4C4",
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
                alignItems: "center",
                flexDirection: "row-reverse",
                paddingLeft: "170px",
              }}
              checkboxStyle={{ marginRight: "10px" }}
              isChecked={selectAll}
            />

            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default Filter;
