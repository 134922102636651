import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

const Prognostics = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("top-10-dtc-complaints", {
      replace: true,
    });
  }, []);
  return (
    <div className="full-width diagnostics-container">
      <div className="sec-tabs-container">
        <NavLink
          to="/warranty-management/prognostics/top-10-dtc-complaints"
          className={({ isActive }) =>
            isActive ? "btn-primary sec-tabs" : "btn-secondary sec-tabs"
          }
        >
          Top 10 DTCs Complaints
        </NavLink>
        <NavLink
          to="/warranty-management/prognostics/top-10-non-dtc-complaints"
          className={({ isActive }) =>
            isActive ? "btn-primary sec-tabs" : "btn-secondary sec-tabs"
          }
        >
          Top 10 non-DTC Complaints
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default Prognostics;
