import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import routesWithBreadcrumbs from "../../../utils/constants";
import "./Breadcrumbs.scss";
import { linkChangeObjects } from "./helper/utils";

const Breadcrumbs = ({ routes }) => {
  const breadcrumbs = useReactRouterBreadcrumbs(routesWithBreadcrumbs);
  /**
   * Hack to change routes for some breadcrumb.
   */
  linkChangeObjects.forEach((link) => {
    breadcrumbs.forEach((bread) => {
      if (bread?.match?.pathname === link.from) {
        bread.match.pathname = link.to;
      }
    });
  });

  return (
    <>
      <div className="breadcrumb-container">
        {breadcrumbs?.map((breadcrumb, index) => (
          <span key={index}>
            {index === 0 ? <Link to="/"></Link> : " > "}
            <Link to={breadcrumb.match.pathname}>{breadcrumb.breadcrumb}</Link>
          </span>
        ))}
      </div>
    </>
  );
};

export default Breadcrumbs;
