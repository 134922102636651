import React, { forwardRef, useEffect, useState } from "react";
import { ComponentSubSystemEndpoints } from "../../../../services/service-constants";
import { mainAxiosInstance } from "../../../../services/service";
import "./index.scss";
import { SingleLine } from "../../../../components/common/Multigraph/graph";
import { DualLine } from "../../../../components/common/Multigraph/graph";
import { DotLine } from "../../../../components/common/Multigraph/graph";
import { Line } from "../../../../components/common/Multigraph/graph";
import { SingleColumn } from "../../../../components/common/Multigraph/graph";
import navLeft from "../../../../assets/left-arrow-square.svg";
import navRight from "../../../../assets/right-arrow-square.svg";
import close from "../../../../assets/close.svg";
import { useDispatch, useSelector } from "react-redux";

const ComponentSubSystem = () => {
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const [noDataFound, setNoDataFound] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [chartDetails, setChartDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedChartData, setSelectedChartData] = useState(null);
  const filterOptions = [
    {
      displayname: "stickiness",
      id: "stickinessAggregatorDto",
      isChecked: true,
      type: "dualLine",
      title: "Stickiness",
    },
    {
      displayname: "endStopPositionWear",
      id: "endStopPositionWearAggregator",
      isChecked: true,
      type: "dotLine",
      title: "End stop Position Wear",
    },
    {
      displayname: "range",
      id: "rangeAggregator",
      isChecked: true,
      type: "singleLine",
      title: "Range",
    },
    {
      displayname: "seatSootDeposit",
      id: "seatSootDepositAggregator",
      isChecked: true,
      type: "line",
      title: "SEAT SOOT Deposit",
    },
    {
      displayname: "stoppingVelocity5PercentBeforeEndStop",
      id: "stoppingVelocity5PercentBeforeEndStopAggregator",
      isChecked: true,
      type: "singleColumn",
      title: "Stopping Velocity 5% Before End Stop",
    },
  ];
  const [filterData, setFilterData] = useState([...filterOptions]);

  const showPrev = (el) => {
    const data = filterData.filter((el) => el.isChecked);
    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== 0) {
        selectElement = data[index - 1];
      } else if (index === 0) {
        selectElement = data[data.length - 1];
      }
      setNoDataFound(false);
      setSelectedChartData(selectElement);
    } else {
      setNoDataFound(true);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const showNext = (el) => {
    const data = filterData.filter((el) => el.isChecked);
    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== data.length - 1) {
        selectElement = data[index + 1];
      } else if (index === data.length - 1) {
        selectElement = data[0];
      }
      setNoDataFound(false);
      setSelectedChartData(selectElement);
    } else {
      setNoDataFound(true);
    }
  };

  const getDualLineData = (id, data) => {
    const graphData = [];
    const secondGraphData = [];
    data?.forEach((val) => {
      let obj = {
        x: val.time,
        y: val.miles0,
      };
      let obj2 = {
        x: val.time,
        y: val.miles20000,
      };
      graphData.push(obj);
      secondGraphData.push(obj2);
    });

    return [[...graphData], [...secondGraphData]];
  };

  const getDotLineData = (id, data) => {
    const graphData = [];
    const secondGraphData = [];
    data?.forEach((val) => {
      let obj = {
        x: val.miles1000,
        y: val.openEndMV,
      };
      let obj2 = {
        x: val.miles1000,
        y: val.closeEndMV,
      };
      graphData.push(obj);
      secondGraphData.push(obj2);
    });

    return [[...graphData], [...secondGraphData]];
  };

  const getSingleLineData = (id, data) => {
    const graphData = [];
    data?.forEach((val) => {
      let obj = {
        x: val.miles1000,
        y: val.angleDegrees,
      };

      graphData.push(obj);
    });

    return [[...graphData]];
  };

  const getlineData = (id, data) => {
    const graphData = [];
    const secondGraphData = [];
    const thirdGraphData = [];
    data?.forEach((val) => {
      let obj = {
        x: val.time,
        y: val.goodSignal,
      };
      let obj2 = {
        x: val.time,
        y: val.failureSignal,
      };
      let obj3 = {
        x: val.time,
        y: val.actls,
      };
      graphData.push(obj);
      secondGraphData.push(obj2);
      thirdGraphData.push(obj3);
    });

    return [[...graphData], [...secondGraphData], [...thirdGraphData]];
  };

  const getSingleColumnData = (id, data) => {
    const graphData = [];
    const secondGraphData = [];
    data?.forEach((val) => {
      let obj = {
        x: val.degreeMS,
        y: val.numberOfStrikes,
        color: val.color,
      };
      graphData.push(obj);
    });

    return [[...graphData]];
  };

  const prepareChartData = (dataSet) => {
    const mainData = [];
    let retData = [];
    let chartDet = [];
    filterData.forEach((el) => {
      const detailsData = dataSet[el.id].componentSubSystemInformation;

      chartDet.push({ name: el.id, value: detailsData });
      switch (el.type) {
        case "dualLine": {
          retData = getDualLineData(el.id, dataSet[el.id][el.displayname]);
          break;
        }
        case "dotLine": {
          retData = getDotLineData(el.id, dataSet[el.id][el.displayname]);
          break;
        }
        case "singleLine": {
          retData = getSingleLineData(el.id, dataSet[el.id][el.displayname]);
          break;
        }
        case "line": {
          retData = getlineData(el.id, dataSet[el.id][el.displayname]);
          break;
        }
        case "singleColumn": {
          retData = getSingleColumnData(el.id, dataSet[el.id][el.displayname]);
          break;
        }
      }

      const dataToStore = {
        name: el.id,
        values: [...retData],
      };
      const index = mainData.findIndex((element) => {
        if (element?.name === el.id) {
          return true;
        }
        return false;
      });

      if (index === -1) {
        mainData.push({ ...dataToStore });
      } else {
        mainData.map((ele) => {
          if (ele.name === el.id) {
            ele.values = [...retData];
          }
        });
      }
    });
    setChartData(mainData);
    setChartDetails(chartDet);
  };

  const getPageData = (val) => {
    const url = `${ComponentSubSystemEndpoints}`;
    let params = {
      vinNumber: val.vin_Number,
      organizationId: 1,
    };
    mainAxiosInstance
      .post(url, { ...params })
      .then((response) => {
        setNoDataFound(false);
        prepareChartData(response.data);
      })
      .catch((er) => {
        setNoDataFound(true);
        throw er;
      });
  };

  useEffect(() => {
    // setChartData([]);
    getPageData({
      vin_Number: vin_Number,
    });
  }, []);

  const clickOnClose = (e) => {
    e.stopPropagation();
    setShowModal(false);
  };

  const handleChartClick = (id) => {
    setShowModal(true);
    const selData = filterData.find((el) => el.id === id);
    setSelectedChartData(selData);
  };

  const getDataForCharts = (type, id) => {
    let data = {};
    const item = filterOptions.find((el) => el.id === id);
    const gData = chartData?.find((el) => el.name === id);
    const chartDetail = chartDetails?.find((el) => el.name === id);
    switch (type) {
      case "singleLine": {
        data = {
          id: id,
          chartdata: gData?.values,
          title: item.title,
          xLabel: "Miles('000)",
          yLabel: "Angle(degrees)",
          chartDetails: chartDetail?.value,
        };
        break;
      }
      case "dotLine": {
        data = {
          id: id,
          dataAxis1: gData?.values?.[0],
          dataAxis2: gData?.values?.[1],
          title: item.title,
          xLabel: "Age of system(Months in Service",
          yLabel: "AbsoluteEGRValue position(mV)",
          chartDetails: chartDetail?.value,
        };
        break;
      }
      case "dualLine": {
        data = {
          id: id,
          dataAxis1: gData?.values?.[0],
          dataAxis2: gData?.values?.[1],
          title: item.title,
          xLabel: "Time(ms)",
          yLabel: "AbsoluteActuatorposition(mV)*100",
          yLabel2: "",
          chartDetails: chartDetail?.value,
        };
        break;
      }
      case "line": {
        data = {
          id: id,
          dataAxis1: gData?.values?.[0],
          dataAxis2: gData?.values?.[1],
          dataAxis3: gData?.values?.[2],
          title: item.title,
          xLabel: "Time(ms)",
          yLabel: "Angle(degrees)",
          chartDetails: chartDetail?.value,
        };
        break;
      }
      case "singleColumn": {
        data = {
          id: id,
          dataAxis1: gData?.values?.[0],
          xLabel: "Velocity",
          yLabel: "EGRValueEnd-to-EndSweepAngle",
          title: item.title,
          chartDetails: chartDetail?.value,
        };
        break;
      }
    }
    return data;
  };

  const createChartPerType = (type, id, isLarge = false) => {
    const data = getDataForCharts(type, id);
    switch (type) {
      case "singleLine": {
        return (
          <>
            {isLarge ? (
              <SingleLine data={data} onClickHandler={() => {}}></SingleLine>
            ) : (
              <SingleLine
                data={data}
                onClickHandler={() => handleChartClick(id)}
              ></SingleLine>
            )}
          </>
        );
      }
      case "dotLine": {
        return (
          <>
            {isLarge ? (
              <DotLine data={data} onClickHandler={() => {}}></DotLine>
            ) : (
              <DotLine
                data={data}
                onClickHandler={() => handleChartClick(id)}
              ></DotLine>
            )}
          </>
        );
      }
      case "dualLine": {
        return (
          <>
            {isLarge ? (
              <DualLine data={data} onClickHandler={() => {}}></DualLine>
            ) : (
              <DualLine
                data={data}
                onClickHandler={() => handleChartClick(id)}
              ></DualLine>
            )}
          </>
        );
      }
      case "line": {
        return (
          <>
            {isLarge ? (
              <Line data={data} onClickHandler={() => {}}></Line>
            ) : (
              <Line
                data={data}
                onClickHandler={() => handleChartClick(id)}
              ></Line>
            )}
          </>
        );
      }
      case "singleColumn": {
        return (
          <>
            {isLarge ? (
              <SingleColumn
                data={data}
                onClickHandler={() => {}}
              ></SingleColumn>
            ) : (
              <SingleColumn
                data={data}
                onClickHandler={() => handleChartClick(id)}
              ></SingleColumn>
            )}
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "20px",
            paddingTop: "20px",
            fontFamily: "Open Sans",
            fontSize: "large",
          }}
        >
          Digital Twin – EGR valve Performance Tracking
        </div>
        <div
          className="page-chart-container vehicle-usage-charts"
          style={{ marginLeft: "40px" }}
        >
          {!noDataFound &&
            filterData.map(
              (el) =>
                el.isChecked && !showModal && createChartPerType(el.type, el.id)
            )}
          {showModal && !noDataFound && (
            <>
              <div
                className="nav-icons"
                onClick={() => showPrev(selectedChartData)}
              >
                <img src={navLeft} alt="previous" />
              </div>
              <div className="large-charts">
                {selectedChartData.type !== "line" && (
                  <div
                    className={`modal-close driver-behaviour `}
                    onClick={clickOnClose}
                  >
                    <img
                      src={close}
                      alt="close"
                      style={{ position: "relative", bottom: "40px" }}
                    />
                  </div>
                )}

                {selectedChartData.type === "line" && (
                  <div
                    className={`modal-close driver-behaviour ${
                      selectedChartData.type === "singleColumn"
                        ? "single-column"
                        : ""
                    }`}
                    onClick={clickOnClose}
                  >
                    <img src={close} alt="close" />
                  </div>
                )}

                {createChartPerType(
                  selectedChartData.type,
                  selectedChartData.id,
                  true
                )}
              </div>
              <div
                className="nav-icons"
                onClick={() => showNext(selectedChartData)}
              >
                <img src={navRight} alt="previous" />
              </div>
            </>
          )}
          {noDataFound && (
            <div className="no-data">
              Sorry, We could not find any data for the given search criteria.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ComponentSubSystem;
