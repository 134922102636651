import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "./reducers/authReducer";
import { mainReducer } from "./reducers/mainReducer";
import vibrationalAnalyticsReducer from "./vibrationalAnalytics/reducer";
import rootCauseReducer from "./rootCauseAnalysis/reducer";
// Combine reducers
const rootReducer = combineReducers({
  mainReducer: mainReducer,
  vAnalytics: vibrationalAnalyticsReducer,
  authReducer: authReducer,
  rootCauseReducer: rootCauseReducer,
});

const middleware = applyMiddleware(thunk);

const store = createStore(rootReducer, middleware);

export default store;
