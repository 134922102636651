import { getToken } from "../../../../services/helpers";
import { mainAxiosInstance } from "../../../../services/service";
import { FuelEconomyEndpoints } from "../../../../services/service-constants";
import { graphType } from "../utils/constants";

export const getFuelEconomyData = async (payload) => {
  const { type, vinNumber, orgId, fromDate, toDate, lastMiles, lastTrips } =
    payload;
  const url = FuelEconomyEndpoints.fuel_economy;
  const reqBody = {
    type: type || graphType.DATE,
    vinNumber: vinNumber || "DH34ASD7SDFF84742",
    organizationId: orgId || 1,
    fromDate: fromDate || "",
    toDate: toDate || "",
    lastMiles: lastMiles || 0,
    lastTrips: lastTrips || 0,
  };
  return mainAxiosInstance
    .post(url, reqBody, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: "You are not authorized",
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response.status,
          data: err.msj || "You are not authorized.",
        };
      } else {
        return {
          code: "error",
          data: err.msj || "Something went wrong",
        };
      }
    });
};
