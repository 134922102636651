import React from "react";
import "./index.scss";
import login_left_car from "../../assets/login_left_car.png";
import logo from "../../assets/logo_blue.png";

const LoginLayout = () => {
  return (
    <div className="layout__container">
      <div className="layout__container--content">
        <div className="layout__container--logo">
          <img
            src={logo}
            width="100%"
            height="100%"
            alt="autonostix_logo"
          ></img>
        </div>
        <div className="layout__container--image">
          <img src={login_left_car} width="100%" height="100%" alt="car" />
        </div>
        <div className="layout__container--testimonial">
          <p>
            “Predict and Prevent Failures”<span>Autonostix360</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
