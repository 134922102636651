import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";

import "./index.scss";

const OperationalAnalytics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/operational-analytics") {
      navigate("/operational-analytics/driver-behaviour", { replace: true });
    }
  }, [location.pathname]);

  return (
    <div className=" maintenance-log-summary-wrapper">
      <div className="tabs-container operational-analytics">
        <NavLink
          to="/operational-analytics/driver-behaviour"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Driver Behaviour
        </NavLink>
        <NavLink
          to="/operational-analytics/fuel-economy"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Fuel Economy
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default OperationalAnalytics;
