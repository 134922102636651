export const ServiceConfig = {
  baseUrl:
    //"http://autonostixdev-alb-1765671364.ap-south-1.elb.amazonaws.com/v1/api/",
    "https://ds.autonostix360.com/v1/api/",
  // "https://dm.autonostix360.com/v1/api/",
  //"/v1/api/",
  // 'http://localhost:8096/v1/api/',
  loginbaseUrl:
    // "https://dm.autonostix360.com/",
    "https://ds.autonostix360.com/",
  //"/",
  usage_profile: "usage_profile",
  vehicle_health_check: "vehicle_health_check/",
  self_test: "vehicle_health_check/self_test/",
  fault_trend_analytics:
    "fault_trend_analytics/fault_trend_and_mod/fault_trend_analytics",
  fault_trend_analytics_graph: "fault_trend_analytics/",
  maintenance_logs: "maintenance_logs/log",
  maintenance_logs_search: "maintenance_logs/log/search",
  maintenance_logs_filter: "maintenance_logs/log/filter",
  get_maintenance_logs_from_filter: "maintenance_logs/log/search_from_filter",
  dtc_list: "vehicle_health_check/dtcs/",
  mod_routine_maintenance: "mod/routine_maintenance",
  mod_diagnostics: "fault_trend_analytics/fault_trend_and_mod/diagnostics",
  mod_prognostics: "fault_trend_analytics/fault_trend_and_mod/prognostics",
  mod_root_cause_analysis: "mod/root_cause_analysis",
  mod_ishikawa_diagram: "mod/ishikawa_diagram",
  mod_prescribed_actions: "mod/prescribed_actions",
};

export const maintenanceLogEndPoints = {
  score_card: "maintenance_logs/score/maintenance_score_card",
  time_driven_action: "maintenance_logs/score/time_driven_action",
  location: "maintenance_logs/score/location",
  quality: "maintenance_logs/score/quality",
  usage_based_maintenance: "maintenance_logs/score/usage_based_maintenance",
  prognostics_based_maintenance:
    "maintenance_logs/score/prognostics_based_maintenance",
  diagnostic_based_maintenance:
    "maintenance_logs/score/diagnostic_based_maintenance",
  graph: "maintenance_logs/score/graph",
};

export const serviceGuideEndPoints = {
  getServiceGuide: "mod/service_guide",
};

export const AdminDashboardEndPoints = {
  getUserlistEndpoint: "admin/users",
  changeUserRole: "admin/users/role",
  userApporval: "admin/users/approval",
  userDecline: "admin/users/decline",
  userDelete: "admin/users/remove",
};

export const vehicleHealthCheckEndpoints = {
  get_performance: "vehicle_health_check/vibrational_analytics/engine_api",
};

export const endpoints = {
  breakPressure: "break_pressure_by_",
  engineCoolantTemp: "engine_coolant_temperature_by_",
  engineOilLevel: "engine_oil_level_by_",
  engineOilTemp: "engine_oil_temperature_by_",
  engineSpeed: "engine_speed_by_",
  engineTorque: "engine_torque_by",
  remainingEngineLife: "remaining_engine_life_by_",
  tirePressure: "tire_pressure_by_",
  transmissionGear: "transmission_gear_by_",
  transmissionOilTemp: "transmission_oil_temperature_by_",
};

export const vehicleOverviewEndpoints = {
  vehicleNotification: "vehicle_overview/vehicle_notification",
  vehilceInformation: "vehicle_overview/vehicle_information",
  vehilceRealtime: "vehicle_overview/all_real_time_data",
  vehilceReadStatusUpdate: "vehicle_overview/status_update",
};

export const selfTestEndPoints = {
  koer_result: "koer",
  koeo_result: "koeo",
};

export const digitalTwinEndpoints = {
  trips: "digital_twin_all_by_trip",
  miles: "digital_twin_all_by_miles",
  date: "digital_twin_all_by_date",
};

export const usageProfileEndPoints = {
  usage_profile: "usage_profile",
};

export const ComponentSubSystemEndpoints =
  "vehicle_health_check/component_sub_system_all_graphs";

export const OperationalAnalyticsEndPoints =
  "remote_monitoring/rm_driver_behaviour";

export const componentSystemHistoryEndPoints = {
  diagnostics: "vehicle_health_check/csh/diagnostics_and_prognostics",
  prognostics: "vehicle_health_check/csh/diagnostics_and_prognostics",
  maintenance_history: "vehicle_health_check/csh/maintenance_history",
  maintenance_profile: "vehicle_health_check/csh/system_maintenance_profile",
  usage_profile: "vehicle_health_check/csh/system_usage_profile",
  service_conditions: "vehicle_health_check/csh/service_conditions",
};
export const FuelEconomyEndpoints = {
  fuel_economy: "fuel_economy",
};

export const GetDriverBehaviourSummaryApi =
  "remote_monitoring/driver_behaviour_summary";
export const PictorialRepresentationApi =
  "remote_monitoring/pictorial_representation";
