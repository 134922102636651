import React, { useContext, useEffect, useState } from "react";
import HeaderIconAndText from "./headerIconAndText";
import HeaderLogo from "./headerLogo";
import help from "../../assets/help.svg";
import notifications from "../../assets/notifications.svg";
import profile from "../../assets/profile.svg";
import searchIcon from "../../assets/search.svg";
import "./header.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/authActions";
const Header = () => {
  const dispatch = useDispatch();
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    setSearchVal(vinNumber);
  }, [vinNumber]);

  const handleSearchChange = (e) => {
    setSearchVal(e.target.value);
  };

  const handleEnter = (e) => {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code === 13) {
      localStorage.setItem("searchVal", e.target.value);
    }
  };

  return (
    <div className="header flex justify-content-space-between">
      <div className="logo-container">
        <HeaderLogo></HeaderLogo>
      </div>
      <div className="header-search">
        <input
          type="text"
          value={searchVal}
          placeholder="Enter VIN number"
          onChange={handleSearchChange}
          onKeyPress={handleEnter}
        />
        <img className="search-icon" src={searchIcon} alt="search" />
      </div>
      <div className="navigation-container flex justify-content-space-between">
        <HeaderIconAndText
          iconUrl={notifications}
          iconAltText="Notifications"
          handleClick={() => {}}
          iconText="Notifications"
        ></HeaderIconAndText>
        <HeaderIconAndText
          iconUrl={help}
          iconAltText="Help"
          handleClick={() => {}}
          iconText="Help"
        ></HeaderIconAndText>
        <HeaderIconAndText
          iconUrl={profile}
          iconAltText="My Profile"
          handleClick={() => dispatch(logout())}
          iconText="Logout"
        ></HeaderIconAndText>
      </div>
    </div>
  );
};

export default Header;
