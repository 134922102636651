import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate,useLocation } from "react-router-dom";
import './diagnostics.scss';

const Diagnostics = () => {
  const navigate = useNavigate();
  const location=useLocation();
  useEffect(() => {
    if(location.pathname==="/vehicle-health/diagnostics"){
    navigate('/vehicle-health/diagnostics/on-board', { replace: true });
    }
  },[location.pathname]);
  return (
    <div className="full-width diagnostics-container">
      <div className="sec-tabs-container">
        <NavLink
          to="/vehicle-health/diagnostics/on-board"
          className={({ isActive }) =>
            isActive ? "btn-primary sec-tabs" : "btn-secondary sec-tabs"
          }
        >
          On Board
        </NavLink>
        <NavLink
          to="/vehicle-health/diagnostics/remote-testing"
          className={({ isActive }) =>
            isActive ? "btn-primary sec-tabs" : "btn-secondary sec-tabs"
          }
        >
          Remote testing
        </NavLink>
        <NavLink
          to="/vehicle-health/diagnostics/vibrational-analytics"
          className={({ isActive }) =>
            isActive ? "btn-primary sec-tabs" : "btn-secondary sec-tabs"
          }
        >
          Vibrational Analytics
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default Diagnostics;