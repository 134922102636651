import React from "react";
import "./index.scss";
import { Button } from "../../components/common/Button/Button";
import Input from "../../components/common/Input";
import Modal from "../../components/common/Modal/Modal";

const Forgotpassword = ({
  handleFPInputChange,
  FPemail,
  error,
  errorFPMsg,
  handleClose,
  handleClick,
  handleKeyDown,
}) => {
  return (
    <>
      <Modal
        type={"medium"}
        title={""}
        titlePosition={"center"}
        isError={false}
        handleClose={handleClose}
      >
        <div className="forgotpwd__Form">
          <div className="forgotpwd__Form--title">
            <p>Registered Email</p>
          </div>
          <span className="Form--error">{errorFPMsg}</span>
          <div className="forgotpwd__Form--inputs">
            <Input
              htmlForId="FPemail"
              type="text"
              placeholder="Enter your email address"
              value={FPemail}
              handleOnChange={handleFPInputChange}
              error={error.FPemail}
              onKeyDown={handleKeyDown}
              onBlur={handleFPInputChange}
            />
            <Button
              title="Submit"
              handleClick={handleClick}
              styleClasses="btn btn-primary"
            />
          </div>
          <div className="forgotpwd__Form--footer">
            <p>
              <span onClick={handleClose}>Back to Login page</span>
            </p>
            <p>
              <span onClick={handleClose}>Close</span>
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Forgotpassword;
