import Details from "../components/Details";
import VehicleHealthCheck from "../components/VehicleHealthCheck/index";
import VehicleUsageProfile from "../pages/VehicleUsageProfile/VehicleUsageProfile";
import Diagnostics from "../components/VehicleHealthCheck/Diagnostics/Diagnostics";
import Home from "../components/Home";
import SelfTest from "../components/VehicleHealthCheck/SelfTest/SelfTest";
import DigitalTwin from "../components/VehicleHealthCheck/DigitalTwin/digitalTwin";
import PerformanceDigitalTwin from "../components/VehicleHealthCheck/DigitalTwin/PerformanceDigitalTwin/index";
import ComponentSubSystem from "../components/VehicleHealthCheck/DigitalTwin/ComponentSubSystem";
import OnBoard from "../components/VehicleHealthCheck/Diagnostics/OnBoard/OnBoard";
import MaintenanceLogs from "../pages/MaintenanceLogs";
import FaultTrendAnalytics from "../pages/FaultTrendAnalytics/FaultTrendAnalytics";
import FaultAnalytics from "../pages/FaultTrendAnalytics/index";
import MaintenanceOnDemand from "../pages/MaintenanceOnDemand/index";
import RoutineMaintenance from "../pages/MaintenanceOnDemand/RoutineMaintenance/RoutineMaintenance";
import MaintenanceDiagnostics from "../pages/MaintenanceOnDemand/MaintenanceDiagnostics/index";
import MaintenancePrognostics from "../pages/MaintenanceOnDemand/MaintenancePrognostics";
import VehicleOverview from "../pages/VehicleOverview";
import VehicleInformation from "../pages/VehicleOverview/VehicleInformation/VehicleInformation";
import VehicleNotification from "../pages/VehicleOverview/VehicleNotfication/VehicleNotification";
import TimeDrivenAction from "../pages/MaintenanceLogs/MaintenanceLogsSummary/Time Driven Action";
import MaintenanceOnDemandLogs from "../pages/MaintenanceLogs/MaintenanceLogsSummary/Maintenance On-Demand";
import Quality from "../pages/MaintenanceLogs/MaintenanceLogsSummary/Quality";
import Location from "../pages/MaintenanceLogs/MaintenanceLogsSummary/Location";
import OperationalAnalytics from "../pages/Operational Analytics";
import DriverBehaviour from "../pages/Operational Analytics/Driver Behaviour";
import WarrantyDiagnostics from "../pages/WarrantyManagement/Diagnostics/Diagnostics";
import WarrantyPrognostics from "../pages/WarrantyManagement/Prognostics/Prognostics";
import WarrantyManagement from "../pages/WarrantyManagement";
import TopDiagonasticDtcComplaints from "../pages/WarrantyManagement/Diagnostics/TopDtcComplaints/TopDtcComplaints";
import TopDiagonasticNonDtcComplaints from "../pages/WarrantyManagement/Diagnostics/TopNonDtcComplaints/TopNonDtcComplaints";
import TopPrognosticDtcComplaints from "../pages/WarrantyManagement/Prognostics/TopDtcComplaints/TopDtcComplaints";
import TopPrognosticNonDtcComplaints from "../pages/WarrantyManagement/Prognostics/TopNonDtcComplaints/TopNonDtcComplaints";
import WarrantyRate from "../pages/WarrantyManagement/WarrantyRate/WarrantyRate";
import RootCauseAnalysis from "../pages/RootCauseAnalysis";
import RemoteTesting from "../components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemoteTesting";
import VehicleRealTimeRefactor from "../pages/VehicleOverview/VehicleRealTimeData/VehicleRealTimeRefactor";
import VibrationalAnalytics from "../components/VehicleHealthCheck/Diagnostics/VibrationalAnalytics/VibrationalAnalytics";
import RemotePerformanceProfile from "../components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemotePerformanceProfile";
import RemoteAbsDiagram from "../components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemoteAbsDiagram";
import DashBoard from "../pages/Dashboard";
import ArchitectureServiceGuide from "../pages/MaintenanceOnDemand/ServiceGuide/Diagnostics/Architecture";
import UserManualServiceGuide from "../pages/MaintenanceOnDemand/ServiceGuide/Diagnostics/UserManual";
import AugmentedRealityServiceGuide from "../pages/MaintenanceOnDemand/ServiceGuide/Diagnostics/AugmentedReality";
import YoutubeVideosServiceGuide from "../pages/MaintenanceOnDemand/ServiceGuide/Diagnostics/YoutubeVideos";
import SocialMediaServiceGuide from "../pages/MaintenanceOnDemand/ServiceGuide/Diagnostics/SocialMedia";
import PictorialRepresentation from "../pages/Operational Analytics/PictorialRepresentation";
import ComponentSystemHistoryDiagnostics from "../components/ComponentSystemHistory/Diagnostics";
import ComponentSystemHistoryPrognostics from "../components/ComponentSystemHistory/Prognostics";
import ComponentSystemHistoryMaintenanceProfile from "../components/ComponentSystemHistory/MaintenanceProfile";
import ComponentSystemHistoryMaintenanceHistory from "../components/ComponentSystemHistory/MaintenanceHistory";
import ComponentSystemHistoryUsageProfile from "../components/ComponentSystemHistory/UsageProfile";
import ComponentSystemHistoryServiceConditions from "../components/ComponentSystemHistory/ServiceConditions";
import FuelEconomy from "../pages/Operational Analytics/FuelEconomy";
import IshikawaDiagram from "../pages/RootCauseAnalysis/RootCauseData/IshikawaDiagram";

const RemoteTestingRoutes = [
  {
    breadcrumb: "Performance Profile",
    title: "Performance Profile",
    iconName: "",
    component: <RemotePerformanceProfile />,
    path: "/vehicle-health/diagnostics/remote-testing/performance-profile",
  },
  {
    breadcrumb: "ABS Diagram",
    title: "ABS Diagram",
    iconName: "",
    component: <RemoteAbsDiagram />,
    path: "/vehicle-health/diagnostics/remote-testing/abs-diagram",
  },
];

const VehicleDiagnosticsRoutes = [
  {
    breadcrumb: "On Board",
    title: "On Board",
    iconName: "",
    component: <OnBoard />,
    path: "/vehicle-health/diagnostics/on-board",
  },
  {
    breadcrumb: "Remote Testing",
    title: "Remote Testing",
    iconName: "",
    component: <RemoteTesting />,
    path: "/vehicle-health/diagnostics/remote-testing",
    nestedRoutes: RemoteTestingRoutes,
  },
  {
    breadcrumb: "Remote Testing",
    title: "Vibrational Analytics",
    iconName: "",
    component: <VibrationalAnalytics />,
    path: "/vehicle-health/diagnostics/vibrational-analytics",
  },
];

const DigitalTwinRoutes = [
  {
    breadcrumb: "Performance Digital Twin",
    iconName: "",
    component: <PerformanceDigitalTwin />,
    path: "/vehicle-health/digital-twin/performance-digital-twin",
  },
  {
    breadcrumb: "Component Sub System",
    iconName: "",
    component: <ComponentSubSystem />,
    path: "/vehicle-health/digital-twin/component-sub-system",
  },
];

export const VehicleHealthCheckRoutes = [
  {
    breadcrumb: "Diagnostics",
    iconName: "",
    component: <Diagnostics />,
    path: "/vehicle-health/diagnostics",
    nestedRoutes: VehicleDiagnosticsRoutes,
  },
  {
    breadcrumb: "Self Test",
    iconName: "",
    component: <SelfTest />,
    path: "/vehicle-health/self-test",
  },
  {
    breadcrumb: "Digital Twin",
    iconName: "",
    component: <DigitalTwin />,
    path: "/vehicle-health/digital-twin",
    nestedRoutes: DigitalTwinRoutes,
  },
];

export const WarrantyDiagnosticsRoutes = [
  {
    breadcrumb: "Top 10 DTCs Complaints",
    title: "Top 10 DTCs Complaints",
    iconName: "",
    component: <TopDiagonasticDtcComplaints />,
    path: "/warranty-management/diagnostics/top-10-dtc-complaints",
  },
  {
    breadcrumb: "Top 10 non-DTC Complaints",
    title: "Top 10 non-DTC Complaints",
    iconName: "",
    component: <TopDiagonasticNonDtcComplaints />,
    path: "/warranty-management/diagnostics/top-10-non-dtc-complaints",
  },
];

export const DashBoardRoutes = [
  {
    breadcrumb: "",
    title: "",
    iconName: "",
    component: <DashBoard />,
    path: "/dashBoard",
  },
];

export const WarrantyPrognosticsRoutes = [
  {
    breadcrumb: "Top 10 DTCs Complaints",
    title: "Top 10 DTCs Complaints",
    iconName: "",
    component: <TopPrognosticDtcComplaints />,
    path: "/warranty-management/prognostics/top-10-dtc-complaints",
  },
  {
    breadcrumb: "Top 10 non-DTC Complaints",
    title: "Top 10 non-DTC Complaints",
    iconName: "",
    component: <TopPrognosticNonDtcComplaints />,
    path: "/warranty-management/prognostics/top-10-non-dtc-complaints",
  },
];

export const WarrantyManagementRoutes = [
  {
    breadcrumb: "Diagnostics",
    iconName: "",
    component: <WarrantyDiagnostics />,
    path: "/warranty-management/diagnostics",
    nestedRoutes: WarrantyDiagnosticsRoutes,
  },
  {
    breadcrumb: "Prognostics",
    iconName: "",
    component: <WarrantyPrognostics />,
    path: "/warranty-management/prognostics",
    nestedRoutes: WarrantyPrognosticsRoutes,
  },
  {
    breadcrumb: "Warranty rate",
    iconName: "",
    component: <WarrantyRate />,
    path: "/warranty-management/warranty-rate",
  },
];

export const MODServiceGuideDetailsRoutes = [
  {
    breadcrumb: "Architecture",
    title: "Architecture",
    iconName: "",
    component: <ArchitectureServiceGuide />,
    path: "/maintenance-overview/diagnostics/service-guide/architecture",
  },
  {
    breadcrumb: "User Manual",
    title: "User Manual",
    iconName: "",
    component: <UserManualServiceGuide />,
    path: "/maintenance-overview/diagnostics/service-guide/user-manual",
  },
  {
    breadcrumb: "Augmented Reality",
    title: "Augmented Reality",
    iconName: "",
    component: <AugmentedRealityServiceGuide />,
    path: "/maintenance-overview/diagnostics/service-guide/augmented-reality",
  },
  {
    breadcrumb: "Youtube Videos",
    title: "Youtube Videos",
    iconName: "",
    component: <YoutubeVideosServiceGuide />,
    path: "/maintenance-overview/diagnostics/service-guide/youtube-videos",
  },
  {
    breadcrumb: "Internet Contents",
    title: "Internet Contents",
    iconName: "",
    component: <SocialMediaServiceGuide />,
    path: "/maintenance-overview/diagnostics/service-guide/internet-contents",
  },
];
export const PrognosticsServiceGuideDetailsRoutes = [
  {
    breadcrumb: "Architecture",
    title: "Architecture",
    iconName: "",
    component: <ArchitectureServiceGuide />,
    path: "/maintenance-overview/prognostics/service-guide/architecture",
  },
  {
    breadcrumb: "User Manual",
    title: "User Manual",
    iconName: "",
    component: <UserManualServiceGuide />,
    path: "/maintenance-overview/prognostics/service-guide/user-manual",
  },
  {
    breadcrumb: "Augmented Reality",
    title: "Augmented Reality",
    iconName: "",
    component: <AugmentedRealityServiceGuide />,
    path: "/maintenance-overview/prognostics/service-guide/augmented-reality",
  },
  {
    breadcrumb: "Youtube Videos",
    title: "Youtube Videos",
    iconName: "",
    component: <YoutubeVideosServiceGuide />,
    path: "/maintenance-overview/prognostics/service-guide/youtube-videos",
  },
  {
    breadcrumb: "Internet Contents",
    title: "Internet Contents",
    iconName: "",
    component: <SocialMediaServiceGuide />,
    path: "/maintenance-overview/prognostics/service-guide/internet-contents",
  },
];

export const RootCauseDataRoutes = [
  {
    title: "Data Visualization",
    breadcrumb: "Data Visualization",
    iconName: "",
    component: <Details />,
    path: "/data-visualization",
  },
  {
    title: "Is - Is Not Analysis",
    breadcrumb: "Is - Is Not Analysis",
    iconName: "",
    component: <Details />,
    path: "/is-isnot-analysis",
  },
  {
    title: "Ishikawa Diagram",
    breadcrumb: "Ishikawa Diagram",
    iconName: "",
    component: <IshikawaDiagram />,
    path: "/ishikawa-diagram",
  },
];

export const FaultAnalyticsRoute = [
  {
    title: "Fault Trend Analytics",
    breadcrumb: "Fault Trend Analytics > Predicted DTC’s",
    iconName: "",
    component: <FaultTrendAnalytics />,
    path: "/fault-analytics",
  },
  {
    title: "Root Cause Analysis",
    breadcrumb: "Root Cause Analysis",
    iconName: "",
    component: <RootCauseAnalysis />,
    path: "/fault-analytics/root-cause-analysis",
  },
];
export const MaintenanceOnDemandRoutes = [
  {
    title: "Routine Maintenance",
    breadcrumb: "Routine Maintenance",
    iconName: "",
    component: <RoutineMaintenance />,
    path: "/maintenance-overview/routine-maintenance",
  },
  {
    title: "Diagnostics",
    breadcrumb: "Diagnostics > Predicted DTC’s",
    iconName: "",
    element: <MaintenanceDiagnostics />,
    component: <MaintenanceDiagnostics />,
    path: "/maintenance-overview/diagnostics",
  },
  {
    title: "Prognostics",
    breadcrumb: "Prognostics > Predicted DTC’s",
    iconName: "",
    component: <MaintenancePrognostics />,
    path: "/maintenance-overview/prognostics",
  },
  {
    title: "Root Cause Analysis",
    breadcrumb: "Root Cause Analysis",
    iconName: "",
    component: <RootCauseAnalysis />,
    path: "/maintenance-overview/diagnostics/root-cause-analysis",
  },
  {
    title: "Root Cause Analysis",
    breadcrumb: "Root Cause Analysis",
    iconName: "",
    component: <RootCauseAnalysis />,
    path: "/maintenance-overview/prognostics/root-cause-analysis",
  },
];

export const VehicleOverviewRoutes = [
  {
    title: "Vehicle Information",
    breadcrumb: "Vehicle Information",
    iconName: "",
    component: <VehicleInformation />,
    path: "/vehicle-overview/information",
  },
  {
    title: "Vehicle Notification",
    breadcrumb: "Vehicle Notification",
    iconName: "",
    element: <VehicleNotification />,
    component: <VehicleNotification />,
    path: "/vehicle-overview/notification",
  },
  {
    title: "Real Time Data",
    breadcrumb: "Real Time Data",
    iconName: "",
    component: <VehicleRealTimeRefactor />,
    path: "/vehicle-overview/realtime-data",
  },
];

export const OperationalAnalyticsRoute = [
  {
    title: "Driver Behaviour",
    breadcrumb: "Driver Behaviour",
    iconName: "",
    component: <DriverBehaviour />,
    path: "/operational-analytics/driver-behaviour",
  },
  {
    title: "Fuel Economy",
    breadcrumb: "Fuel Economy",
    iconName: "",
    component: <FuelEconomy />,
    path: "/operational-analytics/fuel-economy",
  },
];

export const MaintenanceLogsRoutes = [
  {
    title: "Time Driven Action",
    breadcrumb: "Time Driven Action",
    iconName: "",
    component: <TimeDrivenAction />,
    path: "/maintenance-log/summary/time-driven-action",
  },
  {
    title: "Maintenance On-Demand",
    breadcrumb: "Maintenance On-Demand",
    iconName: "",
    component: <MaintenanceOnDemandLogs />,
    path: "/maintenance-log/summary/maintenance-on-demand",
  },
  {
    title: "Quality",
    breadcrumb: "Quality",
    iconName: "",
    component: <Quality />,
    path: "/maintenance-log/summary/quality",
  },
  {
    title: "Location",
    breadcrumb: "Location",
    iconName: "",
    component: <Location />,
    path: "/maintenance-log/summary/location",
  },
];

export const NavigationDashBoardMenuLish = [
  {
    breadcrumb: "DashBoard",
    title: "DashBoard",
    iconName: "",
    component: <DashBoard />,
    path: "/dashboard",
  },
];

export const AdminNavigationMenuList = [
  {
    breadcrumb: "Configure Features",
    title: "Configure Features",
    iconName: "configure-features",
    component: <Details />,
    path: "/configure-features",
  },
];

export const NavigationMenuList = [
  {
    breadcrumb: "Vehicle Overview",
    title: "Vehicle Overview",
    iconName: "over-view",
    component: <VehicleOverview />,
    path: "/vehicle-overview",
    nestedRoutes: VehicleOverviewRoutes,
  },

  {
    breadcrumb: "Vehicle Usage Profile",
    title: "Vehicle Usage Profile",
    iconName: "usage-profile",
    component: <VehicleUsageProfile />,
    path: "/vehicle-usage",
  },
  {
    breadcrumb: "Vehicle Health Check",
    title: "Vehicle Health Check",
    iconName: "health-check",
    component: <VehicleHealthCheck />,
    path: "/vehicle-health",
    nestedRoutes: VehicleHealthCheckRoutes,
  },
  {
    breadcrumb: "Remote Monitoring",
    title: "Operational Analytics",
    iconName: "remote-monitoring",
    component: <OperationalAnalytics />,
    path: "/operational-analytics",
    nestedRoutes: OperationalAnalyticsRoute,
  },
  {
    breadcrumb: "Fault Trend Analytics",
    title: "Fault Trend Analytics",
    iconName: "fault-trend",
    component: <FaultAnalytics />,
    path: "/fault-analytics",
    nestedRoutes: FaultAnalyticsRoute,
  },
  {
    breadcrumb: "Prescriptive Analytics",
    title: "Prescriptive Analytics",
    iconName: "perspective-analytics",
    component: <Details />,
    path: "/prescriptive-analytics",
  },
  {
    breadcrumb: "Maintenance On-Demand",
    title: "Maintenance On-Demand",
    iconName: "maintainance-overview",
    component: <MaintenanceOnDemand />,
    path: "/maintenance-overview",
    nestedRoutes: MaintenanceOnDemandRoutes,
  },
  {
    breadcrumb: "Maintenance Log",
    title: "Maintenance Log",
    iconName: "maintainance-logs",
    component: <MaintenanceLogs />,
    path: "/maintenance-log",
  },
  {
    breadcrumb: "Warranty Management",
    title: "Warranty Management",
    iconName: "warranty-management",
    component: <WarrantyManagement />,
    path: "/warranty-management",
    nestedRoutes: WarrantyManagementRoutes,
  },
];

export const ComponentSystemHistoryRoutes = [
  {
    breadcrumb: "Diagnostics",
    title: "Diagnostics",
    iconName: "",
    component: <ComponentSystemHistoryDiagnostics />,
    path: "/diagnostics",
  },
  {
    breadcrumb: "Prognostics",
    title: "Prognostics",
    iconName: "",
    component: <ComponentSystemHistoryPrognostics />,
    path: "/prognostics",
  },
  {
    breadcrumb: "Maintenance Profile",
    title: "Maintenance Profile",
    iconName: "",
    component: <ComponentSystemHistoryMaintenanceProfile />,
    path: "/maintenance-profile",
  },
  {
    breadcrumb: "Maintenance History",
    title: "Maintenance History",
    iconName: "",
    component: <ComponentSystemHistoryMaintenanceHistory />,
    path: "/maintenance-history",
  },
  {
    breadcrumb: "Usage Profile",
    title: "Usage Profile",
    iconName: "",
    component: <ComponentSystemHistoryUsageProfile />,
    path: "/usage-profile",
  },
  {
    breadcrumb: "Service Conditions",
    title: "Service Conditions",
    iconName: "",
    component: <ComponentSystemHistoryServiceConditions />,
    path: "/service-conditions",
  },
];

export const routes = [
  {
    path: "/",
    breadcrumb: "Home",
    element: <Home />,
    nav: true,
    children: [
      {
        path: "/maintenance-overview",
        breadcrumb: "Maintenance On Demand",
        element: <MaintenanceOnDemand />,
        children: MaintenanceOnDemandRoutes,
      },
      {
        path: "/vehicle-overview",
        breadcrumb: "Vehicle Overview",
        element: <VehicleOverview />,
        children: VehicleOverviewRoutes,
      },
      {
        path: "vehicle-usage",
        element: <VehicleUsageProfile />,
        breadcrumb: "Vehicle Usage Profile",
      },
      {
        path: "vehicle-health",
        element: <VehicleHealthCheck />,
        breadcrumb: "Vehicle Health Check",
        // nestedRoutes: VehicleHealthCheckRoutes,
        nav: true,
        children: [
          {
            path: "vehicle-health/diagnostics",
            breadcrumb: "Diagnostics",
            element: <Diagnostics />,
            children: [
              {
                path: "vehicle-health/diagnostics/on-board",
                breadcrumb: "On Board",
                element: <OnBoard />,
              },
              {
                path: "vehicle-health/diagnostics/remote-testing",
                breadcrumb: "Remote Testing",
                element: <Details />,
              },
            ],
          },
          {
            path: "vehicle-health/self-test",
            breadcrumb: " Self Test",
            element: <SelfTest />,
          },
          {
            path: "/vehicle-health/digital-twin",
            breadcrumb: "Digital Twin",
            element: <DigitalTwin />,
            children: [
              {
                path: "vehicle-health/digital-twin/performance-digital-twin",
                breadcrumb: "Performance Digital Twin",
                element: <PerformanceDigitalTwin />,
              },
              {
                path: "vehicle-health/digital-twin/component-sub-system",
                breadcrumb: "Component Sub System",
                element: <ComponentSubSystem />,
              },
            ],
          },
        ],
      },
      {
        path: "operational-analytics",
        breadcrumb: "Operational Analytics",
        element: <Details />,
      },
      {
        path: "/operational-analytics/driver-behaviour/summary/pictorial-representation",
        breadcrumb: "Pictorial Representation",
        element: <PictorialRepresentation />,
      },
      {
        path: "/fault-analytics",
        breadcrumb: "Fault Trend Analytics",
        element: <FaultAnalytics />,
        children: FaultAnalyticsRoute,
      },
      {
        path: "/prescriptive-analytics",
        breadcrumb: "Prescriptive Analytics",
        element: <Details />,
      },

      {
        path: "/maintenance-log",
        breadcrumb: "Maintenance Log",
        element: <Details />,
      },
      {
        path: "/warranty-management",
        breadcrumb: "Warranty Management",
        element: <WarrantyManagement />,
        nav: true,
        children: [
          {
            path: "warranty-management/diagnostics",
            breadcrumb: "Diagnostics",
            element: <WarrantyDiagnostics />,
            children: [
              {
                path: "warranty-management/diagnostics/top-10-dtc-complaints",
                breadcrumb: "Top 10 DTCs Complaints",
                element: <TopDiagonasticDtcComplaints />,
              },
              {
                path: "warranty-management/diagnostics/top-10-non-dtc-complaints",
                breadcrumb: "Top 10 non-DTC Complaints",
                element: <TopDiagonasticNonDtcComplaints />,
              },
            ],
          },
          {
            path: "warranty-management/prognostics",
            breadcrumb: "Prognostics",
            element: <WarrantyPrognostics />,
            children: [
              {
                path: "warranty-management/prognostics/top-10-dtc-complaints",
                breadcrumb: "Top 10 DTCs Complaints",
                element: <TopPrognosticDtcComplaints />,
              },
              {
                path: "warranty-management/prognostics/top-10-non-dtc-complaints",
                breadcrumb: "Top 10 non-DTC Complaints",
                element: <TopPrognosticNonDtcComplaints />,
              },
            ],
          },
          {
            path: "/warranty-management/warranty-rate",
            breadcrumb: "Warranty Rate",
            element: <WarrantyRate />,
          },
        ],
      },

      { path: "*", element: <Details /> }, // TODO: Create a Not found component and update this with new component
    ],
  },
  {
    path: "/",
    breadcrumb: "Home",
    element: <Home />, // TODO: Replace this with login component
    children: [
      { path: "404", element: <Details /> }, // TODO: Create a Bad request component and update this with new component
      { path: "*", element: <Details /> }, // TODO: Create a Not found component and update this with new component
    ],
  },
  {
    path: "/",
    element: <Home />,
    breadcrumb: "Home",
    children: [
      { path: "health", element: <Details /> }, // Dummy route, DO NOT REMOVE
    ],
  },
];

export default routes;
