import React, { useState, useEffect, useRef } from "react";
import { Button } from "../../components/common/Button/Button";
import { Checkbox } from "../../components/common/Checkbox/Checkbox";
import Filter from "../../components/Filter/Filter";
import searchIcon from "../../assets/search-black.svg";
import { mainAxiosInstance } from "../../services/service";
import { ServiceConfig } from "../../services/service-constants";
import Modal from "../../components/common/Modal/Modal";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import "./maintenanceLogs.scss";
import {
  getDtcListAndSubsystemsData,
  getDtcListData,
  getFilterData,
  getInitialTableData,
} from "./api";
import SelectWithSearch from "../../components/common/SelectWithSearch/SelectWithSearch.js";
import Pagination from "../../components/common/Pagination";
import { useNavigate } from "react-router";

let subSystemCheckedArray = [];
let dtcCheckedArray = [];

const MaintenanceLogs = () => {
  const navigate = useNavigate();
  const [subSystems, setSubSystems] = useState([]);

  const [dtcCodes, setDtcCodes] = useState([]);

  const [searchVal, setSearchVal] = useState("");

  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  const [isAddActivityVisible, setIsAddActivityVisible] = useState(false);

  const [date, setDate] = useState("");

  const [time, setTime] = useState("");

  const [dtcListData, setDtcListData] = useState([]);

  const [dtcAndSubsystemsData, setDtcAndSubsystemsData] = useState();

  const [selectedDtcOption, setSelectedDtcOption] = useState(null);

  const [dtcVisible, setDtcVisible] = useState(5);

  const [subSystemVisible, setSubSystemVisible] = useState(5);

  const [initialTableData, setInitialTableData] = useState([]);

  const [currentPage, setCurrentPage] = useState();

  const [isPageFirst, setIsPageFirst] = useState(false);

  const [isPageLast, setIsPageLast] = useState(false);

  const [filterPageCount, setFilterPageCount] = useState(0);

  const [isSearchingVisible, setIsSearchingVisible] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [pagenation, setPagenation] = useState({
    totalpages: 0,
    size: 0,
    currentPageNo: 0,
  });

  const [dtcSelectOptions, setDtcSelectOptions] = useState();

  const inputRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const getDTCAndSubSystem = (values) => {
    const dtcMap = values?.dtcCodes?.map((dtc) => ({
      displayname: dtc,
      type: "dtc",
      isChecked: false,
    }));

    const subSystemMap = values?.subSystems?.map((subSystem) => ({
      displayname: subSystem,
      type: "subSystem",
      isChecked: false,
    }));
    if (Array.isArray(dtcMap)) {
      setDtcCodes([...dtcMap]);
    }
    if (Array.isArray(subSystemMap)) {
      setSubSystems([...subSystemMap]);
    }
  };

  const getDtcList = async (payload) => {
    const { page, searchString, vinNumber } = payload;
    const data = await getDtcListData({
      vinNumber: vinNumber,
    });
    setDtcListData(data);
  };
  const getDtcListAndSubsystems = async (payload) => {
    const { vinNumber } = payload;
    const data = await getDtcListAndSubsystemsData({
      vinNumber: vinNumber,
    });
    setDtcAndSubsystemsData(data);
  };

  const getInitialData = async (payload) => {
    const { page, searchString, vin_Number } = payload;
    const data = await getInitialTableData({
      page: page,
      searchString: searchString,
      vin_Number: vin_Number,
    });
    if (data) {
      setInitialTableData(data?.content);
      setCurrentPage(data?.pageable?.pageNumber);
      setIsPageFirst(data?.first);
      setIsPageLast(data?.last);
      setPagenation({
        totalpages: data?.totalPages,
        size: data?.size,
        currentPageNo: data?.pageable?.pageNumber,
      });
    }
  };

  useEffect(() => {
    getInitialData({
      page: 0,
      vin_Number: vin_Number,
    });
    getDtcList({
      vinNumber: vin_Number,
    });
    getDtcListAndSubsystems({
      vinNumber: vin_Number,
    });
  }, []);

  useEffect(() => {
    if (dtcAndSubsystemsData) {
      getDTCAndSubSystem(dtcAndSubsystemsData);
    }
  }, [dtcAndSubsystemsData]);

  /**
   * If search input is empty and input is focused getData
   */
  useEffect(() => {
    if (document.activeElement === inputRef?.current && !searchVal) {
      setIsSearchingVisible(false);
      getInitialData({
        page: 0,
        vin_Number: vin_Number,
      });
    }
  }, [searchVal]);

  useEffect(() => {
    const tempSelectOptions =
      dtcListData &&
      dtcListData?.map((dtc) => {
        return {
          label: `${dtc.dtcCode}-${dtc.description}`,
          value: dtc.dtcCode,
          key: dtc.identifier,
          desc: dtc.description,
        };
      });
    setDtcSelectOptions(tempSelectOptions);
  }, [dtcListData]);

  const getFilterDataFromApi = async (payload) => {
    const { page, vinNumber } = payload;
    const data = await getFilterData({
      vinNumber: vinNumber,
      organizationId: 1,
      page: page || 0,
      size: 10,
      subSystems: subSystemCheckedArray,
      dtcCodes: dtcCheckedArray,
    });
    if (data) {
      setInitialTableData(data?.content);
      setCurrentPage(data?.pageable?.pageNumber);
      setIsPageFirst(data?.first);
      setIsPageLast(data?.last);
      setPagenation({
        totalpages: data?.totalPages,
        size: data?.size,
        currentPageNo: data?.pageable?.pageNumber,
      });
    }
  };

  const onFilter = (val) => {
    if (val.type === "dtc") {
      // Push to dtcSearchString
      if (dtcCheckedArray.indexOf(val.displayname) === -1 && !val.isChecked) {
        dtcCheckedArray = [...dtcCheckedArray, val.displayname];
      } else {
        const temp = dtcCheckedArray.filter((sub) => sub !== val.displayname);
        dtcCheckedArray = [...temp];
      }

      // Update filter data
      const newDtcCodes = dtcCodes?.map((el) => {
        if (el.displayname === val.displayname) {
          el.isChecked = !el.isChecked;
        }
        return el;
      });
      setDtcCodes([...newDtcCodes]);
    }
    if (val.type === "subSystem") {
      if (
        subSystemCheckedArray.indexOf(val.displayname) === -1 &&
        !val.isChecked
      ) {
        subSystemCheckedArray = [...subSystemCheckedArray, val.displayname];
      } else {
        const temp = subSystemCheckedArray.filter(
          (sub) => sub !== val.displayname
        );
        subSystemCheckedArray = [...temp];
      }
      const newSubSystems = subSystems?.map((el) => {
        if (el.displayname === val.displayname) {
          el.isChecked = !el.isChecked;
        }
        return el;
      });
      setSubSystems([...newSubSystems]);
    }
    if (subSystemCheckedArray?.length + dtcCheckedArray?.length > 0) {
      setSearchVal("");
      setIsSearchingVisible(false);
      setSearchText("");
      getFilterDataFromApi({
        page: filterPageCount,
        vinNumber: vin_Number,
      });
      setIsSearchingVisible(true);
    } else {
      setIsSearchingVisible(false);
      getInitialData({
        page: 0,
        vin_Number: vin_Number,
      });
    }
  };

  const handleSearchChange = (e) => {
    const searchText = e.target.value;
    setSearchVal(searchText);
    setIsSearchingVisible(false);
  };

  const resetDtcAndSubsystems = () => {
    const resetDtc = dtcCodes?.map((dtc) => ({
      displayname: dtc.displayname,
      type: "dtc",
      isChecked: false,
    }));

    const resetSubsystems = subSystems?.map((subSystem) => ({
      displayname: subSystem.displayname,
      type: "subSystem",
      isChecked: false,
    }));
    setDtcCodes([...resetDtc]);
    setSubSystems(resetSubsystems);
  };

  const handleEnter = (e) => {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code === 13) {
      if (e.target?.value?.length !== 0) {
        setIsSearchingVisible(true);
      }
      setSearchText(e.target.value);
      // localStorage.setItem('searchVal', e.target.value);
      getInitialData({
        page: 0,
        searchString: e.target.value,
        vin_Number: vin_Number,
      });
    }

    subSystemCheckedArray = [];
    dtcCheckedArray = [];
    resetDtcAndSubsystems();
  };

  const submitActivity = async (value) => {
    const dateParam = date
      ? moment(new Date(date)).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");
    const url = `${ServiceConfig.maintenance_logs}`;
    const requestBody = {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      maintenanceActivity: value?.title,
      vinNumber: "DH34ASD7SDFF84742",
      organizationId: 1,
      dtcCode: selectedDtcOption?.value,
      subSystem: value?.subSystem,
      description: selectedDtcOption?.desc,
      statsDate: dateParam,
      time: time,
      odometer: 0,
    };
    try {
      const data = await mainAxiosInstance.post(url, requestBody);
      if (data?.status === 201) {
        getInitialData({
          page: 0,
          vin_Number: vin_Number,
        });
        setIsAddActivityVisible(false);
      }
      setSelectedDtcOption(null);
    } catch (err) {}
    reset();
  };

  const onPrev = () => {
    const prevPage = currentPage - 1;
    if (subSystemCheckedArray?.length + dtcCheckedArray?.length > 0) {
      getFilterDataFromApi({
        page: prevPage,
        subSystems: subSystemCheckedArray,
        dtcCodes: dtcCheckedArray,
        // vinNumber:vin_Number,
      });
      setFilterPageCount((prev) => prev - 1);
    } else {
      if (searchVal) {
        getInitialData({
          page: prevPage,
          searchString: searchVal,
          vin_Number: vin_Number,
        });
        subSystemCheckedArray = [];
        dtcCheckedArray = [];
      } else {
        getInitialData({
          page: prevPage,
          vin_Number: vin_Number,
        });
      }
    }
  };

  const onNext = () => {
    const nextPage = currentPage + 1;
    if (subSystemCheckedArray?.length + dtcCheckedArray?.length > 0) {
      getFilterDataFromApi({
        page: nextPage,
        subSystems: subSystemCheckedArray,
        dtcCodes: dtcCheckedArray,
        // vinNumber:vin_Number,
      });
      setFilterPageCount((prev) => prev + 1);
    } else {
      if (searchVal) {
        getInitialData({
          page: nextPage,
          searchString: searchVal,
        });
        subSystemCheckedArray = [];
        dtcCheckedArray = [];
      } else {
        getInitialData({
          page: nextPage,
          vin_Number: vin_Number,
        });
      }
    }
  };
  const onPageChanged = (val) => {
    const nextPage = val;
    if (subSystemCheckedArray?.length + dtcCheckedArray?.length > 0) {
      getFilterDataFromApi({
        page: nextPage,
        subSystems: subSystemCheckedArray,
        dtcCodes: dtcCheckedArray,
      });
      setFilterPageCount(val);
    } else {
      if (searchVal) {
        getInitialData({
          page: nextPage,
          searchString: searchVal,
          vin_Number: vin_Number,
        });
        subSystemCheckedArray = [];
        dtcCheckedArray = [];
      } else {
        getInitialData({
          page: nextPage,
          vin_Number: vin_Number,
        });
      }
    }
  };

  const onSearchIconClick = () => {
    getInitialData({
      page: 0,
      searchString: searchVal,
      vin_Number: vin_Number,
    });
    subSystemCheckedArray = [];
    dtcCheckedArray = [];
    resetDtcAndSubsystems();
    setSearchText(searchVal);
    setIsSearchingVisible(true);
  };

  return (
    <div
      className="maintenance-log-container page-wrapper"
      style={{ paddingTop: "20px" }}
    >
      <div className="maintenance-log-hdr">
        <div className="maintenance-log-search">
          <div className="search-field">
            <input
              type="text"
              value={searchVal}
              ref={inputRef}
              placeholder="Search for DTC, Sub system"
              onChange={handleSearchChange}
              onKeyPress={handleEnter}
            />
            <img
              className="search-icon"
              onClick={onSearchIconClick}
              style={{ cursor: "pointer" }}
              src={searchIcon}
              alt="search"
            />
          </div>
        </div>
      </div>
      <div className="maintenance-log-filters page-filter">
        <div className="view-maintenance-log-btn">
          <div className="maintenance-help-wrapper">
            <div className="maintenance-help">
              Maintenance Score Card
              <Button
                title="Get Summary"
                handleClick={() =>
                  navigate("/maintenance-log/summary", {
                    replace: true,
                  })
                }
                styleClasses="btn btn-primary"
              />
            </div>
          </div>
        </div>
        {/* {isSearchingVisible && (
          <div className="searching-container">
            <div className="green-container">
              Showing results for {searchText} {dtcCheckedArray.join(" ")}{" "}
              {subSystemCheckedArray.join(" ")}
            </div>
          </div>
        )} */}

        <Filter>
          {subSystems?.length > 0 && (
            <div className="filter-category">Sub Systems</div>
          )}
          {subSystems.slice(0, subSystemVisible)?.map((el) => (
            <Checkbox
              key={el.displayname}
              title={el.displayname}
              handleInputClick={() => onFilter(el)}
              alignProp={{
                borderBottom: "0.5px solid #C4C4C4",
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px 20px",
                alignItems: "center",
                flexDirection: "row-reverse",
              }}
              checkboxStyle={{ marginRight: "10px" }}
              isChecked={el.isChecked}
            />
          ))}
          {subSystemVisible < dtcAndSubsystemsData?.subSystems?.length && (
            <Button
              styleClasses="show-more-btn"
              handleClick={() => setSubSystemVisible((prev) => prev + 5)}
            >
              Show More Subsystems
            </Button>
          )}
          {dtcCodes?.length > 0 && (
            <div className="filter-category">DTC Codes</div>
          )}

          {dtcCodes.slice(0, dtcVisible)?.map((el) => (
            <Checkbox
              key={el.displayname}
              title={el.displayname}
              handleInputClick={() => onFilter(el)}
              alignProp={{
                borderBottom: "0.5px solid #C4C4C4",
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px 20px",
                alignItems: "center",
                flexDirection: "row-reverse",
              }}
              checkboxStyle={{ marginRight: "10px" }}
              isChecked={el.isChecked}
            />
          ))}
          {dtcVisible < dtcAndSubsystemsData?.dtcCodes?.length && (
            <Button
              styleClasses="show-more-btn"
              handleClick={() => setDtcVisible((prev) => prev + 5)}
            >
              Show More Dtc
            </Button>
          )}
        </Filter>
      </div>
      <div>
        {isSearchingVisible && (
          <div className="show-searching-container">
            Showing results for &nbsp;
            {searchText && (
              <span className="green-container">{searchText}</span>
            )}
            {dtcCheckedArray?.map((val, i) => (
              <span className="green-container">{val}</span>
            ))}
            {subSystemCheckedArray?.map((val, i) => (
              <span className="green-container">{val}</span>
            ))}
          </div>
        )}
      </div>
      <div className="maintenance-log-table">
        {initialTableData?.length ? (
          <>
            <>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>DTC</th>
                    <th>Subsystem</th>
                    <th>Maintenance Activity</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {initialTableData?.length &&
                    initialTableData?.map((item) => (
                      <tr key={item?.id}>
                        <td>
                          {item?.statsDate.split("-").reverse().join("/")}
                        </td>
                        <td>{item?.dtcCode}</td>
                        <td>{item?.subSystem}</td>
                        <td>{item?.maintenanceActivity}</td>
                        <td>{item?.description}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <div className="table-buttons-container flex">
                <button
                  className={`btn btn-primary table-buttons ${
                    isPageFirst ? "disabled" : ""
                  }`}
                  disabled={isPageFirst}
                  onClick={() => onPrev()}
                >
                  Prev
                </button>
                <div
                  className="green-container"
                  style={{ height: "27px", lineHeight: "8px" }}
                >
                  <span>Page-{currentPage + 1}</span>
                </div>
                <button
                  className={`btn btn-primary table-buttons ${
                    isPageLast ? "disabled" : ""
                  }`}
                  disabled={isPageLast}
                  onClick={() => onNext()}
                >
                  Next
                </button>
              </div> */}
              {pagenation.totalpages > 1 && (
                <div>
                  <Pagination
                    pageCount={pagenation.totalpages}
                    pageRange={pagenation.size}
                    onPageChanged={onPageChanged}
                  />
                </div>
              )}
            </>
          </>
        ) : (
          <>
            <div className="padding-30">No Data Found</div>
          </>
        )}
      </div>
      <div className="add-activity">
        <Button
          title="Add Activity"
          handleClick={() => setIsAddActivityVisible(true)}
          styleClasses="btn btn-primary add-activity-btn"
        >
          <span>+</span>
        </Button>
      </div>
      {isAddActivityVisible && (
        <Modal
          type="large maintainence-modal"
          title="Add Activity"
          titlePosition="center"
          isError={false}
          handleClose={() => {
            setIsAddActivityVisible(false);
            setSelectedDtcOption(null);
          }}
        >
          <>
            <form onSubmit={handleSubmit(submitActivity)}>
              <div className="flex align-center mb-26">
                <div className="dtc-heading">Title</div>
                <div className="dtc-value">
                  <input
                    className="full-width"
                    type="text"
                    placeholder="Enter title here"
                    {...register("title", {
                      required: "Title is required",
                      minLength: {
                        value: 5,
                        message: "Minimum 5 characters",
                      },
                    })}
                  />
                  {errors.title && (
                    <div style={{ position: "relative" }}>
                      <span className="error-text">
                        {errors.title?.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex align-center mb-26">
                <div className="dtc-heading">Date</div>
                <div className="dtc-value date-field-disabled">
                  <input
                    disabled={true}
                    className="full-width"
                    type="text"
                    placeholder="Enter Date"
                    value={moment(new Date()).format("DD/MM/YYYY")}
                  />
                </div>
              </div>

              <div className="flex align-center mb-26">
                <div className="dtc-heading">DTC</div>
                <div className="dtc-value ">
                  <SelectWithSearch
                    options={dtcSelectOptions}
                    placeholder="Select Dtc"
                    prompt="Select Dtc"
                    value={selectedDtcOption}
                    onChange={(val) => {
                      setSelectedDtcOption(val);
                    }}
                    id="key"
                    label="label"
                    selectedKey="value"
                    selectClassName="maintainence-select"
                  />
                </div>
              </div>
              <div className="flex align-center mb-26">
                <div className="dtc-heading">Subsystem</div>
                <div className="dtc-value">
                  <input
                    className="full-width"
                    type="text"
                    {...register("subSystem", {
                      required: "Subsystem is required",
                      minLength: {
                        value: 4,
                        message: "Minimum 4 characters",
                      },
                    })}
                  />
                  {errors.subSystem && (
                    <div style={{ position: "relative" }}>
                      <span className="error-text">
                        {errors.subSystem?.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex align-center mb-26">
                <div className="dtc-heading">Description</div>
                <div className="dtc-value dtc-desc-disabled">
                  <input
                    className="full-width"
                    disabled={true}
                    type="text"
                    placeholder="Enter Description"
                    value={selectedDtcOption?.desc || ""}
                  />
                </div>
              </div>

              <div className="dtc-button-container">
                <Button
                  title="Close"
                  handleClick={() => {
                    setSelectedDtcOption(null);
                    setIsAddActivityVisible(false);
                  }}
                  styleClasses="btn btn-secondary"
                />
                <Button
                  type="submit"
                  title="Add Activity"
                  styleClasses="btn btn-primary"
                />
              </div>
            </form>
          </>
        </Modal>
      )}
    </div>
  );
};

export default MaintenanceLogs;
