import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getActions } from "./api";
import "./index.scss";

const PrescribedActions = () => {
  const [data, setData] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const dtcCode = useSelector(
    (state) => state.rootCauseReducer.rootCauseAnalysisPayload.dcode
  );
  const getPrescribedActions = async () => {
    const { code, data } = await getActions({
      vinNumber: vinNumber,
      organization: 1,
      statsdate: "2022-06-02",
      dtcCode: dtcCode,
    });

    if (code === 200 && data) {
      setData(data);
    } else if (code === 401) {
      setNotAuthorized(true);
    } else {
      setApiError(true);
    }
  };

  useEffect(() => {
    getPrescribedActions();
  }, []);

  if (notAuthorized) {
    return (
      <div className="page-wrapper">
        <h2>You are not authorized</h2>
      </div>
    );
  }
  if (apiError) {
    return <h2>Something went wrong</h2>;
  }
  if (!data) {
    return <h4>Loading...</h4>;
  }

  return (
    <div className="page-wrapper prescribed_actions">
      <h3 className="sensor">{data.sensor}</h3>
      <div className="actions_table">
        <table>
          <thead>
            <tr>
              <th>Root Cause Probability</th>
              <th>Possible Faults</th>
              <th>Corrective Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data.prescribedActions) &&
              data.prescribedActions.length &&
              data.prescribedActions.map((action) => (
                <tr>
                  <td style={{ textAlign: "center" }}>
                    {action.rootCauseProbability}%
                  </td>
                  <td>{action.possibleFaults}</td>
                  <td>{action.correctiveActions}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PrescribedActions;
