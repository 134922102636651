import React from "react";
import ReportsIcon from "../assets/reports.svg";
import WarrantyCard from "../components/WarrantyCard";
import "../WarrantyManagement.scss";

const WarrantyRate = () => {
  return (
    <div className="warranty-rate page-wrapper">
      <div className="first-container">
        <WarrantyCard
          img={ReportsIcon}
          backgroundColor="#F4E6E7"
          text="Current Warranty"
        />
        <WarrantyCard
          img={ReportsIcon}
          backgroundColor="#E3F5E3"
          text="Projected Warranty"
        />
      </div>
    </div>
  );
};

export default WarrantyRate;
