import React from "react";

const RightVehicleInformation = ({ vehicleInfo }) => {
  return (
    <div className="right-container">
      <div className="img-container">
        <img src={vehicleInfo?.imageUrl} alt="Ford Focus" width="80%" />
      </div>
      <div className="text-container">
        <p className="italic">{vehicleInfo?.banner}</p>
      </div>
    </div>
  );
};

export default RightVehicleInformation;
