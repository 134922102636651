import close from "../../../assets/circle_cross.svg";
import "./valueChip.scss";

const ValueChip = (props) => {

    const {text, handleRemove, showClose = true} = props;

    return (
        <div className="value-chip-wrapper">
            <div className="value-chip-text">{text}</div>
            <div className="value-chip-icon" onClick={handleRemove}>
                {showClose && <img src={close} alt="close" />}
            </div>
        </div>
    )
}

export default ValueChip;