import React from "react";
import { useState } from "react";
import ReactPlayer from "react-player";
import Modal from "../../../../components/common/Modal/Modal";

const ServiceCard = ({ data, onClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      {data && !isModalOpen ? (
        <>
          <div className="service_card_container" onClick={() => onClick(data)}>
            <div className="image_container">
              <img src={data?.imageUrl} alt="" />
            </div>
            <div className="text_container">
              <p className="short_desc_text">{data?.shortDescription}</p>
            </div>
          </div>
        </>
      ) : (
        <>Hello</>
      )}
    </>
  );
};

export default ServiceCard;
