import React from "react";
import { useNavigate } from "react-router";
import logo from "../../assets/logo.png";
import "./header.scss";

const HeaderLogo = () => {
  const navigate = useNavigate();
  return (
    <div className="header-logo" onClick={() => navigate("/dashboard")}>
      <img src={logo} alt="logo"></img>
    </div>
  );
};

export default HeaderLogo;
