import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";

const FaultAnalytics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/fault-analytics") {
      navigate("/fault-analytics", { replace: true });
    }
  }, [location.pathname]);

  return (
    <div className="full-width ">
      <Outlet />
    </div>
  );
};

export default FaultAnalytics;
