import React, { useRef, useState } from 'react';
import useCloseOnClickOutside from '../../../hooks/useCloseOnClickOutside';
import './SelectWithSearch.scss';
import { ReactComponent as SearchIcon } from '../../../assets/search-black.svg';

const SelectWithSearch = ({
  options,
  label,
  value,
  id,
  onChange,
  placeholder,
  selectedKey,
  selectClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  let selectRef = useCloseOnClickOutside(() => {
    setIsOpen(false);
  });

  const [query, setQuery] = useState('');

  const filter = (options) => {
    if (options) {
      return options.filter((option) => {
        return option?.[label].toLowerCase().includes(query.toLowerCase());
      });
    } else {
      return [];
    }
  };

  const displayValue = () => {
    if (query.length > 0) return query;
    if (value) return value[selectedKey];
    return '';
  };
  const displayValueOnly = () => {
    if (value) return value[selectedKey];
    return '';
  };

  return (
    <div className={`dropdown relative ${selectClassName}`} ref={selectRef}>
      <div
        className='control'
        style={{ cursor: 'pointer' }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className='selected-value cursor-pointer text-left'>
          {displayValueOnly() || 'Select Dtc'}
        </div>

        <div className={`arrow ${isOpen ? 'open' : null}`}></div>
      </div>
      <div className={`options ${isOpen ? 'open' : null}`}>
        <div className='search-container'>
          <input
            type='text'
            placeholder={value ? value[label] : 'Search Dtc'}
            value={displayValue()}
            ref={inputRef}
            onChange={(e) => {
              setQuery(e.target.value);
              setIsOpen(true);
              onChange(null);
            }}
            onMouseDown={() => setIsOpen(true)}
          />
          <SearchIcon className='select-search-icon' />
        </div>
        {filter(options)?.map((option, index) => (
          <div
            key={option[id]}
            className={`option cursor-pointer ${
              value?.[label] === option?.label ? 'selected' : null
            }`}
            onClick={() => {
              onChange(option);
              setIsOpen(false);
              setQuery('');
            }}
          >
            {option?.[label]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectWithSearch;
