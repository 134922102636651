import React from "react";
import "./WarrantyCard.scss";

const WarrantyCard = ({
  img,
  backgroundColor,
  text,
  containerStyles,
  secondText,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "197px",
        height: "139px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: backgroundColor,
        borderRadius: "10px",
        textAlign: "center",
        padding: "8px",

        ...containerStyles,
      }}
    >
      <img src={img} alt="wa" />
      <h3 style={{ fontWeight: 400, fontSize: "16px" }}>
        {text}
        <br />
        {secondText}
      </h3>
    </div>
  );
};

export default WarrantyCard;
