import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../../assets/right-arrow-long.svg";

const GasPedalPositionWidget = ({ height, width, gasPedalData }) => {
  const [data, setData] = useState(null);
  const [gasPedalArray, setGasPedalArray] = useState(null);
  const [xAxisTime, setXAxisTime] = useState(null);
  const [gasPedalPosition, setGasPedalPosition] = useState(null);
  useEffect(() => {
    setData(gasPedalData);
    // We are slicing the data upto 1000 points b.c highcharts allow dataset upto 1000 points in normal config
    if (data) {
      const gasPedalArray = data
        ?.splice(0, 1000)
        ?.map((item) => [
          item?.dataRecordTime,
          String(item?.gasPedalPositionSensor).includes("%")
            ? parseFloat(item?.gasPedalPositionSensor) / 1
            : parseFloat(item?.gasPedalPositionSensor),
        ]);
      setGasPedalArray(gasPedalArray);
      const time = data?.splice(0, 1000)?.map((item) => item?.dataRecordTime);
      setXAxisTime(time);
      const gasPedalPosition = data
        ?.splice(0, 1000)
        ?.map((item) => item?.gasPedalPositionSensor);
      setGasPedalPosition(gasPedalPosition);
    }
  }, [gasPedalData, data]);

  const options = {
    chart: {
      type: "areaspline",
      // zoomType: "x",
      height: height || "250px",
      width: width || "100%",
      backgroundColor: null,
      spacing: [10, 10, 10, 20],
    },
    tooltip: {
      backgroundColor: "#FCF1CC",
      borderColor: "none",
      shared: true,
      useHTML: true,
      headerFormat: "Time: {point.key}",
      pointFormat: "<div>Position: {point.y}</div>",
      footerFormat: "",
      padding: 4,
    },

    title: {
      text: "Gas Pedal Position",
      align: "center",
      verticalAlign: "bottom",
      floating: true,
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      type: "category",
      labels: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text: `
        <div style="margin-right:24px;  font-size: 11px;">
          Time
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      visible: true,
      lineWidth: 1,
      lineColor: "#000000",
      gridLineWidth: 0,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
        <div style="position: absolute;
          top: 5px; !important; 
          left: -142px; !important;
          font-size: 11px;
          ">
          Gas Pedal Position
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      visible: true,
      lineWidth: 1,
      lineColor: "#000000",
      gridLineWidth: 0,
    },
    plotOptions: {
      areaspline: {
        lineColor: "#56A64B",
        fillColor: "#F4FFF4",
        width: 1,
      },
      series: {
        marker: {
          enabled: false,
        },
        lineWidth: 1,
      },
    },
    series: [
      {
        data: gasPedalArray,
        showInLegend: false,
        name: "GPP",
        color: "#56A64B",
      },
    ],
  };
  return (
    <div style={{ background: "#F2F7FD" }}>
      {Array.isArray(gasPedalArray) && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default GasPedalPositionWidget;
