export const buttonStrings = [
  {
    text: "Engine",
    id: "engine",
    link: "",
    modalContent: {
      modalButtons: [
        {
          text: "Combustion",
          id: "combustion",
          disable: false,
        },
        {
          text: "Piston Slap​​",
          id: "piston_slap​​",
          disable: true,
        },
        {
          text: "Crankshaft​",
          id: "crankshaft​",
          disable: true,
        },
        {
          text: "Bearing Noise​",
          id: "bearing_noise​",
          disable: true,
        },
        {
          text: "Valve Train​",
          id: "valve_train​",
          disable: true,
        },
        {
          text: "Pully Slipping Noise​",
          id: "pully_slipping_noise​",
          disable: true,
        },
        {
          text: "Coolant Pump​",
          id: "coolant_pump​",
          disable: true,
        },
      ],
    },
  },
  {
    text: "Tire and Wheel Alignment​",
    id: "tire_and_wheel_alignment​",
    link: "",
    modalContent: {
      modalButtons: [
        {
          text: "Combustion Tire",
          id: "combustion_tire",
        },
        {
          text: "Piston Slap​​",
          id: "piston_slap​​",
        },
        {
          text: "Crankshaft​",
          id: "crankshaft​",
        },
        {
          text: "Bearing Noise​",
          id: "bearing_noise​",
        },
        {
          text: "Valve Train​",
          id: "valve_train​",
        },
        {
          text: "Pully Slipping Noise​",
          id: "pully_slipping_noise​",
        },
        {
          text: "Coolant Pump​",
          id: "coolant_pump​",
        },
      ],
    },
  },
  {
    text: "Transmission​",
    id: "transmission​",
    link: "",
    modalContent: {
      modalButtons: [
        {
          text: "Combustion",
          id: "combustion",
        },
        {
          text: "Piston Slap​​",
          id: "piston_slap​​",
        },
        {
          text: "Crankshaft​",
          id: "crankshaft​",
        },
        {
          text: "Bearing Noise​",
          id: "bearing_noise​",
        },
        {
          text: "Valve Train​",
          id: "valve_train​",
        },
        {
          text: "Pully Slipping Noise​",
          id: "pully_slipping_noise​",
        },
        {
          text: "Coolant Pump​",
          id: "coolant_pump​",
        },
      ],
    },
  },
  {
    text: "Suspension",
    id: "suspension",
    link: "",
    modalContent: {
      modalButtons: [
        {
          text: "Combustion",
          id: "combustion",
        },
        {
          text: "Piston Slap​​",
          id: "piston_slap​​",
        },
        {
          text: "Crankshaft​",
          id: "crankshaft​",
        },
        {
          text: "Bearing Noise​",
          id: "bearing_noise​",
        },
        {
          text: "Valve Train​",
          id: "valve_train​",
        },
        {
          text: "Pully Slipping Noise​",
          id: "pully_slipping_noise​",
        },
        {
          text: "Coolant Pump​",
          id: "coolant_pump​",
        },
      ],
    },
  },
  {
    text: "Driveline",
    id: "driveline",
    link: "",
    modalContent: {
      modalButtons: [
        {
          text: "Combustion",
          id: "combustion",
        },
        {
          text: "Piston Slap​​",
          id: "piston_slap​​",
        },
        {
          text: "Crankshaft​",
          id: "crankshaft​",
        },
        {
          text: "Bearing Noise​",
          id: "bearing_noise​",
        },
        {
          text: "Valve Train​",
          id: "valve_train​",
        },
        {
          text: "Pully Slipping Noise​",
          id: "pully_slipping_noise​",
        },
        {
          text: "Coolant Pump​",
          id: "coolant_pump​",
        },
      ],
    },
  },
];
