import React from 'react';

const OneColumn = ({ className, children }) => {
  return (
    <div className='page-wrapper'>
      <div className={className}>{children}</div>
    </div>
  );
};

export default OneColumn;
