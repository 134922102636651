import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { mainAxiosInstance } from "../../../services/service";
import { PictorialRepresentationApi } from "../../../services/service-constants";

const IdealBehaviourPieChart = () => {
  const [driverBehaviourSummaryData, setDriverBehaviourSummaryData] = useState(
    []
  );
  const [pieChartData, setPieChartData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(true);
  const pieColors = {
    purple: "#C69DE0",
    red: "#ED7173",
    green: "#C0EC92",
    orange: "#FFA285",
    "light blue": "#B4CCF8",
    pink: "#FEB3B3",
    blue: "#4886E7",
    yellow: "#FEA443",
  };
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      renderTo: "container",
      width: 600,
      height: 600,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      events: {
        load: function () {
          var allY = 0,
            angle1 = 0,
            angle2 = 0,
            angle3 = 0;
          var series = this.series[0];

          series.points.forEach(function (p, i) {
            allY += p.y;
          });
          series.points.forEach(function (p, i) {
            angle2 = angle1 + (p.y * 360) / allY;
            angle3 = angle2 - (p.y * 360) / (2 * allY);

            if (angle3 >= 180) {
              p.update({ dataLabels: { rotation: 90 + angle3 } });
            } else {
              p.update({ dataLabels: { rotation: -90 + angle3 } });
            }
            angle1 = angle2;
          });
          var allY2 = 0,
            angle12 = 0,
            angle22 = 0,
            angle32 = 0;
          this.series[1].points.forEach(function (p, i) {
            allY2 += p.y;
          });
          this.series[1].points.forEach(function (p, i) {
            angle22 = angle12 + (p.y * 360) / allY2;
            angle32 = angle22 - (p.y * 360) / (2 * allY2);

            if (angle32 >= 180) {
              p.update({
                dataLabels: { enabled: true, rotation: 90 + angle32 },
              });
            } else {
              p.update({
                dataLabels: { enabled: true, rotation: -90 + angle32 },
              });
            }
            angle12 = angle22;
          });
        },
      },
    },
    title: {
      text: "Ideal Driver Behaviour",
      style: {
        fontSize: 16,
        fontWeight: "bold",
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      series: {
        enableMouseTracking: false,
      },
      pie: {
        allowPointSelect: true,
        cursor: "default",
        data: pieChartData,
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        size: 500,
        stacking: "normal",
        slicedOffset: 0,
        dataLabels: {
          style: {
            textOverflow: "clip",
            fontSize: "9px",
            textOutline: false,
          },
          color: "black",
          distance: -110,
          y: 12,
          formatter: function () {
            if (this.percentage !== 0) return this.point.name;
          },
        },
      },
      {
        animation: false,
        type: "pie",
        name: "",
        size: 500,
        slicedOffset: 0,
        dataLabels: {
          enabled: false,
          connectorColor: "white",
          color: "black",
          softConnector: false,
          connectorWidth: 0,
          distance: 10,
          y: 10,
          formatter: function () {
            if (this.percentage !== 0) return Math.round(this.percentage) + "%";
          },
        },
      },
    ],
  };
  const getPageData = () => {
    let url = "";
    let params = {};
    url = `${PictorialRepresentationApi}`;
    params = {
      vin_number: "DH34ASD7SDFF84742",
      organization_id: 1,
    };

    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        setDriverBehaviourSummaryData(response?.data);
      })
      .catch((er) => {
        setNoDataFound(true);
        throw er;
      });
  };
  const getPieChartData = () => {
    let chartdata = [];
    driverBehaviourSummaryData?.pictorialRepresentation?.map((item) => {
      let data = {
        name: item.paramName,
        y: item.actualScore,
        color: pieColors[item.pieColor.toLowerCase()],
        dataLabels: {
          rotation: 0,
        },
      };
      chartdata.push(data);
    });
    setPieChartData(chartdata);
    setNoDataFound(false);
  };
  useEffect(() => {
    getPageData();
  }, []);
  useEffect(() => {
    if (driverBehaviourSummaryData?.pictorialRepresentation?.length > 0) {
      getPieChartData();
    }
  }, [driverBehaviourSummaryData]);
  return (
    <div className="driver-behaviour-summary-wrapper">
      <div className="summary-driver-behaviour">
        Driver Behaviour score is {driverBehaviourSummaryData?.driverScore}%
        (Good)
      </div>
      <>
        <div className="ideal_behavior_container">
          {!noDataFound && (
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"chart"}
              options={options}
            />
          )}
        </div>
        <div className="">
          {noDataFound && (
            <div className="no-data">
              Sorry, We could not find any data for the given search criteria.
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default IdealBehaviourPieChart;
