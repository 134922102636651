import { ReactComponent as SomethingWentWrongIcon } from "./assets/went-wrong.svg";

const Action = ({ label = "Go Back", ...rest }) => {
  return (
    <button
      size="small"
      variant="contained"
      color="primary"
      sx={{ mt: 2 }}
      {...rest}
    >
      {label}
    </button>
  );
};

const Feedback = ({
  title = "Something went wrong.",
  subtitle,
  children,
  action,
  illustration,
  illustrationHeight,
}) => {
  return (
    <div className="page-wrapper" style={{ marginTop: "30px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          flexDirection: "column",
          height: "600px",
          width: "100%",
          paddingBlock: "60px",
        }}
      >
        {illustration || <SomethingWentWrongIcon />}
        <h2>{title}</h2>
        {subtitle && <p>{subtitle}</p>}
        {action && <Action {...action} />}
        {children && <div>{children}</div>}
      </div>
    </div>
  );
};

export default Feedback;
