import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "../../components/common/Button/Button";
import { mainAxiosInstance } from "../../services/service";
import { ServiceConfig } from "../../services/service-constants";
import { addRootCausePayload } from "../../store/rootCauseAnalysis/actions";
import "./index.scss";

const RootCauseAnalysis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let rcaPayload = useSelector(
    (state) => state.rootCauseReducer.rootCauseAnalysisPayload
  );
  if (rcaPayload && rcaPayload !== {}) {
    window.localStorage.setItem(
      "root-cause-payload",
      JSON.stringify(rcaPayload)
    );
  } else {
    rcaPayload = JSON.parse(
      window.localStorage.getItem("root-cause-payload") || {}
    );
    dispatch(addRootCausePayload({ ...rcaPayload }));
  }
  const [RCAdata, setRCAdata] = useState(null);
  const [noDataFound, setNoDataFound] = useState(true);

  const getRCAdata = (vin, oId, dcode, stats) => {
    let url = `${ServiceConfig.mod_root_cause_analysis}`;
    let params = {
      vin_number: vin,
      organization_id: oId,
      stats_date: stats,
      dtc_code: dcode,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        setRCAdata(response.data);
        setNoDataFound(false);
      })
      .catch((er) => {
        setNoDataFound(true);
        throw er;
      });
  };
  useEffect(() => {
    if (
      rcaPayload.vin &&
      rcaPayload.oId &&
      rcaPayload.dcode &&
      rcaPayload.stats
    ) {
      getRCAdata(
        rcaPayload.vin,
        rcaPayload.oId,
        rcaPayload.dcode,
        rcaPayload.stats
      );
    }
  }, []);

  const goToRootCauseData = () => {
    navigate("./root-cause-data", { relative: "route" });
  };

  const goToPrescribedActions = () => {
    navigate("./prescribed-actions", { relative: "route" });
  };

  return (
    <>
      {!noDataFound && (
        <div className="rca__container">
          <div className="rca--title">
            <p>
              Root Cause Identified - Air Leak detected at MAF Sensor Upstream
            </p>
          </div>
          <div className="rca--content">
            {RCAdata.map((rca, index) => {
              return (
                <div className="key-value-holder" id={rca.id} key={rca.id}>
                  <div className="key-name">
                    {rca.paramName}
                    <span>*</span>
                  </div>
                  <div className="value-details">
                    {rca.paramValue ? rca.paramValue : 0}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="rca--footer">
            <Button
              title="Prescribed Actions​"
              handleClick={() => {
                goToPrescribedActions();
              }}
              styleClasses={"btn btn-secondary"}
            />
            <Button
              title="Root Cause Data​"
              handleClick={() => goToRootCauseData()}
              styleClasses={"btn btn-primary"}
            />
          </div>
        </div>
      )}
      {noDataFound && (
        <div className="no-data">Sorry, We could not find any data.</div>
      )}
    </>
  );
};

export default RootCauseAnalysis;
