import moment from "moment";
import { mainAxiosInstance } from "../../../services/service";
import { ServiceConfig } from "../../../services/service-constants";

export const getDtcListData = async (payload) => {
  const url = `${ServiceConfig.dtc_list}`;
  const { vinNumber } = payload;

  //   TODO : dynamic vin_number and org_id required
  const params = {
    vin_number: vinNumber,
    organization_id: 1,
  };
  return mainAxiosInstance
    .get(url, { params: params })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((err) => {
      // return err?.message || 'Something went wrong';
      Promise.reject();
    });
};

export const getDtcListAndSubsystemsData = async (payload) => {
  const { vinNumber } = payload;
  const url = `${ServiceConfig.maintenance_logs_filter}`;

  //   TODO : dynamic vin_number and org_id required
  const params = {
    vin_number: vinNumber,
    organization_id: 1,
  };
  return mainAxiosInstance
    .get(url, { params: params })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((err) => {
      return err?.message || "Something went wrong";
    });
};

export const getInitialTableData = async (payload) => {
  const { page, searchString, organizationId, vin_Number } = payload;

  let url = `${ServiceConfig.maintenance_logs_search}`;
  const reqBody = {
    organizationId: organizationId || 1,
    vinNumber: vin_Number,
    searchString: searchString || "",
  };
  const params = {
    page: page,
    size: 10,
  };

  return mainAxiosInstance
    .post(url, reqBody, {
      params: params,
    })
    .then((response) => {
      if (response.status === 200 || response.status === 301) {
        return response?.data;
      }
    })
    .catch((err) => {
      return err?.message || "Something went wrong";
    });
};

export const getFilterData = async (payload) => {
  const { vinNumber, organizationId, page, size, subSystems, dtcCodes } =
    payload;

  const url = ServiceConfig.get_maintenance_logs_from_filter;
  const reqBody = {
    vinNumber: vinNumber,
    organizationId: organizationId || 1,
    subSystems: subSystems,
    dtcCodes: dtcCodes,
  };
  const params = {
    page: page,
    size: size || 10,
  };

  return mainAxiosInstance
    .post(url, reqBody, {
      params: params,
    })
    .then((response) => {
      if (response.status === 200 || response.status === 301) {
        return response?.data;
      }
    })
    .catch((err) => {
      return err?.message || "Something went wrong";
    });
};

export const getMaintenanceScoreTableData = async (payload) => {
  const { page, url, organizationId, vinNumber } = payload;
  const params = {
    organization_id: organizationId || 1,
    vin_number: vinNumber,
    page: page,
    size: 10,
  };

  return mainAxiosInstance
    .get(url, {
      params: params,
    })
    .then((response) => {
      if (response.status === 200 || response.status === 301) {
        return response?.data;
      }
    })
    .catch((err) => {
      return err?.message || "Something went wrong";
    });
};

export const getGraphicalData = async (payload) => {
  const { url, organizationId, vinNumber, statsDate, graphName } = payload;
  const params = {
    organization_id: organizationId || 1,
    vin_number: vinNumber,
    stats_date: statsDate || moment(new Date()).format("YYYY-MM-DD"),
    graph_name: graphName || "",
  };

  return mainAxiosInstance
    .get(url, {
      params: params,
    })
    .then((response) => {
      if (response.status === 200 || response.status === 301) {
        return response?.data;
      }
    })
    .catch((err) => {
      return err?.message || "Something went wrong";
    });
};
