import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../assets/right-arrow-long.svg";
import LengendContainer from "./LengendContainer";
import { useSelector } from "react-redux";
import { colorMap } from "../utils/constants";
import HC_Regression from "highcharts-regression";
import NoDataFound from "./NoDataFound";

// usage of highcharts-regression
HC_Regression(Highcharts);

const HoursSpentRpm = ({ data }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const [chartData, setChartData] = useState(null);
  const [hoursAverageArray, setHoursAverageArray] = useState(null);
  const [hoursTargetArray, setHoursTargetArray] = useState(null);
  const [chartInfo, setChartInfo] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setChartData(data?.hoursSpentOnRpmBand);
    if (data && data?.hoursSpentOnRpmBand.length > 0) {
      const hoursAverageArray = data.hoursSpentOnRpmBand.map((item) => [
        item.rpmRange,
        item.hoursAverage,
      ]);
      const hoursTargetArray = data.hoursSpentOnRpmBand.map((item) => [
        item.rpmRange,
        item.hoursTarget,
      ]);
      setChartInfo(data?.information);
      setHoursAverageArray(hoursAverageArray);
      setHoursTargetArray(hoursTargetArray);
      const month = moment(data?.hoursSpentOnRpmBand[0].statsDate).format(
        "MMMM"
      );
      const year = moment(data?.hoursSpentOnRpmBand[0].statsDate).format(
        "YYYY"
      );
      setMonth(month);
      setYear(year);
    } else {
      setHoursAverageArray(null);
      setHoursTargetArray(null);
    }
  }, [data]);

  const options = {
    chart: {
      type: "column",
      backgroundColor: "#F1F3F5",
      spacing: [50, 50, 30, 20],
      height: 350,
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
       <div style="font-size: 11px; left:-160px;top:4px;position:absolute;">
         Hours Spent on RPM Band
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      min: 0,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
      gridLineColor: "#ffffff",
    },
    xAxis: {
      type: "category",
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-100px;top:4px;position:absolute;">
           (RPM * 100)
        </div>
        `,
      },
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
      },
      line: {
        dashStyle: "Dash",
      },
    },
    tooltip: {
      style: {
        color: "#353536",
        font: "12px Open Sans",
      },
    },
    series: [
      {
        data: hoursAverageArray,
        showInLegend: false,
        name: "Mpg Average Hours",
        color: chartInfo && colorMap[chartInfo[0]?.color],
      },
      {
        data: hoursTargetArray,
        showInLegend: false,
        name: "Mpg Target Hours",
        color: chartInfo && colorMap[chartInfo[1]?.color],
      },
    ],
  };

  return (
    <div key={hoursAverageArray}>
      <div key={isSidemenuExpanded}>
        {Array.isArray(hoursAverageArray) &&
        Array.isArray(hoursTargetArray) &&
        hoursTargetArray.length > 0 ? (
          <>
            <LengendContainer chartInfo={chartInfo} />
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              updateArgs={[true, true, true]}
            />
            <div
              style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}
            >
              <p style={{ fontSize: "12px", fontWeight: "600" }}>
                Hours Spent on RPM band
              </p>
            </div>
          </>
        ) : (
          <>
            <NoDataFound chartName=" Hours Spent on RPM band" />
          </>
        )}
      </div>
    </div>
  );
};

export default HoursSpentRpm;
