/**
 * from : link generated by useBreadcrumb
 * to : link we want
 */

export const linkChangeObjects = [
  {
    from: "/vehicle-health/diagnostics",
    to: "/vehicle-health/diagnostics/on-board",
  },
  {
    from: "/vehicle-health",
    to: "/vehicle-health/diagnostics/on-board",
  },
  {
    from: "/vehicle-health/digital-twin",
    to: "/vehicle-health/digital-twin/performance-digital-twin",
  },
  {
    from: "/maintenance-overview",
    to: "/maintenance-overview/routine-maintenance",
  },
  {
    from: "/vehicle-overview",
    to: "/vehicle-overview/information",
  },
  {
    from: "/warranty-management/diagnostics",
    to: "/warranty-management/diagnostics/top-10-dtc-complaints",
  },
  {
    from: "/warranty-management/prognostics",
    to: "/warranty-management/prognostics/top-10-dtc-complaints",
  },
  {
    from: "/maintenance-overview/diagnostics/service-guide",
    to: "/maintenance-overview/diagnostics/service-guide/architecture",
  },
  {
    from: "maintenance-overview/prognostics/service-guide",
    to: "/maintenance-overview/prognostics/service-guide/architecture",
  },
];
