import { useEffect } from "react";
import Highcharts from "highcharts";
import arrowYAxis from "../../../assets/arrow-yaxis.svg";
import arrowXAxis from "../../../assets/arrow-xaxis.svg";
import "./index.scss";
// import { engineSpeedMile, engineSpeedLastTrip } from "../../../_mock/vehicle-usage-profile/engineSpeed";

export const LineGraphWithThreshold = (data) => {
  const { dataSolid, dataDotted, title, xLabel, yLabel } = data.data;
  useEffect(() => {
    renderChart(dataSolid, dataDotted, title, xLabel, yLabel);
  }, [dataSolid, dataDotted]);

  const renderChart = (dataSolid, dataDotted, title, xLabel, yLabel) => {
    Highcharts.chart(
      "container",
      {
        chart: {
          backgroundColor: "#c9ced740",
          spacing: [20, 20, 20, 20],
          height: 350,
        },
        title: {
          text: title,
          align: "center",
          verticalAlign: "bottom",
        },

        yAxis: {
          title: {
            text: `<div><img src='${arrowYAxis}'/><div>${yLabel}</div></div>`,
            style: {
              color: "#000000",
            },
            align: "high",
            useHTML: true,
          },
          gridLineColor: "#ffffff",
        },
        xAxis: {
          title: {
            text: `<div><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
            style: {
              color: "#000000",
            },
            align: "high",
            useHTML: true,
          },
        },

        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        // plotOptions: {
        //     series: {
        //         color: '#000000'
        //     }
        // },
        series: [
          {
            data: dataSolid,
            dashStyle: "Solid",
            color: "#56A64B",
          },
          {
            data: dataDotted,
            dashStyle: "ShortDash",
            color: "#56A64B",
          },
        ],
      },
      function (chart) {
        // on complete

        if (chart.series?.[0]?.data?.length < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 125)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure full-width">
      <div id="container" className="full-width"></div>
    </figure>
  );
};
