import React, { useEffect, useState } from "react";
import { getServiceGuideData } from "../api";
import DtcHeading from "../components/DtcHeading";
import LargeContent from "../components/LargeContent";
import ServiceCard from "../components/ServiceCard";

const UserManualServiceGuide = () => {
  const [data, setData] = useState(null);
  const [isContentLarge, setIsContentLarge] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const getServiceGuide = async (payload) => {
    const data = await getServiceGuideData(payload);
    setData(data);
  };
  useEffect(() => {
    getServiceGuide({ serviceType: "User Manual" });
  }, []);
  return (
    <div className="page-wrapper serviceguide serviceguide_usermanual">
      {data ? (
        <>
          {!isContentLarge ? (
            <>
              <DtcHeading />
              <div className="content_container">
                {data?.map((item, index) => (
                  <div key={index}>
                    <ServiceCard
                      data={item}
                      onClick={(data) => {
                        setIsContentLarge(true);
                        setSelectedCard(data);
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <LargeContent
                selectedCard={selectedCard}
                onClose={() => setIsContentLarge(false)}
                data={data}
                onServiceCardClick={(singleData) => {
                  setIsContentLarge(true);
                  setSelectedCard(singleData);
                }}
              />
            </>
          )}
        </>
      ) : (
        <>
          <>No Data found</>
        </>
      )}
    </div>
  );
};

export default UserManualServiceGuide;
