import React, { useEffect, useState } from "react";
import { maintenanceLogEndPoints } from "../../../../services/service-constants";
import { getMaintenanceScoreTableData, getGraphicalData } from "../../api";
import Pagination from "../../../../components/common/Pagination";
import { useDispatch, useSelector } from "react-redux";

import "./index.scss";
import { LineGraphWithThreshold } from "../../../../components/common/LineGraph/LineGraphWiththreshold";
import Modal from "../../../../components/common/Modal/Modal";

const MaintenanceOnDemandLogs = () => {
  const [initialTableData, setInitialTableData] = useState(null);
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  const [initialPrognosticsTableData, setInitialPrognosticsTableData] =
    useState(null);

  const [initialDiagnosticsTableData, setInitialDiagnosticsTableData] =
    useState(null);

  const [currentPage, setCurrentPage] = useState();

  const [isPageFirst, setIsPageFirst] = useState(false);

  const [isPageLast, setIsPageLast] = useState(false);

  const [currentPagePrognostics, setCurrentPagePrognostics] = useState();

  const [isPageFirstPrognostics, setIsPageFirstPrognostics] = useState(false);

  const [isPageLastPrognostics, setIsPageLastPrognostics] = useState(false);

  const [currentPageDiagnostics, setCurrentPageDiagnostics] = useState();

  const [isPageFirstDiagnostics, setIsPageFirstDiagnostics] = useState(false);

  const [isPageLastDiagnostics, setIsPageLastDiagnostics] = useState(false);

  const [selectedGraphData, setSelectedGraphData] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [pagenation, setPagenation] = useState({
    totalpages: 0,
    size: 0,
    currentPageNo: 0,
  });

  const [pagenationDiagnostics, setPagenationDiagnostics] = useState({
    totalpages: 0,
    size: 0,
    currentPageNo: 0,
  });

  const [pagenationPrognostics, setPagenationPrognostics] = useState({
    totalpages: 0,
    size: 0,
    currentPageNo: 0,
  });

  const onPageChangedUsageBased = (val) => {
    const nextPage = val;
    getInitialUsageBasedMaintenanceData({
      page: nextPage,
      // vinNumber:vin_Number
    });
  };

  const onPageChangedDiagnosticsBased = (val) => {
    const nextPage = val;
    getInitialDiagnosticBasedMaintenanceData({
      page: nextPage,
    });
  };

  const onPageChangedPrognosticsBased = (val) => {
    const nextPage = val;
    getInitialPrognosticBasedMaintenanceData({
      page: nextPage,
    });
  };

  const getSelectedGraphData = (data) => {
    const returnData = {};
    const maintenanceLogsGraphs = data.maintenanceLogsGraphs;
    if (maintenanceLogsGraphs.length) {
      returnData["id"] = maintenanceLogsGraphs[0].graphName;
      returnData["xLabel"] = maintenanceLogsGraphs[0].xAxisName;
      returnData["yLabel"] = maintenanceLogsGraphs[0].yAxisName;
      const mainGraphData = maintenanceLogsGraphs.reduce((acc, el) => {
        acc.push({ x: el.xAxisData, y: el.yAxisData });
        return acc;
      }, []);
      returnData["dataSolid"] = mainGraphData;
    }
    const maintenanceLogsGraphsThresholds =
      data.maintenanceLogsGraphsThresholds;
    if (maintenanceLogsGraphsThresholds) {
      const extendedGraphData = [
        {
          x: maintenanceLogsGraphsThresholds.thresholdStart[0],
          y: maintenanceLogsGraphsThresholds.thresholdStart[1],
        },
        {
          x: maintenanceLogsGraphsThresholds.thresholdEnd[0],
          y: maintenanceLogsGraphsThresholds.thresholdEnd[1],
        },
      ];
      returnData["dataDotted"] = extendedGraphData;
      returnData["title"] = maintenanceLogsGraphsThresholds.graphLabel;
    }

    return returnData;
  };

  const getGraphData = async (item, vin_Number) => {
    const data = await getGraphicalData({
      url: `${maintenanceLogEndPoints.graph}`,
      statsDate: item.statsDate,
      graphName: item.graphName,
      vinNumber: vin_Number,
    });
    if (data) {
      const selectGraphData = getSelectedGraphData(data);
      setSelectedGraphData(selectGraphData);
      setShowModal(true);
    }
  };

  const getInitialUsageBasedMaintenanceData = async (payload) => {
    const { page, vinNumber } = payload;
    const data = await getMaintenanceScoreTableData({
      page: page,
      vinNumber: vinNumber,
      url: `${maintenanceLogEndPoints.usage_based_maintenance}`,
    });
    if (data) {
      setInitialTableData(data?.content);
      setCurrentPage(data?.pageable?.pageNumber);
      setIsPageFirst(data?.first);
      setIsPageLast(data?.last);
      setPagenation({
        totalpages: data?.totalPages,
        size: data?.size,
        currentPageNo: data?.pageable?.pageNumber,
      });
    }
  };

  const getInitialDiagnosticBasedMaintenanceData = async (payload) => {
    const { page, vinNumber } = payload;
    const data = await getMaintenanceScoreTableData({
      page: page,
      url: `${maintenanceLogEndPoints.diagnostic_based_maintenance}`,
      vinNumber: vinNumber,
    });
    if (data) {
      setInitialDiagnosticsTableData(data?.content);
      setCurrentPageDiagnostics(data?.pageable?.pageNumber);
      setIsPageFirstDiagnostics(data?.first);
      setIsPageLastDiagnostics(data?.last);
      setPagenationDiagnostics({
        totalpages: data?.totalPages,
        size: data?.size,
        currentPageNo: data?.pageable?.pageNumber,
      });
    }
  };

  const getInitialPrognosticBasedMaintenanceData = async (payload) => {
    const { page, vinNumber } = payload;
    const data = await getMaintenanceScoreTableData({
      page: page,
      url: `${maintenanceLogEndPoints.prognostics_based_maintenance}`,
      vinNumber: vinNumber,
    });
    if (data) {
      setInitialPrognosticsTableData(data?.content);
      setCurrentPagePrognostics(data?.pageable?.pageNumber);
      setIsPageFirstPrognostics(data?.first);
      setIsPageLastPrognostics(data?.last);
      setPagenationPrognostics({
        totalpages: data?.totalPages,
        size: data?.size,
        currentPageNo: data?.pageable?.pageNumber,
      });
    }
  };

  useEffect(() => {
    getInitialUsageBasedMaintenanceData({
      page: 0,
      vinNumber: vin_Number,
    });
    getInitialDiagnosticBasedMaintenanceData({
      page: 0,
      vinNumber: vin_Number,
    });
    getInitialPrognosticBasedMaintenanceData({
      page: 0,
      vinNumber: vin_Number,
    });
  }, []);

  return (
    <>
      <div className="table-title">Usage based Maintenance</div>

      <table className="usage-based-table">
        <thead>
          <tr>
            <th>Maintenance Activity</th>
            <th>Metric</th>
            <th>Scheduled</th>
            <th>Completed</th>
            <th>Violation</th>
            <th>Weighing factor</th>
          </tr>
        </thead>
        <tbody>
          {initialTableData?.length &&
            initialTableData.map((item) => (
              <tr key={item?.id}>
                <td>{item?.maintenanceActivity || "-"}</td>
                <td>{item?.metric || "-"}</td>
                <td>{item?.scheduled || "-"}</td>
                <td>{item?.completed || "-"}</td>
                <td>{item?.violation || "-"}</td>
                <td>
                  {item?.weighnigFactor || "0.0"}{" "}
                  <span
                    className="view-details-from-table"
                    onClick={() => getGraphData(item, vin_Number)}
                  >
                    view details
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {pagenation.totalpages > 1 && (
        <div className="pagination-container">
          <Pagination
            pageCount={pagenation.totalpages}
            pageRange={pagenation.size}
            onPageChanged={onPageChangedUsageBased}
          />
        </div>
      )}

      <div className="table-title">Diagnostic based Maintenance</div>
      <table className="diagnostics-based-table">
        <thead>
          <tr>
            <th>Maintenance Activity</th>
            <th>Metric</th>
            <th>Scheduled</th>
            <th>Completed</th>
            <th>Violation</th>
            <th>Weighing factor</th>
          </tr>
        </thead>
        <tbody>
          {initialDiagnosticsTableData?.length &&
            initialDiagnosticsTableData.map((item) => (
              <tr key={item?.id}>
                <td>{item?.maintenanceActivity || "-"}</td>
                <td>{item?.metric || "-"}</td>
                <td>{item?.scheduled || "-"}</td>
                <td>{item?.completed || "-"}</td>
                <td>{item?.violation || "-"}</td>
                <td>
                  {item?.weighnigFactor || "0.0"}{" "}
                  <span
                    className="view-details-from-table"
                    onClick={() => getGraphData(item, vin_Number)}
                  >
                    view details
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {pagenationDiagnostics.totalpages > 1 && (
        <div className="pagination-container">
          <Pagination
            pageCount={pagenationDiagnostics.totalpages}
            pageRange={pagenationDiagnostics.size}
            onPageChanged={onPageChangedDiagnosticsBased}
          />
        </div>
      )}

      <div className="table-title">Prognostic based Maintenance</div>
      <table className="prognostics-based-table">
        <thead>
          <tr>
            <th>Maintenance Activity</th>
            <th>Metric</th>
            <th>Scheduled</th>
            <th>Completed</th>
            <th>Violation</th>
            <th>Weighing factor</th>
          </tr>
        </thead>
        <tbody>
          {initialPrognosticsTableData?.length &&
            initialPrognosticsTableData.map((item) => (
              <tr key={item?.id}>
                <td>{item?.maintenanceActivity || "-"}</td>
                <td>{item?.metric || "-"}</td>
                <td>{item?.scheduled || "-"}</td>
                <td>{item?.completed || "-"}</td>
                <td>{item?.violation || "-"}</td>
                <td>
                  {item?.weighnigFactor || "0.0"}{" "}
                  <span
                    className="view-details-from-table"
                    onClick={() => getGraphData(item, vin_Number)}
                  >
                    view details
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {pagenationPrognostics.totalpages > 1 && (
        <div className="pagination-container">
          <Pagination
            pageCount={pagenationPrognostics.totalpages}
            pageRange={pagenationPrognostics.size}
            onPageChanged={onPageChangedPrognosticsBased}
          />
        </div>
      )}
      {showModal && (
        <div className="maintenance-log-graph-container">
          <Modal
            type={"large"}
            title={""}
            titlePosition={"center"}
            isError={false}
            handleClose={() => setShowModal(false)}
            wrapperClass="log-graph"
          >
            {selectedGraphData.id && (
              <LineGraphWithThreshold
                data={selectedGraphData}
              ></LineGraphWithThreshold>
            )}
          </Modal>
        </div>
      )}
    </>
  );
};

export default MaintenanceOnDemandLogs;
