import React, { useEffect, useState } from "react";
import { mainAxiosInstance } from "../../../services/service";
import { PictorialRepresentationApi } from "../../../services/service-constants";
import "./index.scss";
import { Button } from "../../../components/common/Button/Button";
import SinglebarCard from "./SinglebarCard";

import { useNavigate } from "react-router";

const PictorialRepresentation = () => {
  const navigate = useNavigate();
  const [driverBehaviourSummaryData, setDriverBehaviourSummaryData] = useState(
    []
  );
  const [noDataFound, setNoDataFound] = useState(true);

  const getPageData = () => {
    let url = "";
    let params = {};
    url = `${PictorialRepresentationApi}`;
    params = {
      vin_number: "DH34ASD7SDFF84742",
      organization_id: 1,
    };

    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        setDriverBehaviourSummaryData(response?.data);
        setNoDataFound(false);
      })
      .catch((er) => {
        setNoDataFound(true);
        throw er;
      });
  };

  const redirectToIdealBehaviour = () => {
    var pageType = {
      pathname:
        "/operational-analytics/driver-behaviour/summary/pictorial-representation/ideal-behaviour",
    };
    navigate(pageType, {
      // state: {
      //   pictorialData: driverBehaviourSummaryData?.pictorialRepresentation,
      //   driverScore: driverBehaviourSummaryData?.driverScore,
      // },
      replace: true,
    });
  };
  useEffect(() => {
    getPageData();
  }, []);

  return (
    <div className="driver-behaviour-summary-wrapper">
      <div className="summary-driver-behaviour">
        Driver Behaviour score is {driverBehaviourSummaryData?.driverScore}%
        (Good)
      </div>
      <>
        {" "}
        <div className="flex page-content-header">
          <div className="page-options-container"></div>
          <div className="service-help-wrapper">
            <div className="service-help">
              Ideal behaviour
              <Button
                title="View"
                handleClick={() => redirectToIdealBehaviour()}
                styleClasses="btn btn-primary view-button"
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="picRepbar-container">
            {!noDataFound &&
              driverBehaviourSummaryData?.pictorialRepresentation?.map(
                (item, i) => {
                  return i <= 6 && <SinglebarCard item={item} />;
                }
              )}
          </div>
          <div className="picRepbar-container">
            {!noDataFound &&
              driverBehaviourSummaryData?.pictorialRepresentation?.map(
                (item, i) => {
                  return i >= 7 && <SinglebarCard item={item} />;
                }
              )}
          </div>
          <div className="picRepbar_footer">
            {!noDataFound && (
              <div className="picRepbar_footer--right">
                <div className="picRepbar_footer--row">
                  <span class="one">Ideal Behavior</span>
                  <span class="two">Good</span>
                </div>

                <span class="three">Average</span>
                <span class="four">Bad</span>
              </div>
            )}
            
          </div>
          {noDataFound && (
            <div className="no-data">
              Sorry, We could not find any data for the given search criteria.
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default PictorialRepresentation;
