import React from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router";
import Breadcrumbs from "../../components/common/Breadcrumb/Breadcrumbs";
import { NavigationMenu } from "../../components/common/NavigationMenu";
import Footer from "../../components/Footer/footer";
import AdminHeader from "../../components/Header/AdminHeader";
import routes, { AdminNavigationMenuList } from "../constants";

const AdminDahboardLayout = () => {
  const { isLoggedIn } = useSelector((state) => state.authReducer);
  const [isExpanded, setExpanded] = useState(true);
  const expandClicked = (val) => {
    setExpanded(val);
  };
  return isLoggedIn ? (
    <>
      <AdminHeader />
      <Toaster position="top-center" />
      <div className="container flex">
        <NavigationMenu
          className="nav-content admin-nav-content"
          collapseClass="admin-nav-content-collapse"
          menuItmes={AdminNavigationMenuList}
          clickExpand={(val) => expandClicked(val)}
          dashboardRoute="/admin-dashboard"
        />
        <div className={`content ${isExpanded ? "" : "expand"}`}>
          <Breadcrumbs routes={routes} />
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default AdminDahboardLayout;
