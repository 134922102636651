import React, { forwardRef, useEffect, useState } from "react";
import { OperationalAnalyticsEndPoints } from "../../../services/service-constants";

import Select from "../../../components/common/Select/Select";
import { Checkbox } from "../../../components/common/Checkbox/Checkbox";
import Filter from "../../../components/Filter/Filter";
import Daterangepicker from "../../../components/Datepicker/daterangepicker";
import { Button } from "../../../components/common/Button/Button";
import moment from "moment";
import intervalPreIcon from "../../../assets/interval.png";
import timeIcon from "../../../assets/datepicker.png";
import { mainAxiosInstance } from "../../../services/service";

import "./index.scss";
import { SingleLine } from "../../../components/common/Graphs/SingleLine";
import { DualLine } from "../../../components/common/Graphs/DualLine";
import { DualColumn } from "../../../components/common/Graphs/DualColumn";
import { SingleColumn } from "../../../components/common/Graphs/SingleColumn";
import { useNavigate } from "react-router";
import navLeft from "../../../assets/left-arrow-square.svg";
import navRight from "../../../assets/right-arrow-square.svg";
import close from "../../../assets/close.svg";
import { useDispatch, useSelector } from "react-redux";

const DriverBehaviour = () => {
  const intervalTypes = {
    trips: "by_trip",
    miles: "by_miles",
    date: "by_date",
  };
  const navigate = useNavigate();
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const interval = ["Lifetime", "Last Trip", "Last Miles", "Date"];
  const [defaultInterval, setDefaultInterval] = useState("Lifetime");
  const [chartData, setChartData] = useState([]);
  const [chartDetails, setChartDetails] = useState([]);
  const [xLabel, setXlabel] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(null);
  const [selectAll, setSelectAll] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [selectedChartData, setSelectedChartData] = useState(null);
  const filterOptions = [
    {
      displayname: "Engine Run Time / Distance Travelled",
      id: "engineRunTimeDistanceTravelled",
      isChecked: true,
      type: "dualLine",
      label: "Distance Travelled*Engine Runtime",
      title: "Engine Run Time / Distance Travelled",
    },
    {
      displayname: "Over Speeding",
      id: "noOfOverSpeedingEvents",
      isChecked: true,
      type: "singleLine",
      label: "# of over speeding events",
      title: "# of over speeding events",
    },
    {
      displayname: "Miles driven over speeding",
      id: "milesDrivenOverSpeeding",
      isChecked: true,
      type: "singleColumn",
      label: "Miles",
      title: "Miles driven over speeding",
    },
    {
      displayname: "Harsh Braking",
      id: "noOfHarshBreaking",
      isChecked: true,
      type: "singleLine",
      label: "# of harsh braking",
      title: "# of harsh braking",
    },
    {
      displayname: "Harsh Cornering / Steering",
      id: "noOfHarshCornering",
      isChecked: true,
      type: "singleLine",
      label: "# of harsh cornering / steering",
      title: "# of harsh cornering / steering",
    },
    {
      displayname: "Rapid Acceleration",
      id: "noOfRapidAcceleration",
      isChecked: true,
      type: "singleLine",
      label: "# of rapid acceleration",
      title: "# of rapid acceleration",
    },
    {
      displayname: "Rapid Deceleration",
      id: "noOfRapidDeceleration",
      isChecked: true,
      type: "singleLine",
      label: "# of rapid deceleration",
      title: "# of rapid deceleration",
    },
    {
      displayname: "Miles driven in extreme weather conditions",
      id: "engineOnDistanceTravelledExtremeWeather",
      isChecked: true,
      type: "singleColumn",
      label: "Driving distance (miles)",
      title: "Miles driven in extreme weather conditions",
    },
    {
      displayname: "Miles driven in Lane Tracking Zone",
      id: "milesDrivenInLaneTrackingZone",
      isChecked: true,
      type: "singleColumn",
      label: "Miles driven",
      title: "Miles driven in Lane Tracking Zone",
    },
    {
      displayname: "Driving Unpaved Road / Route Adherence",
      id: "drivingUnpavedRoadRouteAdherence",
      isChecked: true,
      type: "singleColumn",
      label: "Driving distance (miles)",
      title: "Driving Unpaved Road / Route adherence",
    },
    {
      displayname: "Traffic Signals Violation",
      id: "trafficSignalViolation",
      isChecked: true,
      type: "dualColumn",
      label: "Driving distance (miles)*# of traffic violation reported",
      title: "Traffic Signals Violation",
    },
    {
      displayname: "Stop Sign Violation",
      id: "stopSignViolation",
      isChecked: true,
      type: "dualColumn",
      label: "Driving distance (miles)*# of stop violation reported",
      title: "Stop Sign Violation",
    },
  ];

  const [filterData, setFilterData] = useState([...filterOptions]);
  const [inputData, setInputData] = useState(1000);
  const [showInput, setShowInput] = useState(false);
  const [showDatepicker, setShowDatepicker] = useState(false);

  const [selectedStartDate, setStartDate] = useState(new Date());
  const [selectedEndDate, setEndDate] = useState(new Date());
  const [noDataFound, setNoDataFound] = useState(true);
  const [behaviourSummary, setBehaviourSummary] = useState(0);

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    const isAnyModuleSelected = newFilterData.some((el) => el.isChecked);
    setNoDataFound(!isAnyModuleSelected);
    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Lifetime":
        setShowInput(false);
        setShowDatepicker(false);
        setType("dateTime");
        break;
      case "Last Trip":
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1);
        setType(null);
        break;
      case "Last Miles":
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1000);
        setType(null);
        break;
      case "Date":
        setShowInput(false);
        setShowDatepicker(true);
        setType("dateTime");
        break;
      default:
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1000);
        setType(null);
        break;
    }
  };

  const DatepickerCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      className="btn btn-secondary date-selector"
      onClick={onClick}
      ref={ref}
    >
      <img className="time-icon" src={timeIcon} alt="datePicker" />
      {value}
    </div>
  ));

  const handleOnDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const colorMap = {
    yellow: "#FDFD62",
    green: "#A9D18E",
    red: "#F23732",
  };

  const getSingleLineData = (id, data) => {
    const graphData = [];
    let term = "";
    if (id === "noOfOverSpeedingEvents") {
      term = "noOfOverSpeeding";
    } else if (id === "noOfHarshBreaking") {
      term = "noOfHarshBreaking";
    } else if (id === "noOfHarshCornering") {
      term = "noOfHarshCornering";
    } else if (id === "noOfRapidAcceleration") {
      term = "noOfRapidAcceleration";
    } else if (id === "noOfRapidDeceleration") {
      term = "noOfRapidDeceleration";
    }
    let labelObj = {};
    data?.forEach((val) => {
      let obj = [];
      if (defaultInterval === "Lifetime" || defaultInterval === "Date") {
        obj.push(moment(val["statsDate"]).format("DD"));
        labelObj[id] = moment(data?.[0]?.["statsDate"]).format("MMM, yyyy");
      } else if (defaultInterval === "Last Trip") {
        obj.push(val["trip"]);
        labelObj[id] = "Trips";
      } else if (defaultInterval === "Last Miles") {
        obj.push(val["miles"]);
        labelObj[id] = "Miles";
      }
      obj.push(val[term]);
      graphData.push(obj);
    });
    setXlabel((prev) => ({ ...prev, ...labelObj }));

    return [...graphData];
  };

  const getDualLineData = (id, data) => {
    const graphData = [];
    const secondAxisdata = [];
    const term = "engineRunTime";
    let labelObj = {};
    data?.forEach((val) => {
      let obj = {};
      let obj2 = {};
      if (defaultInterval === "Lifetime" || defaultInterval === "Date") {
        obj = {
          x: parseInt(moment(val["statsDate"]).format("DD")),
          y: val.miles,
        };
        obj2 = {
          x: parseInt(moment(val["statsDate"]).format("DD")),
          y: val[term],
        };
        labelObj[id] = moment(data[0]["statsDate"]).format("MMM, yyyy");
      } else if (defaultInterval === "Last Trip") {
        obj = {
          x: val["trip"],
          y: val.miles,
        };
        obj2 = {
          x: val["trip"],
          y: val[term],
        };
        labelObj[id] = "Trips";
      } else if (defaultInterval === "Last Miles") {
        obj = {
          x: val["miles"],
          y: val.miles,
        };
        obj2 = {
          x: val["miles"],
          y: val[term],
        };
        labelObj[id] = "Miles";
      }

      graphData.push(obj);
      secondAxisdata.push(obj2);
    });
    setXlabel((prev) => ({ ...prev, ...labelObj }));

    return [[...graphData], [...secondAxisdata]];
  };

  const getSingleColumnData = (id, data) => {
    const graphData = [];
    let labelObj = {};
    data?.forEach((val) => {
      let obj = {};
      if (defaultInterval === "Lifetime" || defaultInterval === "Date") {
        obj = {
          x: parseInt(moment(val["statsDate"]).format("DD")),
          y: val.miles,
          color: colorMap[val.threshold],
        };
        labelObj[id] = moment(data[0]["statsDate"]).format("MMM, yyyy");
      } else if (defaultInterval === "Last Trip") {
        obj = {
          x: val.trip,
          y: val.miles,
          color: colorMap[val.threshold],
        };
        labelObj[id] = "Trips";
      } else if (defaultInterval === "Last Miles") {
        obj = {
          x: val.miles,
          y: val.miles,
          color: colorMap[val.threshold],
        };
        labelObj[id] = "Miles";
      }
      graphData.push(obj);
    });
    setXlabel((prev) => ({ ...prev, ...labelObj }));

    return [...graphData];
  };

  const getDualColumnData = (id, data) => {
    // dualColumn: const {id, dataAxis1, dataAxis2, title, xLabel, yLabel, yLabel2} = data;
    const graphData = [];
    const secondAxisdata = [];
    let labelObj = {};
    const term =
      id === "trafficSignalViolation"
        ? "noOfSignalViolation"
        : "stopSignViolation";

    data?.forEach((val) => {
      let obj = {};
      let obj2 = {};
      if (defaultInterval === "Lifetime" || defaultInterval === "Date") {
        obj = {
          x: parseInt(moment(val["statsDate"]).format("DD")),
          y: val.miles,
          color: colorMap[val.threshold],
        };
        obj2 = {
          x: parseInt(moment(val["statsDate"]).format("DD")),
          y: val[term],
          color: colorMap[val.threshold],
        };
        labelObj[id] = moment(data[0]["statsDate"]).format("MMM, yyyy");
      } else if (defaultInterval === "Last Trip") {
        obj = {
          x: val.trip,
          y: val.miles,
          color: colorMap[val.threshold],
        };
        obj2 = {
          x: val.trip,
          y: val[term],
          color: colorMap[val.threshold],
        };
        labelObj[id] = "Trips";
      } else if (defaultInterval === "Last Miles") {
        obj = {
          x: val.miles,
          y: val.miles,
          color: colorMap[val.threshold],
        };
        obj2 = {
          x: val.miles,
          y: val[term],
          color: colorMap[val.threshold],
        };
        labelObj[id] = "Miles";
      }
      graphData.push(obj);
      secondAxisdata.push(obj2);
    });
    setXlabel((prev) => ({ ...prev, ...labelObj }));

    return [[...graphData], [...secondAxisdata]];
  };

  const checkForDataAvailability = (mainData) => {
    let dataAvailable = false;
    filterOptions.forEach((value) => {
      if (value.isChecked) {
        const data = mainData?.find((el) => el.name === value.id);
        if (
          [
            "engineRunTimeDistanceTravelled",
            "trafficSignalViolation",
            "stopSignViolation",
          ].includes(value.id)
        ) {
          if (data && data.values?.length > 0) {
            data.values.forEach((el) => {
              if (el && el.length > 0) {
                dataAvailable = true;
              }
            });
          }
        } else if (
          ["milesDrivenInLaneTrackingZone", "milesDrivenOverSpeeding"].includes(
            value.id
          )
        ) {
          if (data && data.values?.length > 0) {
            dataAvailable = !data.values.every(
              (item) => item.y === 0 || item.y === "0"
            );
          }
        } else {
          if (data && data.values?.length > 0) {
            dataAvailable = true;
          }
        }
      }
    });
    setNoDataFound(!dataAvailable);
  };

  const createChartPerType = (type, id, isLarge = false) => {
    const data = getDataForCharts(type, id);
    switch (type) {
      case "singleLine": {
        return (
          <>
            {isLarge ? (
              <SingleLine data={data} onClickHandler={() => {}}></SingleLine>
            ) : (
              <SingleLine
                data={data}
                onClickHandler={() => handleChartClick(id)}
              ></SingleLine>
            )}
          </>
        );
      }
      case "singleColumn": {
        return (
          <>
            {isLarge ? (
              <SingleColumn
                data={data}
                onClickHandler={() => {}}
                properties="nowrap"
              ></SingleColumn>
            ) : (
              <SingleColumn
                data={data}
                onClickHandler={() => handleChartClick(id)}
                properties=""
              ></SingleColumn>
            )}
          </>
        );
      }
      case "dualLine": {
        return (
          <>
            {isLarge ? (
              <DualLine data={data} onClickHandler={() => {}}></DualLine>
            ) : (
              <DualLine
                data={data}
                onClickHandler={() => handleChartClick(id)}
              ></DualLine>
            )}
          </>
        );
      }
      case "dualColumn": {
        return (
          <>
            {isLarge ? (
              <DualColumn data={data} onClickHandler={() => {}}></DualColumn>
            ) : (
              <DualColumn
                data={data}
                onClickHandler={() => handleChartClick(id)}
              ></DualColumn>
            )}
          </>
        );
      }
    }
  };

  const getChartData = (response) => {
    const mainData = [];
    let retData = [];
    let chartDet = [];
    filterData.forEach((el) => {
      const detailsData = response[el.id].driverBehaviourInfo;
      chartDet.push({ name: el.id, value: detailsData });
      switch (el.type) {
        case "singleLine": {
          retData = getSingleLineData(el.id, response[el.id][el.id]);
          break;
        }
        case "dualLine": {
          retData = getDualLineData(el.id, response[el.id][el.id]);
          break;
        }
        case "singleColumn": {
          retData = getSingleColumnData(el.id, response[el.id][el.id]);
          break;
        }
        case "dualColumn": {
          retData = getDualColumnData(el.id, response[el.id][el.id]);
          break;
        }
      }

      const dataToStore = {
        name: el.id,
        values: [...retData],
      };

      const index = mainData.findIndex((element) => {
        if (element?.name === el.id) {
          return true;
        }
        return false;
      });

      if (index === -1) {
        mainData.push({ ...dataToStore });
      } else {
        mainData.map((ele) => {
          if (ele.name === el.id) {
            ele.values = [...retData];
          }
        });
      }
    });
    setChartData(mainData);
    setChartDetails(chartDet);

    checkForDataAvailability(mainData);
  };

  const prepareChartData = (dataSet, replaceTextOuter, replaceText) => {
    let aggregatedData = {};
    Object.keys(dataSet).map((val) => {
      const name = val.replace(replaceTextOuter, "");
      // aggregatedData[name] = dataSet[val];
      const obj = {};
      Object.keys(dataSet[val]).map((value) => {
        const internalName = value.replace(replaceText, "");
        obj[internalName] = dataSet[val][value];
      });
      aggregatedData[name] = obj;
    });
    getChartData(aggregatedData);
  };

  const getPageData = (val) => {
    setChartData([]);
    const url = `${OperationalAnalyticsEndPoints}`;
    let params = {};
    const replaceText = "Dto";
    const replaceTextOuter = "AggregatorDto";
    switch (defaultInterval) {
      case "Last Miles": {
        params = {
          type: intervalTypes.miles,
          vinNumber: val.vin_Number,
          organizationId: 1,
          lastMiles: inputData || -1,
        };
        break;
      }
      case "Last Trip": {
        params = {
          type: intervalTypes.trips,
          vinNumber: val.vin_Number,
          organizationId: 1,
          lastTrips: inputData || -1,
        };
        break;
      }
      case "Date": {
        params = {
          type: intervalTypes.date,
          vinNumber: val.vin_Number,
          organizationId: 1,
          fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
          toDate: moment(selectedEndDate).format("yyyy-MM-DD"),
        };
        break;
      }
      case "Lifetime": {
        params = {
          type: intervalTypes.date,
          vinNumber: val.vin_Number,
          organizationId: 1,
        };
        break;
      }
      default: {
        params = {
          type: intervalTypes.date,
          vinNumber: val.vin_Number,
          organizationId: 1,
        };
        break;
      }
    }

    mainAxiosInstance
      .post(url, { ...params })
      .then((response) => {
        setNoDataFound(false);
        prepareChartData(response.data, replaceTextOuter, replaceText);
        setBehaviourSummary(
          response?.data?.driverBehaviourSummaryResponseDto?.driverScore || 0
        );
      })
      .catch((er) => {
        setNoDataFound(true);
        throw er;
      });
  };

  // eslint-disable-next-line no-unused-vars
  const getDataForCharts = (type, id) => {
    let data = {};
    const item = filterOptions.find((el) => el.id === id);
    const gData = chartData?.find((el) => el.name === id);
    const chartDetail = chartDetails?.find((el) => el.name === id);
    switch (type) {
      case "singleLine": {
        data = {
          id: id,
          chartdata: gData?.values,
          title: item.title,
          xLabel: xLabel[id],
          yLabel: item.label,
          chartDetails: chartDetail?.value,
        };
        break;
      }
      case "singleColumn": {
        data = {
          id: id,
          chartData: gData?.values,
          title: item.title,
          xLabel: xLabel[id],
          yLabel: item.label,
          chartDetails: chartDetail?.value,
        };
        break;
      }
      case "dualLine": {
        const labelList = item.label.split("*");
        data = {
          id: id,
          dataAxis1: gData?.values?.[0],
          dataAxis2: gData?.values?.[1],
          title: item.title,
          xLabel: xLabel[id],
          yLabel: labelList[0],
          yLabel2: labelList[1],
          chartDetails: chartDetail?.value,
        };
        break;
      }
      case "dualColumn": {
        const labelList = item.label.split("*");
        data = {
          id: id,
          dataAxis1: gData?.values?.[0],
          dataAxis2: gData?.values?.[1],
          title: item.title,
          xLabel: xLabel[id],
          yLabel: labelList[0],
          yLabel2: labelList[1],
          chartDetails: chartDetail?.value,
        };
        break;
      }
    }
    return data;
  };

  // eslint-disable-next-line no-unused-vars
  const handleChartClick = (id) => {
    setShowModal(true);
    const selData = filterData.find((el) => el.id === id);
    setSelectedChartData(selData);
    setShowFilter(false);
  };

  // eslint-disable-next-line no-unused-vars
  const showPrev = (el) => {
    const data = filterData.filter((el) => el.isChecked);
    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== 0) {
        selectElement = data[index - 1];
      } else if (index === 0) {
        selectElement = data[data.length - 1];
      }
      setNoDataFound(false);
      setSelectedChartData(selectElement);
    } else {
      setNoDataFound(true);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const showNext = (el) => {
    const data = filterData.filter((el) => el.isChecked);
    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== data.length - 1) {
        selectElement = data[index + 1];
      } else if (index === data.length - 1) {
        selectElement = data[0];
      }
      setNoDataFound(false);
      setSelectedChartData(selectElement);
    } else {
      setNoDataFound(true);
    }
  };

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });
    setFilterData(getUpdatedfilterData);
    setSelectAll(val);
    setNoDataFound(false);
  };

  const clickOnClose = (e) => {
    e.stopPropagation();
    setShowModal(false);
    setShowFilter(true);
  };

  useEffect(() => {
    setChartData([]);
    getPageData({
      vin_Number: vin_Number,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultInterval, inputData, selectedStartDate, selectedEndDate]);

  return (
    <div className="page-wrapper">
      <div className="flex page-content-header">
        <div className="page-options-container">
          <Select
            heading="Data Interval"
            options={interval}
            defaultSelected={defaultInterval}
            handleOptionClick={handleIntervalOptionClick}
            preIcon={intervalPreIcon}
          />
          {showInput && (
            <input
              type="text"
              className="value-input"
              placeholder={`Please enter ${defaultInterval}`}
              value={inputData}
              onChange={(e) => {
                setInputData(e.target.value);
              }}
            />
          )}
          {showDatepicker && (
            <Daterangepicker
              customInput={<DatepickerCustomInput />}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              handleOnChange={handleOnDateChange}
            />
          )}
        </div>
        {showFilter === true && (
          <div className="page-filter">
            <Filter data={AllSelect} selectAll={selectAll}>
              {filterData.map((el) => (
                <Checkbox
                  title={el.displayname}
                  handleInputClick={() => handleFilterClick(el)}
                  alignProp={{
                    borderTop: "0.5px solid #C4C4C4",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "10px",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                  }}
                  checkboxStyle={{ marginRight: "10px" }}
                  isChecked={el.isChecked}
                />
              ))}
            </Filter>
          </div>
        )}
      </div>
      <div className="service-help-wrapper driver-behaviour">
        <div className="service-help">
          Driver Behaviour {behaviourSummary}%
          <Button
            title="Get Summary"
            handleClick={() =>
              navigate("/operational-analytics/driver-behaviour/summary", {
                replace: true,
              })
            }
            styleClasses="btn btn-primary"
          />
        </div>
      </div>
      <div className="page-chart-container vehicle-usage-charts">
        {!noDataFound &&
          filterData.map(
            (el) =>
              el.isChecked && !showModal && createChartPerType(el.type, el.id)
          )}
        {showModal && !noDataFound && (
          <>
            <div
              className="nav-icons"
              onClick={() => showPrev(selectedChartData)}
            >
              <img src={navLeft} alt="previous" />
            </div>
            <div className="large-charts">
              <div
                className={`modal-close driver-behaviour ${
                  selectedChartData.type === "singleColumn"
                    ? "single-column"
                    : ""
                }`}
                onClick={clickOnClose}
              >
                <img src={close} alt="close" />
              </div>
              {createChartPerType(
                selectedChartData.type,
                selectedChartData.id,
                true
              )}
            </div>
            <div
              className="nav-icons"
              onClick={() => showNext(selectedChartData)}
            >
              <img src={navRight} alt="previous" />
            </div>
          </>
        )}
        {noDataFound && (
          <div className="no-data">
            Sorry, We could not find any data for the given search criteria.
          </div>
        )}
      </div>
    </div>
  );
};

export default DriverBehaviour;
