import React from "react";

export const Button = (props) => {
  const { title, handleClick, styleClasses, buttonStyles, type } = props;
  return (
    <button
      className={styleClasses}
      onClick={handleClick}
      type={type}
      style={buttonStyles}
    >
      {props.children}
      {title}
    </button>
  );
};
