import { VIBRATIONAL_ANALYTICS_ACTIONS } from "./action_types";

const INITIAL_STATE = {
  performanceData: null,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case VIBRATIONAL_ANALYTICS_ACTIONS.GET_PERFORMANCE_DATA:
      return {
        ...state,
        performanceData: payload,
      };
    default:
      return state;
  }
};

export default reducer;
