import React from "react";

const HeaderCard = ({ text, icon, count, backgroundColor }) => {
  return (
    <div
      className="admin_page_card"
      style={{ backgroundColor: backgroundColor }}
    >
      {icon}
      <h4 className="text">{text}</h4>
      <h4 className="count">{count}</h4>
    </div>
  );
};

export default HeaderCard;
