import {
  axiosWithContentType,
  mainAxiosInstance,
} from "../../../services/service";
import { AdminDashboardEndPoints } from "../../../services/service-constants";

export const getUserlist = async (payload) => {
  const url = `${AdminDashboardEndPoints.getUserlistEndpoint}?page=0&size=20`;
  const reqBody = {};
  return mainAxiosInstance
    .post(url, reqBody)
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: "You are not authorized",
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response.status,
          data: err.msj || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.msj || "Something went wrong",
        };
      }
    });
};

export const getFilterUserlist = async (payload, page = 0) => {
  const url = `${AdminDashboardEndPoints.getUserlistEndpoint}?page=${page}&size=20`;

  return mainAxiosInstance
    .post(url, payload)
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: "You are not authorized",
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response.status,
          data: err.msj || "Something went wrong",
        };
      } else {
        return {
          code: "error",
          data: err.msj || "Something went wrong",
        };
      }
    });
};

export const changeUserRole = async (payload) => {
  const url = `${AdminDashboardEndPoints.changeUserRole}`;
  const reqBody = {
    emailId: payload.emailId,
    userRole: payload.userRole,
  };
  return mainAxiosInstance
    .patch(url, reqBody, {
      headers: {
        Accept: "application/merge-patch+json",
        "Accept-Patch": "application/merge-patch+json",
        "Content-Type": "application/merge-patch+json",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: "You are not authorized",
        };
      }
    })
    .catch((err) => {
      return {
        code: "error",
        data: err.msj || "Something went wrong",
      };
    });
};

export const userApporval = async (payload) => {
  const url = `${AdminDashboardEndPoints.userApporval}`;
  const reqBody = {
    emailId: payload.emailId,
    userRole: payload.userRole,
  };
  return mainAxiosInstance
    .patch(url, reqBody, {
      headers: {
        Accept: "application/merge-patch+json",
        "Accept-Patch": "application/merge-patch+json",
        "Content-Type": "application/merge-patch+json",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: "You are not authorized",
        };
      }
    })
    .catch((err) => {
      return {
        code: "error",
        data: err.msj || "Something went wrong",
      };
    });
};

export const userDecline = async (payload) => {
  const url = `${AdminDashboardEndPoints.userDecline}`;
  const reqBody = {
    emailId: payload.emailId,
    remarks: payload.remarks,
  };
  return mainAxiosInstance
    .patch(url, reqBody, {
      headers: {
        Accept: "application/merge-patch+json",
        "Accept-Patch": "application/merge-patch+json",
        "Content-Type": "application/merge-patch+json",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: "You are not authorized",
        };
      }
    })
    .catch((err) => {
      return {
        code: "error",
        data: err.msj || "Something went wrong",
      };
    });
};

export const userDelete = async (payload) => {
  const url = `${AdminDashboardEndPoints.userDelete}`;
  const reqBody = {
    emailId: payload.emailId,
    remarks: payload.remarks,
  };
  return axiosWithContentType
    .patch(url, reqBody, {
      headers: {
        Accept: "application/merge-patch+json",
        "Accept-Patch": "application/merge-patch+json",
        "Content-Type": "application/merge-patch+json",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: "You are not authorized",
        };
      }
    })
    .catch((err) => {
      return {
        code: "error",
        data: err.msj || "Something went wrong",
      };
    });
};
