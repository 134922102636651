import "./scss/App.scss";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  // useRoutes,
} from "react-router-dom";
import store from "./store";
import Details from "./components/Details";
import DashBoard from "./pages/Dashboard";
import VehicleUsageProfile from "./pages/VehicleUsageProfile/VehicleUsageProfile";
import routes, {
  MaintenanceLogsRoutes,
  MODServiceGuideDetailsRoutes,
  NavigationMenuList,
  NavigationDashBoardMenuLish,
  PrognosticsServiceGuideDetailsRoutes,
  ComponentSystemHistoryRoutes,
  RootCauseAnalysisRoutes,
  RootCauseDataRoutes,
} from "./utils/constants";
import ServiceGuide from "./pages/MaintenanceOnDemand/ServiceGuide";
import MaintenanceLogsSummary from "./pages/MaintenanceLogs/MaintenanceLogsSummary";
import GetDriverBehaviourSummary from "./pages/Operational Analytics/GetDriverBehaviourSummary.js";
import PictorialRepresentation from "./pages/Operational Analytics/PictorialRepresentation";
import RemotePerformanceProfile from "./components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemotePerformanceProfile";
import RemoteAbsDiagram from "./components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemoteAbsDiagram";
import RemoteTesting from "./components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemoteTesting";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PrivateRoute from "./utils/PrivateRoute";
import IdealBehaviourPieChart from "./pages/Operational Analytics/PictorialRepresentation/IdealBehaviourPieChart";
import PrognosticsServiceGuide from "./pages/MaintenanceOnDemand/ServiceGuide/prognostics";
import ComponentSystemHistory from "./components/ComponentSystemHistory";
import RootCauseAnalysis from "./pages/RootCauseAnalysis";
import Home from "./components/Home";
import RootCauseData from "./pages/RootCauseAnalysis/RootCauseData";
import AdminDahboardLayout from "./utils/layouts/AdminDahboardLayout";
import AdminDashboard from "./pages/AdminDashboard";
import PrescribedActions from "./pages/RootCauseAnalysis/PrescribedActions";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Signup />} />
            <Route exact path="/set-password" element={<Signup />} />
            <Route path="" element={<AdminDahboardLayout />}>
              <Route
                exact
                path="/admin-dashboard"
                element={<AdminDashboard />}
              />
              <Route exact path="/configure-features" element={<Details />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="" element={<PrivateRoute />}>
              <Route exact path="/dashboard" element={<DashBoard />} />

              <Route exact path="/details" element={<Details />} />
              <Route
                exact
                path="/health"
                element={<h3>Hey There!!! The App is Healthy</h3>}
              />
              <Route
                exact
                path="/vehicle-usage"
                element={<VehicleUsageProfile />}
              />

              {NavigationDashBoardMenuLish?.length &&
                NavigationDashBoardMenuLish.map((item) => (
                  <Route
                    key={item.path}
                    exact
                    path={item.path}
                    element={item.component}
                  />
                ))}

              {NavigationMenuList?.length &&
                NavigationMenuList.map((item) => (
                  <Route
                    key={item.path}
                    exact
                    path={item.path}
                    element={item.component}
                  >
                    {item?.nestedRoutes?.map((nestedItem) => (
                      <Route
                        key={item.path}
                        exact
                        path={nestedItem.path}
                        element={nestedItem.component}
                      >
                        {nestedItem?.nestedRoutes?.map((childItem) => (
                          <Route
                            key={item.path}
                            exact
                            path={childItem.path}
                            element={childItem.component}
                          />
                        ))}
                      </Route>
                    ))}
                  </Route>
                ))}
              <Route
                key="Service Guide"
                exact
                path="/maintenance-overview/diagnostics/service-guide"
                element={<ServiceGuide />}
              >
                {MODServiceGuideDetailsRoutes.map((item) => (
                  <Route
                    key={item.title}
                    exact
                    path={item.path}
                    element={item.component}
                  />
                ))}
              </Route>
              <Route
                key="Service Guide Prognostics"
                exact
                path="/maintenance-overview/prognostics/service-guide"
                element={<PrognosticsServiceGuide />}
              >
                {PrognosticsServiceGuideDetailsRoutes.map((item) => (
                  <Route
                    key={item.title}
                    exact
                    path={item.path}
                    element={item.component}
                  />
                ))}
              </Route>
              <Route
                key="Maintenance Logs Summary"
                exact
                path="/maintenance-log/summary"
                element={<MaintenanceLogsSummary />}
              >
                {MaintenanceLogsRoutes.map((item) => (
                  <Route
                    key={item.title}
                    exact
                    path={item.path}
                    element={item.component}
                  />
                ))}
              </Route>
              <Route
                key="Get Summary"
                exact
                path="/operational-analytics/driver-behaviour/summary"
                element={<GetDriverBehaviourSummary />}
              />
              <Route
                key="Pictorial Representation"
                exact
                path="/operational-analytics/driver-behaviour/summary/pictorial-representation"
                element={<PictorialRepresentation />}
              />

              <Route
                key="Root cause Data Fault Trend"
                exact
                path="/fault-analytics/root-cause-analysis/root-cause-data"
                element={<RootCauseData />}
              >
                {RootCauseDataRoutes.map((item) => (
                  <Route
                    key={`${item.path} Fault Trend`}
                    exact
                    path={`/fault-analytics/root-cause-analysis/root-cause-data${item.path}`}
                    element={item.component}
                  />
                ))}
              </Route>
              <Route
                key="Falut Trend Prescribed Actions"
                exact
                path="/fault-analytics/root-cause-analysis/prescribed-actions"
                element={<PrescribedActions />}
              ></Route>
              <Route
                key="Root cause Analysis Fault Trend"
                exact
                path="/maintenance-overview/diagnostics/root-cause-analysis/root-cause-data"
                element={<RootCauseData />}
              >
                {RootCauseDataRoutes.map((item) => (
                  <Route
                    key={`${item.path} MOD Diagnostcs`}
                    exact
                    path={`/maintenance-overview/diagnostics/root-cause-analysis/root-cause-data${item.path}`}
                    element={item.component}
                  />
                ))}
              </Route>
              <Route
                key="Falut Trend Prescribed Actions"
                exact
                path="/maintenance-overview/diagnostics/root-cause-analysis/prescribed-actions"
                element={<PrescribedActions />}
              ></Route>
              <Route
                key="Falut Trend Prescribed Actions"
                exact
                path="/maintenance-overview/prognostics/root-cause-analysis/prescribed-actions"
                element={<PrescribedActions />}
              ></Route>
              <Route
                key="Root cause Analysis Fault Trend"
                exact
                path="/maintenance-overview/prognostics/root-cause-analysis/root-cause-data"
                element={<RootCauseData />}
              >
                {RootCauseDataRoutes.map((item) => (
                  <Route
                    key={`${item.path} MOD Prognostics`}
                    exact
                    path={`/maintenance-overview/prognostics/root-cause-analysis/root-cause-data${item.path}`}
                    element={item.component}
                  />
                ))}
              </Route>
              <Route
                key="Component System History Vehicle Overview"
                exact
                path="/vehicle-overview/notification/component-system-history"
                element={<ComponentSystemHistory />}
              >
                {ComponentSystemHistoryRoutes.map((item) => (
                  <Route
                    key={`${item.title} Vehicle Overview`}
                    exact
                    path={`/vehicle-overview/notification/component-system-history${item.path}`}
                    element={item.component}
                  />
                ))}
              </Route>

              <Route
                key="Component System History Vehicle Health OnBoard"
                exact
                path="/vehicle-health/diagnostics/on-board/component-system-history"
                element={<ComponentSystemHistory />}
              >
                {ComponentSystemHistoryRoutes.map((item) => (
                  <Route
                    key={`${item.title} On Board`}
                    exact
                    path={`/vehicle-health/diagnostics/on-board/component-system-history${item.path}`}
                    element={item.component}
                  />
                ))}
              </Route>

              <Route
                key="Component System History Vehicle Health Self Test"
                exact
                path="/vehicle-health/self-test/component-system-history"
                element={<ComponentSystemHistory />}
              >
                {ComponentSystemHistoryRoutes.map((item) => (
                  <Route
                    key={`${item.title} Self Test`}
                    exact
                    path={`/vehicle-health/self-test/component-system-history${item.path}`}
                    element={item.component}
                  />
                ))}
              </Route>

              <Route
                key="Maintenance Logs Summary"
                exact
                path="/maintenance-log/summary"
                element={<MaintenanceLogsSummary />}
              >
                {MaintenanceLogsRoutes.map((item) => (
                  <Route
                    key={item.title}
                    exact
                    path={item.path}
                    element={item.component}
                  />
                ))}
              </Route>
              <Route
                key="Get Summary"
                exact
                path="/operational-analytics/driver-behaviour/summary"
                element={<GetDriverBehaviourSummary />}
              />
              <Route
                key="Pictorial Representation"
                exact
                path="/operational-analytics/driver-behaviour/summary/pictorial-representation"
                element={<PictorialRepresentation />}
              />
              <Route
                key="Ideal Behaviour"
                exact
                path="/operational-analytics/driver-behaviour/summary/pictorial-representation/ideal-behaviour"
                element={<IdealBehaviourPieChart />}
              />
              <Route
                key="Performance Profile"
                exact
                path="/vehicle-health/diagnostics/remote-testing/performance-profile"
                element={<RemoteAbsDiagram />}
              />
              <Route
                key="ABS Diagram"
                exact
                path="/vehicle-health/diagnostics/remote-testing/abs-diagram"
                element={<RemotePerformanceProfile />}
              />
            </Route>
          </Routes>

          {/* <RoutingComp /> */}
        </div>
      </Router>
    </Provider>
  );
}

export default App;
