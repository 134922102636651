import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import "./index.scss";
// import { BrowserRouter as Router, Route } from "react-router-dom";
// import { VehicleHealthCheckRoutes } from "../../utils/constants";

const VehicleHealthCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/vehicle-health") {
      navigate("/vehicle-health/diagnostics", { replace: true });
    }
  }, [location.pathname]);

  return (
    <div className="full-width ">
      {/* <Router>
        <Routes>
          {VehicleHealthCheckRoutes?.length &&
            VehicleHealthCheckRoutes.map((item) => (
              <Route
                key={item.title}
                exact
                path={item.path}
                element={item.component}
              />
            ))}
        </Routes>
      </Router> */}
      <div className="tabs-container">
        <NavLink
          to="/vehicle-health/diagnostics"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Diagnostics
          {/* <Button
            title="Diagnostics"
            handleClick={() => {}}
            styleClasses={({ isActive }) =>
              `btn ${isActive ? "btn-primary" : "btn-secondary"}`
            }
          /> */}
        </NavLink>
        <NavLink
          to="/vehicle-health/self-test"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Self Test
          {/* <Button
            title="Self Test"
            handleClick={() => {}}
            styleClasses={({ isActive }) =>
              `btn ${isActive ? "btn-primary" : "btn-secondary"}`
            }
          /> */}
        </NavLink>
        <NavLink
          to="/vehicle-health/digital-twin"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        > 
          Digital Twin
          
        </NavLink>
        
      </div>
      <Outlet />
    </div>
  );
};

export default VehicleHealthCheck;
