export const FETCH_VEHICLES = "FETCH_VEHICLES";

export const VIN_NUMBER = "VIN_NUMBER";

export const IS_SIDEMENU_EXPANDED = "IS_SIDEMENU_EXPANDED";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
