import moment from "moment";

import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../assets/right-arrow-long.svg";
import LengenContainer from "./LengendContainer";
import LengendContainer from "./LengendContainer";
import { useSelector } from "react-redux";
import { colorMap } from "../utils/constants";
import HC_Regression from "highcharts-regression";
import NoDataFound from "./NoDataFound";

// usage of highcharts-regression
HC_Regression(Highcharts);

const MpgAndRpm = ({ data }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const [chartData, setChartData] = useState(null);
  const [mpgAverageArray, setMpgAverageArray] = useState(null);
  const [mpgTargetArray, setMpgTargetArray] = useState(null);
  const [chartInfo, setChartInfo] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setChartData(data?.mpgVsRpm);
    if (data && data?.mpgVsRpm.length > 0) {
      const mpgAverageArray = data.mpgVsRpm.map((item) => [
        item.rpmRange,
        item.mpgAverage,
      ]);
      const mpgTargetArray = data.mpgVsRpm.map((item) => [
        item.rpmRange,
        item.mpgTarget,
      ]);
      setChartInfo(data?.information);
      setMpgAverageArray(mpgAverageArray);
      setMpgTargetArray(mpgTargetArray);
      const month = moment(data?.mpgVsRpm[0].statsDate).format("MMMM");
      const year = moment(data?.mpgVsRpm[0].statsDate).format("YYYY");
      setYear(year);
      setMonth(month);
    } else {
      setMpgAverageArray(null);
      setMpgTargetArray(null);
    }
  }, [data]);
  const options = {
    chart: {
      type: "column",
      backgroundColor: "#F1F3F5",
      spacing: [50, 50, 30, 20],
      height: 350,
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-50px;top:4px;position:absolute;">
         MPG
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      min: 0,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
      gridLineColor: "#ffffff",
    },
    xAxis: {
      type: "category",
      title: {
        useHTML: true,
        text: `
       <div style="font-size: 11px; left:-100px;top:4px;position:absolute;">
           (RPM*100)
        </div>
        `,
      },
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
      },
      line: {
        dashStyle: "Dash",
      },
    },
    tooltip: {
      style: {
        color: "#353536",
        font: "12px Open Sans",
      },
    },
    series: [
      {
        data: mpgAverageArray,
        showInLegend: false,
        name: "Mpg Target",
        color: chartInfo && colorMap[chartInfo[0]?.color],
      },
      {
        data: mpgTargetArray,
        showInLegend: false,
        name: "Mpg Average",
        color: chartInfo && colorMap[chartInfo[1]?.color],
      },
    ],
  };
  return (
    <div key={mpgAverageArray}>
      <div key={isSidemenuExpanded}>
        {Array.isArray(mpgAverageArray) && Array.isArray(mpgTargetArray) ? (
          <>
            <LengendContainer chartInfo={chartInfo} />
            <HighchartsReact highcharts={Highcharts} options={options} />
            <div
              style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}
            >
              <p style={{ fontSize: "12px", fontWeight: "600" }}>MPG Vs RPM</p>
            </div>
          </>
        ) : (
          <>
            <NoDataFound chartName="MPG Vs RPM" />
          </>
        )}
      </div>
    </div>
  );
};

export default MpgAndRpm;
