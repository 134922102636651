import React from "react";
import WarrantyCard from "../../components/WarrantyCard";
import ReportsIcon from "../../assets/reports.svg";
import CustomersAffectedIcon from "../../assets/customers-affected.svg";
import CostIcon from "../../assets/cost.svg";
import Severity from "../../assets/severity.svg";
import DtcWarrantyIcon from "../../assets/dtc-warranty-score.svg";
import RiskAnalysisIcon from "../../assets/risk-analysis.svg";

const TopDtcComplaints = () => {
  return (
    <div className="diagnostics-topdtc-complaints page-wrapper">
      <div className="first-container">
        <WarrantyCard
          img={ReportsIcon}
          backgroundColor="#F4E6E7"
          text="Reports"
        />
        <WarrantyCard
          img={CustomersAffectedIcon}
          backgroundColor="#E3F5E3"
          text="Customers Affected"
        />
        <WarrantyCard img={CostIcon} backgroundColor="#C9DBFF" text="Cost" />
      </div>
      <div className="second-container">
        <WarrantyCard
          img={Severity}
          backgroundColor="#EAE1F9"
          text="Severity"
        />
        <WarrantyCard
          img={DtcWarrantyIcon}
          backgroundColor="#C1E5FF"
          text="DTC Warranty Scorecard"
        />
        <WarrantyCard
          img={RiskAnalysisIcon}
          backgroundColor="#D7FFFA"
          text="Risk Analysis and Required Action"
          containerStyles={{ paddingTop: "20px" }}
        />
      </div>
    </div>
  );
};

export default TopDtcComplaints;
