import { loginAxiosInstance } from "./service";

export const login = async (data) => {
  let url = `auth/login`;
  await loginAxiosInstance.post(url, data).then((response) => {
    let resData = JSON.stringify({
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
    });
    localStorage.setItem("token", resData);

    return response.data.accessToken;
  });
};

export const logout = () => {
  localStorage.removeItem("token");
  window.location.reload();
};

export const register = async (data) => {
  let url = `auth/users`;
  return await loginAxiosInstance.post(url, data);
};
export const forgotPassword = async (data) => {
  let url = `auth/reset_password_link`;
  return await loginAxiosInstance.post(url, data);
};
export const resetPassword = async (data) => {
  let url = `auth/password_reset`;
  return await loginAxiosInstance.post(url, data);
};
export const setPassword = async (data) => {
  let url = `auth/password_set`;
  return await loginAxiosInstance.post(url, data);
};
