import React, { useCallback } from "react";
import { useState } from "react";
import { Button } from "../../../../common/Button/Button";
import Modal from "../../../../common/Modal/Modal";
import { useForm } from "react-hook-form";
import { getVibrationalPerformanceData } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { getPerformanceData } from "../../../../../store/vibrationalAnalytics/actions";

const ButtonCard = ({ buttonText, id, buttonContent, showTable, disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    const reqData = data.parts

      .filter((part) => Boolean(part.value))
      .map((part) => part.value);

    if (Array.isArray(reqData) && reqData.length > 0) {
      const data = await getVibrationalPerformanceData({
        profile: buttonText,
        parts: reqData,
        vinNumber: vin_Number,
      });
      dispatch(getPerformanceData(data));
      setIsModalOpen(false);
      showTable();
    }
  };

  const validate = useCallback(() => {
    const errorMessage = "Please select at least one item";
    const values = getValues(
      buttonContent.modalContent.modalButtons.map(
        (v, index) => `parts.${index}.value`
      )
    );
    const isValid = values.some((v) => v);
    return isValid || errorMessage;
  }, []);

  return (
    <>
      <div className="v-button" onClick={() => setIsModalOpen(true)}>
        {buttonText}
      </div>
      {isModalOpen && !disabled && (
        <Modal
          type="large"
          titlePosition="center"
          isError={false}
          handleClose={() => setIsModalOpen(false)}
          wrapperClass="button-modal"
        >
          <div className="modal-content">
            <div className="title-box">
              <p className="button-head">{buttonText}</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="content-box">
                <p>Select Part to get Vibration Analysis​</p>
                <div className="form-container">
                  <div className="checkbox-container">
                    {buttonContent.modalContent.modalButtons.map(
                      (button, index) => (
                        <>
                          <div className="input-container">
                            <input
                              type="checkbox"
                              name="checkox-ut"
                              id={`custom-checkbox-${index}`}
                              disabled={button.disable}
                              value={button.text}
                              {...register(`parts.${index}.value`, {
                                validate,
                              })}
                            />
                            <label htmlFor={`custom-checkbox-${index}`}>
                              {button.text}
                            </label>
                          </div>
                        </>
                      )
                    )}
                  </div>
                  {errors && Object.keys(errors).includes("parts") && (
                    <p className="error-text">
                      {errors?.parts[0]?.value?.message}
                    </p>
                  )}
                </div>
                <div className="action-container">
                  <Button
                    title="Cancel"
                    handleClick={() => setIsModalOpen(false)}
                    styleClasses="btn btn-secondary v-cancel-button"
                  />
                  <Button
                    title="Get Analysis"
                    type="submit"
                    // handleClick={onClick}
                    styleClasses="btn btn-primary v-analysis-button"
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ButtonCard;
