import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handlePerProfile } from "./RemoteTesting";
import { LineChart } from "../../../common/LineGraph";
import { useSelector } from "react-redux";
import { RemoteTestingGraph } from "./Graph/RemoteTestingGraph";
import VehicleHealthCheck from "../../index";
import Diagnostics from "../Diagnostics";

const RemotePerformanceProfile = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlEnd = searchParams.get("urlEnd");
  const imgUrlEnd = searchParams.get("imgUrlEnd");
  const graphUrl = searchParams.get("graphUrl");
  const [chartData, setChartData] = useState([]);
  const [timeAxis, setTimeAxis] = useState("");
  const [dataWithImg, setDataWithImg] = useState([]);
  const [data, setData] = useState("");
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  useEffect(() => {
    if (chartData?.length) {
      setData(chartData);
    }
  }, [JSON.stringify(chartData)]);

  useEffect(() => {
    handlePerProfile({
      urlEnd,
      imgUrlEnd,
      saveData: setChartData,
      saveImgData: setDataWithImg,
      xAxisSaveData: setTimeAxis,
      graphUrl,
      vin_Number,
    });
  }, []);
  const handleViewDiagram = ({ routePath, urlEnd, imgUrlEnd, graphUrl }) => {
    navigate(
      "/vehicle-health/diagnostics/remote-testing/" +
        routePath +
        "?" +
        "urlEnd=" +
        urlEnd +
        "&imgUrlEnd=" +
        imgUrlEnd +
        "&graphUrl=" +
        graphUrl,

      {
        replace: true,
      }
    );
  };

  return (
    <div className="page-wrapper">
      <VehicleHealthCheck />
      <Diagnostics />
      <div className="rem-test-abs-diagram-card" style={{ marginTop: "20px" }}>
        <div className="re-test-abs-wrapper">
          <div className="rem-test-diagrams">
            <div className="rem-test-graph">
              {chartData?.length > 0 && dataWithImg?.length > 0 && (
                <div
                  onClick={() =>
                    handleViewDiagram({
                      routePath: "performance-profile",
                      urlEnd: urlEnd,
                      imgUrlEnd: imgUrlEnd,
                      graphUrl: graphUrl,
                    })
                  }
                >
                  <RemoteTestingGraph
                    id={dataWithImg?.[0]?.id}
                    data={data}
                    onClickHandler={() => {}}
                    title={dataWithImg?.[0]?.graphName
                      ?.split("_")
                      .join(" ")
                      ?.toUpperCase()}
                    xLabel={dataWithImg?.[0]?.xAxisName}
                    xAxisData={timeAxis}
                    yAxisLabel={dataWithImg?.[0]?.yAxisName}
                  />
                </div>
              )}
            </div>
            <div className="rem-test-abs-diagram">
              <img
                className="rem-test-abs-diagram-image"
                src={dataWithImg?.[0]?.imageURL}
                alt={dataWithImg?.[0]?.graphName}
              />
            </div>
          </div>
          <div className="rem-test-diag-text">
            <h4>Purpose:</h4>
            {dataWithImg?.[0]?.purpose}
            <h4>Test Prerequisite:</h4>
            {dataWithImg?.[0]?.testPrerequisite}
            <h4>Test Execution:</h4>
            {dataWithImg?.[0]?.testExecution}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemotePerformanceProfile;
