import React, { useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

const Diagnostics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/warranty-management/diagnostics") {
      navigate("/warranty-management/diagnostics/top-10-dtc-complaints", {
        replace: true,
      });
    }
  }, [location.pathname]);
  return (
    <div className="full-width diagnostics-container">
      <div className="sec-tabs-container">
        <NavLink
          to="/warranty-management/diagnostics/top-10-dtc-complaints"
          className={({ isActive }) =>
            isActive ? "btn-primary sec-tabs" : "btn-secondary sec-tabs"
          }
        >
          Top 10 DTCs Complaints
        </NavLink>
        <NavLink
          to="/warranty-management/diagnostics/top-10-non-dtc-complaints"
          className={({ isActive }) =>
            isActive ? "btn-primary sec-tabs" : "btn-secondary sec-tabs"
          }
        >
          Top 10 non-DTC Complaints
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default Diagnostics;
