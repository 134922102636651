import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Daterangepicker = (props) => {
  const { customInput, startDate, endDate, handleOnChange } = props;
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const today = new Date();

  useEffect(() => {
    setFromDate(startDate || new Date());
    setToDate(endDate || new Date());
  }, []);

  const onChange = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
    handleOnChange(dates);
  };

  return customInput ? (
    <DatePicker
      selected={fromDate}
      onChange={onChange}
      startDate={fromDate}
      endDate={toDate}
      selectsRange
      customInput={customInput}
      maxDate={today}
    />
  ) : (
    <DatePicker
      selected={fromDate}
      onChange={onChange}
      startDate={fromDate}
      endDate={toDate}
      selectsRange
      maxDate={today}
    />
  );
};

export default Daterangepicker;
