import { relativeLength } from "highcharts";
import React, { useEffect, useState } from "react";
import "./index.scss";
const SinglebarCard = ({ item }) => {
  const [isEqual, setIsEqual] = useState(false);
  const colorMap = {
    yellow: "#FDE6A1",
    green: "#CFFFD2",
    red: "#FDDBD4",
  };

  let actualScoreValue = item.actualScore;
  let ItemColor = colorMap[item.barColor.toLowerCase()];
  let obtainedScoreValue = item.obtainedScore;
  let thresholdValue = item.thresholdValue;


  useEffect(() => {
    if (actualScoreValue === obtainedScoreValue) {
      setIsEqual(true);
    }
  }, [actualScoreValue, obtainedScoreValue]);

  return (
    <>
      <div class="bar__container">
        <div class="bar__wrapper">
          <div class="dashed_line"
            style={{
              height: thresholdValue * 20 + "px",
              borderTop: '2px dashed red',
              position: "absolute",
              zIndex: "100",
              width: "70px",
              margin: " 0px 20px 0px 20px "

            }}>
          </div>

          <div class="bar">
            <div
              class="bar-track"
              style={{
                height: actualScoreValue * 20 + "px",
              }}
            >
              <span>{actualScoreValue + "%"}</span>

              <div
                class="bar-fill"
                style={{
                  backgroundColor: ItemColor,
                  height: isEqual
                    ? 100 + "%"
                    : actualScoreValue * 20 -
                    (actualScoreValue - obtainedScoreValue) * 20 +
                    "px",
                  borderTop:
                    !isEqual && obtainedScoreValue !== 0
                      ? "1px solid #000000"
                      : "",
                }}
              >
                <span>{!isEqual ? item.obtainedScore + "%" : ""}</span>

              </div>
            </div>
          </div>
        </div>
        <span className="bar__wrapper--footer">{item.paramName}</span>
      </div>
    </>
  );
};

export default SinglebarCard;
