import React from "react";

const NoDataFound = ({ chartName }) => {
  return (
    <>
      <div style={{ width: "20px", height: "40px" }}></div>
      <div
        style={{
          width: "100%",
          height: "350px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "none !important",
          backgroundColor: "#F1F3F5",
        }}
      >
        <p>No data found</p>
      </div>
      <p
        style={{
          fontSize: "12px",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        {chartName}
      </p>
    </>
  );
};

export default NoDataFound;
