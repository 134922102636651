import moment from 'moment';
import { mainAxiosInstance } from '../../../services/service';
import { ServiceConfig } from '../../../services/service-constants';

export const getComponentSystemHistoryTableData = async (payload) => {
  const { page, url, organizationId, vinNumber, subSystem, graphName = null, statsDate = null, type = null } = payload;
  const params = {
    organization_id: organizationId || 1,
    vin_number: vinNumber,
    sub_system: subSystem,
    page: page,
    size: 10,
  };

  if(graphName){
    params.graph_name = graphName;
  }

  if(statsDate) {
    params.stats_date = statsDate;
  }

  if(type) {
    params.type = type;
  }

  return mainAxiosInstance
    .get(url, {
      params: params,
    })
    .then((response) => {
      if (response.status === 200 || response.status === 301) {
        return response?.data;
      }
    })
    .catch((err) => {
      return {type: 'error', message: err?.message || 'Something went wrong'};
    });
};
