import { Button } from "../common/Button/Button";
import { Checkbox } from "../common/Checkbox/Checkbox";
import Modal from "../common/Modal/Modal";
import ValueChip from "../common/ValueChip/ValueChip";
import MultiSelect from "../common/MultiSelect/MultiSelect";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Datepicker from "../Datepicker/datepicker";
import Daterangepicker from "../Datepicker/daterangepicker";
import { forwardRef } from "react";
import { Outlet } from "react-router";
import SelfTest from "../VehicleHealthCheck/SelfTest/SelfTest";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const options = {
  title: {
    text: "My chart",
  },
  series: [
    {
      data: [1, 2, 3],
    },
  ],
};

const Home = () => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const values = ["miles", "hours"];

  const handleMultiSelect = (values) => {};
  const percentage = 66;

  return (
    <section className="content-wrapper">
      <div style={{ width: "100px", height: "100px" }}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          counterClockwise={true}
        />
        ;
      </div>

      <Checkbox
        title="Checking"
        handleClick={() => {}}
        styleClasses=""
        alignProp={{ display: "flex", flexDirection: "row-reverse" }}
      />
      <Button
        title="Submit"
        handleClick={() => {}}
        styleClasses="btn btn-secondary"
      />
      <ValueChip text="Miles" handleRemove={() => {}} />
      <MultiSelect heading={"heading"}>
        {values.map((el) => (
          <Checkbox
            title={el}
            key={el}
            handleClick={() => handleMultiSelect(el)}
          />
        ))}
      </MultiSelect>
      <SelfTest />
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        // accessibility={{ enabled: false }}
        accessibility={false}
      />
      {false && (
        <Modal
          type="large"
          title="DTC Details"
          wrapperClass="main-diagnostics-modal"
          titlePosition="center"
          isError={false}
          handleClose={() => {}}
        >
          <>
            <div className="flex align-center dtc-wrapper">
              <div className="dtc-heading">VIN</div>
              <div className="dtc-value">
                <input type="text" value="5YJSA1DG9DFP14705" />
              </div>
            </div>
            <div className="flex align-center dtc-wrapper">
              <div className="dtc-heading">DTC</div>
              <div className="dtc-value small">
                <input type="text" value="P0407" />
              </div>
            </div>
            <div className="flex align-center dtc-wrapper">
              <div className="dtc-heading">Casual Probability</div>
              <div className="dtc-value small">
                <input type="text" value="82%" />
              </div>
            </div>
            <div className="flex align-center dtc-wrapper">
              <div className="dtc-heading">Associated DTCs</div>
              <div className="dtc-value flex sp-bw small">
                <input type="text" value="P0407" />
                <input type="text" value="P0406" />
                <input type="text" value="P0408" />
                <input type="text" value="P0409" />
              </div>
            </div>
            <div className="flex align-center dtc-wrapper">
              <div className="dtc-heading">DTC Time-Stamp</div>
              <div className="dtc-value">
                <input type="text" value="jan 09 2018 12:30:13" />
              </div>
            </div>
            <div className="flex align-center dtc-wrapper">
              <div className="dtc-heading">DTC Description</div>
              <div className="dtc-value">
                <input
                  className="full-width"
                  type="text"
                  value="Leak detected in the fuel tank"
                />
              </div>
            </div>
            <div className="flex align-center dtc-wrapper">
              <div className="dtc-heading">Potential Root Causes</div>
              <div className="dtc-value">
                <input
                  className="full-width"
                  type="text"
                  value="Fuel cap not fully closed"
                />
              </div>
            </div>
            <div className="flex align-center dtc-wrapper">
              <div className="dtc-heading">Potential Solutions</div>
              <div className="dtc-value">
                <input
                  className="full-width"
                  type="text"
                  value="Replace Fuel Cap"
                />
              </div>
            </div>
            <div className="dtc-button-container">
              <Button
                title="Close"
                handleClick={() => {}}
                styleClasses="btn btn-secondary"
              />
              <Button
                title="Componenet System History"
                handleClick={() => {}}
                styleClasses="btn btn-primary"
              />
            </div>
          </>
        </Modal>
      )}
      <Datepicker customInput={<ExampleCustomInput />} />
      <Daterangepicker
        customInput={<ExampleCustomInput />}
        endDate={new Date("05/28/2022")}
      />
      <Outlet />
    </section>
  );
};

export default Home;
