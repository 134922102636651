import React, { useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./index.scss";

const PrognosticsServiceGuide = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/maintenance-overview/prognostics/service-guide"
    ) {
      navigate("/maintenance-overview/prognostics/service-guide/architecture", {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="full-width padding-15">
      <div className="tabs-container service-guide-tabs">
        <NavLink
          to="/maintenance-overview/prognostics/service-guide/architecture"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Architecture
        </NavLink>
        <NavLink
          to="/maintenance-overview/prognostics/service-guide/user-manual"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          User Manual
        </NavLink>
        <NavLink
          to="/maintenance-overview/prognostics/service-guide/augmented-reality"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Augmented Reality
        </NavLink>
        <NavLink
          to="/maintenance-overview/prognostics/service-guide/youtube-videos"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Youtube Videos
        </NavLink>
        <NavLink
          to="/maintenance-overview/prognostics/service-guide/internet-contents"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Internet Contents
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default PrognosticsServiceGuide;
