import "./modal.scss";
import close from "../../../assets/close.svg";
import { useEffect, useState } from "react";

const Modal = (props) => {
  const {
    type = "small",
    title,
    titlePosition,
    isError,
    handleClose,
    wrapperClass,
    displayCloseIcon = true,
  } = props;

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setToggle(true);
  }, []);

  /**
   *
   * Add animate class after document mount
   */

  useEffect(() => {
    document &&
      document.getElementById("modal-content").classList.add("animated");
  }, []);
  /**
   *
   * To disable scroll when modal is open
   */

  useEffect(() => {
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "unset";
      }
    };
  }, [toggle === true]);

  const handleContentClicked = (event) => {
    event.stopPropagation();
  };

  return (
    <div className={`modal-wrapper ${wrapperClass}`} onClick={handleClose}>
      <div
        className={`modal-container ${type}`}
        onClick={(e) => handleContentClicked(e)}
        id="modal-content"
      >
        <div className={`modal-header`}>
          <div
            className={
              `modal-title ${titlePosition} ` + (isError ? "error" : "")
            }
          >
            {title}
          </div>
          {displayCloseIcon && (
            <div className="modal-close" onClick={handleClose}>
              <img src={close} alt="close" />
            </div>
          )}
        </div>
        <div className="modal-body">{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
