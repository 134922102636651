import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { handlePerProfile } from "./RemoteTesting";
import { useSelector } from "react-redux";
import { Button } from "../../../common/Button/Button";
import { useNavigate } from "react-router";
import { RemoteTestingGraph } from "./Graph/RemoteTestingGraph";
import navLeft from "../../../../assets/left-arrow-square.svg";
import navRight from "../../../../assets/right-arrow-square.svg";
import close from "../../../../assets/close.svg";
import VehicleHealthCheck from "../../index";
import Diagnostics from "../Diagnostics";
//import "./RemoteTesting.scss";

const RemoteAbsDiagram = () => {
  const [searchParams] = useSearchParams();
  const urlEnd = searchParams.get("urlEnd");
  const imgUrlEnd = searchParams.get("imgUrlEnd");
  const graphUrl = searchParams.get("graphUrl");
  const [chartData, setChartData] = useState([]);
  const [timeAxis, setTimeAxis] = useState("");
  const [dataWithImg, setDataWithImg] = useState([]);
  const [data, setData] = useState("");
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const navigate = useNavigate();
  const RightRearInletValve = "right_rear_inlet_valve";
  const RightRearBrakePadValve = "right_rear_brake_pad_wear";
  const RightRearOutletValve = "right_rear_outlet_valve";
  const imageUrlPath = "remote_testing_graphs";
  const graphs = [
    RightRearInletValve,
    RightRearBrakePadValve,
    RightRearOutletValve,
  ];
  const [graphIndex, setGraphIndex] = useState(graphs.indexOf(urlEnd));

  const handleViewDiagram = ({ routePath, urlEnd, imgUrlEnd, graphUrl }) => {
    navigate(
      "/vehicle-health/diagnostics/remote-testing/" +
        routePath +
        "?" +
        "urlEnd=" +
        urlEnd +
        "&imgUrlEnd=" +
        imgUrlEnd +
        "&graphUrl=" +
        graphUrl,

      {
        replace: true,
      }
    );
  };

  const showPrev = () => {
    let index = graphs.length - 1;
    if (graphIndex > 0) {
      index = graphIndex - 1;
    }
    handleViewDiagram({
      routePath: "performance-profile",
      urlEnd: graphs[index],
      imgUrlEnd: imageUrlPath,
      graphUrl: graphs[index],
      remote: "remote-testing",
      vin_Number: vin_Number,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const showNext = () => {
    let index = 0;
    if (graphIndex < graphs.length - 1) {
      index = graphIndex + 1;
    }
    handleViewDiagram({
      routePath: "performance-profile",
      urlEnd: graphs[index],
      imgUrlEnd: imageUrlPath,
      graphUrl: graphs[index],
      remote: "remote-testing",
      vin_Number: vin_Number,
    });
  };

  const clickOnClose = (e) => {
    e.stopPropagation();
    navigate("/vehicle-health/diagnostics/remote-testing", {
      replace: true,
      state: "used",
    });
  };

  useEffect(() => {
    if (chartData?.length) {
      setData(chartData);
    }
  }, [JSON.stringify(chartData)]);

  useEffect(() => {
    setGraphIndex(graphs.indexOf(urlEnd));
    handlePerProfile({
      urlEnd,
      imgUrlEnd,
      saveData: setChartData,
      saveImgData: setDataWithImg,
      xAxisSaveData: setTimeAxis,
      graphUrl,
      vin_Number,
    });
  }, [urlEnd]);
  return (
    <>
      <VehicleHealthCheck />
      <Diagnostics />
      <div className="page-wrapper">
        <div
          className="rem-test-abs-diagram-card"
          style={{ marginTop: "20px" }}
        >
          <div className="re-test-abs-wrapper">
            <div className="rem-test-diagrams">
              <div className="rem-test-graph rem-test-graph-abs">
                {chartData?.length > 0 && dataWithImg?.length > 0 && (
                  <>
                    <div
                      className="nav-icons"
                      onClick={() => showPrev()}
                    >
                      <img src={navLeft} alt="previous" />
                    </div>
                    <div className="large-charts">
                      <div className={`modal-close`} onClick={clickOnClose}>
                        <img src={close} alt="close" />
                      </div>
                      <RemoteTestingGraph
                        id={dataWithImg?.[0]?.id}
                        data={data}
                        onClickHandler={() => {}}
                        title={dataWithImg?.[0]?.graphName
                          ?.split("_")
                          .join(" ")
                          ?.toUpperCase()}
                        xLabel={dataWithImg?.[0]?.xAxisName}
                        xAxisData={timeAxis}
                        yAxisLabel={dataWithImg?.[0]?.yAxisName}
                        showLegend={true}
                      />
                    </div>
                    <div
                      className="nav-icons"
                      onClick={() => showNext()}
                    >
                      <img src={navRight} alt="next" />
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  title="ABS System Diagram"
                  buttonStyles={{ width: "200px" }}
                  styleClasses="btn btn-primary"
                  handleClick={() =>
                    handleViewDiagram({
                      routePath: "abs-diagram",
                      urlEnd: urlEnd,
                      imgUrlEnd: imgUrlEnd,
                      graphUrl: graphUrl,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoteAbsDiagram;
